import MyTeam from './MyTeamComponent'
import MutationAspiration from '../shared-component/MutationAspiration'
import MutationPlan from '../talent-profile/MutationPlan'
import DetailMyTeam from './detail/DetailMyTeam'

import SeeAllProfessionalInformation from '../talent-profile/see-all-professional-information/SeeAllProfessionalInformation'

export const MyTeamConfig = {
  routes: [
    {
      path: '/my-team',
      component: MyTeam,
      state: 'my-team',
      exact: true,
    },
    {
      path: '/my-team/detail',
      component: DetailMyTeam,
      state: 'my-team',
      exact: true,
    },
    {
      path: '/my-team/see-all-professional-information',
      component: SeeAllProfessionalInformation,
      state: 'my-team',
      exact: true,
    },
    {
      path: '/:from/development-plan/:type',
      component: MutationPlan,
      state: 'my-team',
      exact: true,
    },
    {
      path: '/my-team/aspiration/add/:id',
      component: MutationAspiration,
      state: 'my-team',
      exact: true,
    },
    {
      path: '/my-team/aspiration/edit/:id',
      component: MutationAspiration,
      state: 'my-team',
      exact: true,
    },
  ],
}
