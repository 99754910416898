import React from 'react'
import {makeStyles} from '@material-ui/core'
import {EmptyState} from '@smartasn/wlb-utils-components'

import {isValidUrl} from '../../../utils/helpers'

import CardItem from '../../shared-component/CardItem'
import LoadingComponent from '../../../components/loading/LoadingComponent'
import AddItemCard from '../../shared-component/AddItemCard'

const useStyles = makeStyles(() => ({
  cardWraper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  center: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 24,
  },
}))

export default function ListAspiration(props) {
  const classes = useStyles()
  const {
    loading,
    positions,
    onClick,
    onClickAction,
    withAction,
    addActionTo,
  } = props
  return (
    <div className={classes.cardWraper}>
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          {positions?.map(position => {
            const {job_icon} = position.company_job_profile || {}

            const image = isValidUrl(job_icon) ? job_icon : null

            return (
              <CardItem
                key={position.id}
                image={image}
                label={position?.company_job_profile?.title || '-'}
                onClick={() => onClick(position.id)}
                onClickAction={
                  withAction ? ev => onClickAction(ev, position) : undefined
                }
              />
            )
          })}
          {addActionTo && <AddItemCard to={addActionTo} />}
        </>
      )}
      {!loading && !addActionTo && positions?.length === 0 && (
        <div className={classes.center}>
          <EmptyState
            message1="Maaf, tidak ada daftar"
            message2="Belum ada Aspirasi"
            width={280}
          />
        </div>
      )}
    </div>
  )
}
