import styled from 'styled-components'

export const WrapperProfessionalItem = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;

    .chip {
      height: fit-content;
      border: 1px solid #e5e5e5;
      border-radius: 40px;
      padding: 15px 30px;
      margin-right: 32px;
      max-width: 183px;
      width: 100%;

      .MuiChip-label {
        padding: 0;
        white-space: pre-wrap;
        text-align: center;
      }
    }

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      margin-right: 50px;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: 100%;

      .mb-8 {
        margin-bottom: 8px;
      }
      .mb-16 {
        margin-bottom: 16px;
      }
      .mb-18 {
        margin-bottom: 18px;
      }

      .fs-12 {
        font-size: 12px;
      }

      .fs-14 {
        font-size: 14px;
      }

      .italic {
        font-style: italic;
      }

      .fw-600 {
        font-weight: 600;
      }

      .color-primary {
        color: #014a62;
      }

      .color-secondary {
        color: #039be5;
      }

      .color-dimmed {
        color: #a9a8a8;
      }

      .see-more {
        margin-top: 8px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #039be5;
      }

      .divider {
        margin: 30px 0px;
      }

      .hide {
        display: none;
      }
    }
  }
`
