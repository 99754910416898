import gql from 'graphql-tag'

export const GET_LIST_MYTEAM = gql`
  query GetTalentMyTeam(
    $company: uuid!
    $supervisor: uuid!
    $limit: Int
    $offset: Int
  ) {
    total: people_work_placements_aggregate(
      where: {
        user: {_neq: $supervisor}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          _or: [
            {
              job_profile_supervisor: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $supervisor}
                }
              }
            }
            {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                user: {_eq: $supervisor}
              }
            }
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    people_work_placements(
      where: {
        user: {_neq: $supervisor}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
        company_job_profile: {
          _or: [
            {
              job_profile_supervisor: {
                people_work_placements: {
                  status: {_eq: "ACTIVE"}
                  user: {_eq: $supervisor}
                }
              }
            }
            {
              people_work_placements: {
                status: {_eq: "ACTIVE"}
                user: {_eq: $supervisor}
              }
            }
          ]
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      global_user {
        id
        name
        avatar
      }
      company_job_profile {
        id
        title
        is_peer: people_work_placements_aggregate(
          where: {status: {_eq: "ACTIVE"}, user: {_eq: $supervisor}}
        ) {
          aggregate {
            count
          }
        }
      }
      talent_aspirations(limit: 1) {
        id
        company_job_profile {
          id
          company_employee_position {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_LIST_DEVELOPMENT_PLAN_MY_TEAM = gql`
  query GetDevelopmentPlanMyTeam($user: uuid!, $company: uuid!, $limit: Int) {
    talent_development_plans(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
      order_by: [{id: desc}]
      limit: $limit
    ) {
      id
      name
      start_date
      end_date
    }
    total: talent_development_plans_aggregate(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_ASPIRATION_MY_TEAM = gql`
  query getListAspirationMyTeam($user: uuid!, $company: uuid!) {
    talent_aspirations(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
      order_by: {id: desc}
    ) {
      id
      company_job_profile {
        id
        title
        job_icon
      }
    }
  }
`

export const GET_TALENT_INFORMATION = gql`
  query GetTalentInformation($userWorkId: Int!) {
    people_work_placements(where: {id: {_eq: $userWorkId}}) {
      id
      global_user {
        id
        name
        avatar
      }
      placement_fields
    }
  }
`
