import React, {useState, useEffect} from 'react'
import {
  makeStyles,
  Paper,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Icon,
  Button,
  Grid,
  Fab,
  Tooltip,
} from '@material-ui/core'
import {getYear, parseISO} from 'date-fns'
import {GreyTypography} from '../../../../components/typography/TypographyStyles'
import {withRouter, Link} from 'react-router-dom'
import {BoldTypography, FlexBetween} from '../../../../GlobalStyles'
import Carousel from 'nuka-carousel'
import {useQuery, useApolloClient, useMutation} from '@apollo/react-hooks'
import {
  GET_DETAIL_NOMINATION_HOLDING,
  GET_COMPANY_HEAD,
} from '../../../../graphql/queries'
import ChartComponent from './chart/3dChartComponent'
import DeletePopup from '../../../shared-component/DeletePopup'
import Snackbar from '../../../shared-component/Snackbar'
import {
  ADD_TO_NOMINATION_HOLDING,
  REMOVE_FROM_NOMINATION_HOLDING,
} from '../../../../graphql/mutations'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
  },

  flexHeader: {display: 'flex', alignItems: 'center'},
  divider: {marginTop: 24},
  avaLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  flexInnerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  gridContainer: {
    margin: 0,
    width: '100%',
  },

  textCenter: {
    textAlign: 'center',
  },
  successor: {
    margin: '12px 0 16px',
    textAlign: 'center',
    padding: 12,
  },
  indicator: {
    padding: 12,
  },
  namedSuccessor: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
  },
  green: {
    color: '#4caf50',
  },
  spacer: {
    marginLeft: 16,
  },
  spacerPool: {
    marginLeft: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  spacerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 20,
    width: '100%',
  },
  mr20: {
    marginRight: 20,
  },
  flexDetail: {
    alignItems: 'center',
    display: 'flex',
  },
  paperCategory: {
    padding: '20px 0',
    textAlign: 'center',
  },
  red: {
    color: '#ef4d5e',
  },
  paperMobility: {
    textAlign: 'center',
    padding: 17,
    marginTop: 20,
  },
  paperContribut: {
    padding: 20,
    marginTop: 20,
    marginBottom: 42,
  },
  seeAll: {
    textAlign: 'center',
    marginTop: 20,
    width: '100%',
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fab: {
    margin: '0 .5rem 22rem',
    color: '#A19F9F',
    background: '#fff',
    width: 36,
    height: 36,
  },
  icArrow: {marginTop: 6},
}))

const ProcessTalentComponent = props => {
  const client = useApolloClient()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  // const [name, setName] = useState('')
  const [id, setId] = useState('')
  const [head, setHead] = useState(null)
  const [openSnc, setOpenSnc] = useState({
    open: false,
    message: null,
    type: null,
  })
  const colorSwitch = value => {
    const val = value.toLowerCase()

    let color = null
    switch (val) {
      case 'low':
        color = classes.green
        break
      case 'medium':
        color = classes.yellow
        break
      default:
        color = classes.red
    }
    return color
  }

  const handleOpen = (ids, type) => {
    setOpen(true)
    setId(ids)
    // setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getCompanyHead = async () => {
    const {data} = await client.query({
      query: GET_COMPANY_HEAD,
      variables: {id: props.match.params.id},
    })
    if (data) {
      setHead(data.holding_nomination_config[0].company_head)
    }
  }

  const {data, error, refetch} = useQuery(GET_DETAIL_NOMINATION_HOLDING, {
    variables: {id_nomination: props.match.params.id, company_head: head},
    // context: {headers: {'X-Hasura-Role': 'organization-staff'}},
    fetchPolicy: 'no-cache',
  })

  const [mutationAddToNomination] = useMutation(ADD_TO_NOMINATION_HOLDING)
  const [mutationDelFromNomination] = useMutation(
    REMOVE_FROM_NOMINATION_HOLDING
  )

  const handleMutation = () => {
    if (confirmType === 'add') {
      const object = {
        source_id: id,
        nomination_id: props.match.params.id,
      }
      mutationAddToNomination({
        variables: {object},
      })
        .then(() => {
          setOpenSnc({
            open: true,
            message: `Update data success`,
            type: 'success',
          })
          refetch()
        })
        .catch(e => {
          setOpenSnc({
            open: true,
            message: JSON.stringify(e),
            type: 'error',
          })
        })
    } else {
      mutationDelFromNomination({
        variables: {id},
      })
        .then(() => {
          setOpenSnc({
            open: true,
            message: `Update data success`,
            type: 'success',
          })
          refetch()
        })
        .catch(e => {
          setOpenSnc({
            open: true,
            message: JSON.stringify(e),
            type: 'error',
          })
        })
    }
  }
  useEffect(() => {
    getCompanyHead()
  }, [data])
  if (data === undefined && error === undefined) {
    return <div>loading</div>
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const detail = data.detail[0]

  const incumbent =
    data.current_incumbent[0].company_job_profile.people_work_placements

  const displayFab = {display: incumbent.length === 0 ? 'none' : 'block'}

  return (
    <Paper className={classes.paper} style={{marginLeft: 12, marginBottom: 30}}>
      <div className={classes.flexHeader}>
        <IconButton onClick={props.history.goBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Avatar
          variant="rounded"
          src={detail.company_profile.logo}
          className={classes.avaLarge}
          style={{marginRight: 24, marginLeft: 12}}
        />
        <div className={classes.flexInnerHeader}>
          <div>
            <Typography variant="body1" color="primary" gutterBottom>
              {detail.name}
            </Typography>
            <Typography
              variant="body2"
              color="secondary"
              style={{marginBottom: 14}}
            >
              {`${detail.company_job_profile.title} at ${detail.company_profile.brand_name}`}
            </Typography>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <ChartComponent company_head={head} />
      <Grid
        container
        item
        xs={12}
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography variant="body1" gutterBottom>
              Current Incumbent
            </Typography>
            <Carousel
              swiping
              renderCenterRightControls={({
                currentSlide,
                slideCount,
                nextSlide,
              }) => {
                return (
                  <Fab
                    className={classes.fab}
                    disabled={slideCount - currentSlide === 1}
                    style={displayFab}
                    onClick={nextSlide}
                  >
                    <Icon className={classes.icArrow}>chevron_right</Icon>
                  </Fab>
                )
              }}
              renderTopRightControls={null}
              renderCenterLeftControls={({previousSlide, currentSlide}) => {
                return (
                  <Fab
                    className={classes.fab}
                    disabled={currentSlide === 0}
                    style={displayFab}
                    onClick={previousSlide}
                  >
                    <Icon className={classes.icArrow}>chevron_left</Icon>
                  </Fab>
                )
              }}
              renderBottomCenterControls={null}
            >
              {incumbent.map((res, i) => {
                return (
                  <div key={i}>
                    <Paper className={classes.successor}>
                      <BoldTypography variant="body1">
                        {res.global_user.name}
                      </BoldTypography>
                      <Avatar
                        src={res.global_user.avatar}
                        alt={res.global_user.name}
                        className={classes.avaLarge}
                        style={{margin: '16px auto'}}
                      ></Avatar>

                      <GreyTypography variant="body2">
                        {res.regno}
                      </GreyTypography>

                      <Typography variant="body2">{`${getYear(
                        parseISO(res.start_date)
                      )} - ${getYear(parseISO(res.end_date))}`}</Typography>
                    </Paper>
                    <Grid container item spacing={2}>
                      <Grid item xs={4}>
                        <Paper className={classes.paperCategory}>
                          <Typography variant="body2" gutterBottom>
                            Risk of Loss
                          </Typography>
                          <BoldTypography
                            variant="body1"
                            className={colorSwitch(
                              res.placement_fields.talent_information
                                .risk_of_loss
                            )}
                          >
                            {
                              res.placement_fields.talent_information
                                .risk_of_loss
                            }
                          </BoldTypography>
                        </Paper>
                      </Grid>
                      <Grid item xs={4}>
                        <Paper className={classes.paperCategory}>
                          <Typography variant="body2" gutterBottom>
                            Impact of Loss
                          </Typography>
                          <BoldTypography
                            variant="body1"
                            className={colorSwitch(
                              res.placement_fields.talent_information
                                .impact_of_loss
                            )}
                          >
                            {
                              res.placement_fields.talent_information
                                .impact_of_loss
                            }
                          </BoldTypography>
                        </Paper>
                      </Grid>

                      <Grid item xs={4}>
                        <Paper className={classes.paperCategory}>
                          <Typography variant="body2" gutterBottom>
                            Future Leader
                          </Typography>
                          <BoldTypography
                            variant="body1"
                            className={
                              res.placement_fields.talent_information
                                .future_leader
                                ? classes.green
                                : classes.red
                            }
                          >
                            {res.placement_fields.talent_information
                              .future_leader
                              ? 'Yes'
                              : 'No'}
                          </BoldTypography>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Paper className={classes.paperMobility}>
                      <Typography variant="body2">Talent Mobility</Typography>
                      <GreyTypography style={{fontWeight: 600}}>
                        {res.placement_fields.talent_information
                          .reason_for_leaving
                          ? res.placement_fields.talent_information
                              .reason_for_leaving
                          : '-'}
                      </GreyTypography>
                    </Paper>
                    <Paper className={classes.paperContribut}>
                      <div style={{display: 'flex'}}>
                        <Avatar
                          variant="rounded"
                          src={
                            res.global_user.talent_assignments.length > 0
                              ? res.global_user.talent_assignments[0].talent_box
                                  .badge
                              : ''
                          }
                          style={{marginRight: 16}}
                        ></Avatar>
                        <div>
                          <BoldTypography variant="body1">
                            {res.global_user.talent_assignments.length > 0
                              ? res.global_user.talent_assignments[0].talent_box
                                  .name
                              : ''}
                          </BoldTypography>
                          <Typography variant="body2" color="secondary">
                            {res.global_user.talent_assignments.length > 0
                              ? res.global_user.talent_assignments[0].talent_score.toFixed(
                                  2
                                )
                              : ''}
                          </Typography>
                        </div>
                      </div>
                    </Paper>
                    <FlexBetween>
                      <BoldTypography variant="body1">
                        {res.global_user.talent_assignments.length > 0
                          ? res.global_user.talent_assignments[0].talent_box
                              .name
                          : ''}
                      </BoldTypography>
                    </FlexBetween>

                    {/* <div className={classes.indicator}>
                      {potensials
                        .filter(v => v.checked)
                        .map((res, i) => {
                          return (
                            <FlexBetween key={i}>
                              <Typography
                                variant="body2"
                                gutterBottom
                              >{`${res.name} (${res.indicator})`}</Typography>
                              <Typography variant="body2">
                                {res.value}
                              </Typography>
                            </FlexBetween>
                          )
                        })}
                    </div> */}
                    <FlexBetween>
                      <BoldTypography variant="body1">
                        Performance Indicator
                      </BoldTypography>
                    </FlexBetween>

                    <div className={classes.indicator}>
                      {/* {performances
                        .filter(v => v.checked)
                        .map((res, i) => {
                          return (
                            <FlexBetween key={i}>
                              <Typography
                                variant="body2"
                                gutterBottom
                              >{`${res.name} (${res.indicator})`}</Typography>
                              <Typography variant="body2">
                                {res.value}
                              </Typography>
                            </FlexBetween>
                          )
                        })} */}
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </Paper>
        </Grid>
        <Grid item container xs={6} alignContent="flex-start">
          <Grid item xs={12} style={{marginBottom: 12}}>
            <Paper className={classes.paper}>
              <FlexBetween>
                <Typography variant="body1">Successor Nomination</Typography>
              </FlexBetween>
              {data.successor.slice(0, 5).map((resSuccessor, i) => {
                const holdingSource = resSuccessor.holding_source
                return (
                  <div className={classes.namedSuccessor} key={i}>
                    <Avatar
                      src={
                        holdingSource &&
                        holdingSource.people_work_placement &&
                        holdingSource.people_work_placement.global_user
                          ? holdingSource.people_work_placement.global_user
                              .avatar
                          : ''
                      }
                      alt={
                        holdingSource &&
                        holdingSource.people_work_placement &&
                        holdingSource.people_work_placement.global_user
                          ? holdingSource.people_work_placement.global_user.name
                          : ''
                      }
                    ></Avatar>
                    <div className={classes.spacerPool}>
                      <div>
                        <BoldTypography variant="body2">
                          {holdingSource &&
                          holdingSource.people_work_placement &&
                          holdingSource.people_work_placement.global_user
                            ? holdingSource.people_work_placement.global_user
                                .name
                            : ''}
                        </BoldTypography>
                        <Typography
                          variant="caption"
                          className={
                            resSuccessor.status === 'ACTIVE'
                              ? classes.green
                              : classes.red
                          }
                        >
                          {resSuccessor.status}
                        </Typography>
                      </div>

                      <Tooltip title="Remove from Talent Nomination">
                        <IconButton
                          size="small"
                          onClick={() => handleOpen(resSuccessor.id, 'delete')}
                        >
                          <Icon color="error">person_remove</Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )
              })}
              {data.successor.length > 5 && (
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.seeAll}
                    color="secondary"
                    component={Link}
                    to={{
                      pathname: 'all-successor',
                      state: {
                        detail,
                        active: 'all-successor',
                        type: 'nomination',
                        company_head: head,
                      },
                    }}
                  >
                    See All
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <FlexBetween>
                <Typography variant="body1" gutterBottom>
                  Talent Pool
                </Typography>
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  component={Link}
                  to={{
                    pathname: 'all-pool',
                    state: {
                      detail,
                      active: 'all-pool',
                      type: 'pool',
                      company_head: head,
                    },
                  }}
                >
                  + Add
                </Button> */}
              </FlexBetween>

              {data.talent_pool.slice(0, 5).map((resPool, i) => {
                return (
                  <div className={classes.namedSuccessor} key={i}>
                    <Avatar
                      src={resPool.people_work_placement.global_user.avatar}
                      alt={resPool.people_work_placement.global_user.name}
                    ></Avatar>
                    <div className={classes.spacerPool}>
                      <div>
                        <BoldTypography variant="body2">
                          {resPool.people_work_placement.global_user.name}
                        </BoldTypography>
                        <GreyTypography variant="caption">
                          {
                            resPool.people_work_placement.company_job_profile
                              .title
                          }{' '}
                          at{' '}
                          {
                            resPool.people_work_placement.company_profile
                              .brand_name
                          }
                        </GreyTypography>
                      </div>
                      <Tooltip title="Add to Talent Nomination">
                        <IconButton
                          size="small"
                          onClick={() => handleOpen(resPool.source_id, 'add')}
                        >
                          <Icon color="primary">person_add</Icon>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                )
              })}
              {data.talent_pool.length > 5 && (
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.seeAll}
                    color="secondary"
                    component={Link}
                    to={{
                      pathname: 'all-pool',
                      state: {
                        detail,
                        active: 'all-pool',
                        type: 'pool',
                        company_head: head,
                      },
                    }}
                  >
                    See All
                  </Button>
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <DeletePopup
        open={open}
        type={confirmType}
        handleClose={handleClose}
        mutation={handleMutation}
      />
      <Snackbar open={openSnc} setOpen={setOpenSnc} />
    </Paper>
  )
}

export default withRouter(ProcessTalentComponent)
