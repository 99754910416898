import styled from 'styled-components'

export const WrapperLicences = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      margin-right: 50px;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: 100%;

      .title {
        color: #000000;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 12px;
      }

      .label {
        font-weight: 400;
        font-size: 12px;
        color: #a9a8a8;
        margin-bottom: 8px;
      }

      .company {
        color: #014a62;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 12px;
      }

      .date {
        color: #a9a8a8;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 12px;
      }

      .description {
        font-weight: 400;
        font-size: 12px;
        white-space: pre-line;
      }

      .certificate {
        font-weight: 400;
        font-size: 12px;
        color: #a9a8a8;
        margin-bottom: 16px;

        span {
          color: #039be5;
        }
      }

      .credential {
        font-size: 12px;
        color: #014a62;
      }

      .see-more {
        margin-top: 16px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #039be5;
      }

      .divider {
        margin: 30px 0px;
      }

      .hide {
        display: none;
      }
    }
  }
`
