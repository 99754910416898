import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {GET_LIST_AWARDS} from '../../../../graphql/queries'
import {USER_ID, NAME} from '../../../../utils/globals'
import {formatDate} from '../../talent-profile-helper'

import HeaderSection from '../shared-professional-information/HeaderSection'
import LoadingItem from '../../../../components/loading/LoadingItem'
import AwardItem from './AwardItem'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 36,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
}))

const LIMIT = 2

export default function Award({userId, to}) {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_AWARDS, {
    context: {headers: {'X-Hasura-Role': 'user'}},
    fetchPolicy: 'network-only',
    variables: {
      id: userId || USER_ID,
      limit: LIMIT,
    },
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.wrapper}>
      <HeaderSection title="Penghargaan" />

      {loading && <LoadingItem />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Penghargaan"
          width={280}
        />
      )}

      {!loading &&
        data?.people_profile_awards?.map((award, idx) => {
          const date = formatDate(award.reference_date, 'MMM dd, yyyy')

          return (
            <AwardItem
              key={award.id}
              avatar={award?.company_profile?.logo}
              title={award.award_name || '-'}
              company={award.issuing_organization || '-'}
              date={date}
              reference={`Nomor Referensi ${award.reference_number || '-'}`}
              awardto={`Diberikan kepada ${to?.state?.userName || NAME}`}
              awardNumber={`${award.letter_number} - ${award.certificate_number}`}
              description={award.remark || '-'}
              attachments={award.attachments}
              isLast={data?.people_profile_awards.length === idx + 1}
            />
          )
        })}
      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 4,
          }}
        >
          +{total} Lebih Banyak Penghargaan
        </Typography>
      )}
    </div>
  )
}
