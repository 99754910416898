import React from 'react'
import {IconButton, makeStyles, Paper, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(() => ({
  paper: {
    marginBottom: '2rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: 5,
  },
  icon: {
    fontSize: 20,
    marginRight: 18,
  },
  header: {
    padding: '25px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sectionName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: 16,
  },
  back: {
    marginRight: 20,
  },
  body: {
    padding: '0px 30px 25px 30px',
  },
}))

export default function Section(props) {
  const {title, children, icon, onBack, childrenStyle = {}, action} = props
  const classes = useStyles()

  const Icon = icon

  return (
    <Paper className={classes.paper}>
      <div className={classes.header}>
        <div className={classes.sectionName}>
          {onBack && (
            <IconButton
              size="small"
              onClick={onBack}
              aria-label="back"
              className={classes.back}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {icon && <Icon color="primary" className={classes.icon} />}
          <Typography color="primary" className={classes.title}>
            {title}
          </Typography>
        </div>
        {action}
      </div>
      <div style={childrenStyle} className={classes.body}>
        {children}
      </div>
    </Paper>
  )
}
