import React from 'react'
import {Snackbar, IconButton, makeStyles} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles(() => ({
  green: {
    backgroundColor: 'green !important',
  },
  red: {
    backgroundColor: 'red',
  },
}))

export default function SnackbarComponent({open, setOpen}) {
  const classes = useStyles()
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open.open}
        ContentProps={{
          classes: {
            root: open.type !== 'success' ? classes.red : classes.green,
          },
        }}
        autoHideDuration={1500}
        onClose={handleClose}
        message={open.message}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  )
}
