import React from 'react'
import {Dialog, Grid, makeStyles, Typography} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import {NAME} from '../../../../utils/globals'

import {Employee} from './WorkingInformationDetail'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'muli',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#eff2f4',
    padding: '12px 24px',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  pointer: {
    cursor: 'pointer',
  },
  body: {
    padding: '0px 24px',
  },
  employees: {
    marginBottom: 36,
    marginTop: 24,
  },
  employeesLabel: {
    fontWeight: 600,
    marginBottom: 24,
  },
}))

export default function SeeAllHierarchy(props) {
  const classes = useStyles()

  const {open, onClose, data} = props

  return (
    <Dialog scroll="body" fullWidth maxWidth="md" onClose={onClose} open={open}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <h2 className={classes.title}>Rekan Kantor {NAME}</h2>
          <CloseIcon className={classes.pointer} onClick={onClose} />
        </div>

        <div className={classes.body}>
          <Mate data={data?.supervisor} label="Atasan Langsung" />
          <Mate data={data?.subordinate} label="Bawahan Langsung" />
        </div>
      </div>
    </Dialog>
  )
}

const Mate = ({label, data}) => {
  const classes = useStyles()

  return (
    <div className={classes.employees}>
      <Typography className={classes.employeesLabel}>{label}</Typography>
      <Grid container spacing={2}>
        {data?.map((employee, idx) => (
          <Grid item xs={12} sm={6} key={idx}>
            <Employee employee={employee} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
