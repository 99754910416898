import {GetModularSettings} from '@smartasn/wlb-utils-components'
import {HCEco} from '@worklifebeyond/wlb-utils-components'
import React, {useEffect} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import {Header} from './components/header/HeaderComponent'
import {
  AppWrapper,
  LeftSideWrapper,
  MainWrapper,
  VacancyListsMainArea,
} from './components/job-portal/JobPortalStyles'
import Main from './components/Main'
import UserLoggedIn from './components/user-logged-in/UserLoggedInComponent'
// import {AccessToAnotherModule} from './components/access-to-another-module/AccessToAnotherModuleComponent'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from './components/utils-components/TalentStyles'
import UnderDevelopment from './pages/error/UnderDevelopment'
import {COMPANY_ID, SOSMED_APP_URL} from './utils/globals'
import {hasModule, listenCookieChange} from './utils/helpers'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
  },
}

const AppContainer = props => {
  const {data} = GetModularSettings(COMPANY_ID)

  if (data) {
    if (!hasModule(data, 'talent')) {
      window.location = SOSMED_APP_URL
    }
  }
  let regexUrl = ''
  if (props.location.pathname) {
    regexUrl = props.location.pathname.match('[a-z0-9-]+')
  }
  const activeTab = regexUrl ? `/${regexUrl[0]}` : '/'
  const handleChange = (event, newValue) => {
    event.preventDefault()
    props.history.push({
      pathname: newValue,
    })
  }

  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [listenCookieChange])

  return (
    <AppWrapper>
      {props.location.pathname === '/page-under-development' ? (
        <Switch>
          <Route
            exact
            path="/page-under-development"
            render={() => <UnderDevelopment />}
          />
        </Switch>
      ) : (
        <>
          <Header />
          <TabsWrapper>
            <StyledTabs
              value={activeTab}
              onChange={handleChange}
              style={customStyle.tabs}
              centered
            >
              <StyledTab label="Profil Talenta" value="/profile" />
              <StyledTab label="Tim" value="/my-team" />
              <StyledTab label="Komite Talenta" value="/committee-task" />
            </StyledTabs>
          </TabsWrapper>
          <MainWrapper>
            <LeftSideWrapper>
              <React.Fragment>
                <UserLoggedIn />
                <HCEco
                  supportLink="https://forms.gle/XEDmZktE7MXhfjZw7"
                  companyId={COMPANY_ID}
                  env={{
                    SOSMED_URL: process.env.REACT_APP_HC_SOSMED,
                    USER_URL: process.env.REACT_APP_HC_TIME,
                    PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
                    LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
                    TALENT_URL: process.env.REACT_APP_HC_TALENT,
                    VENDOR_URL: process.env.REACT_APP_HC_VENDOR,
                  }}
                  language="indonesian"
                />
              </React.Fragment>
            </LeftSideWrapper>
            <VacancyListsMainArea>
              <Main />
            </VacancyListsMainArea>
          </MainWrapper>
        </>
      )}
    </AppWrapper>
  )
}

export default withRouter(AppContainer)
