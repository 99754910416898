import React, {useEffect} from 'react'
import {withRouter} from 'react-router'
import {useQuery} from '@apollo/react-hooks'

import {USER_WORK} from '../../../graphql/queries'
import {COMPANY_ID} from '../../../utils/globals'

import Section from '../../shared-component/wrapper/Section'

import ProfessionalInformation from '../../talent-profile/professional-information/ProfessionalInformation'
import DevelopmentPlan from '../../talent-profile/development-plan/DevelopmentPlan'
import Aspiration from '../../talent-profile/aspiration/Aspiration'

const DetailMyTeam = props => {
  const {userId, userName, disallowEdit} = props.location.state

  const {data} = useQuery(USER_WORK, {
    skip: !userId,
    variables: {
      user: userId,
      company: COMPANY_ID,
    },
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Section
      title={`Informasi profesional ${userName}`}
      onBack={() => props.history.goBack()}
    >
      <ProfessionalInformation
        userId={userId}
        userWorkId={data?.people_work_placements?.[0]?.id}
        to={{
          pathname: '/my-team/see-all-professional-information',
          state: {userId, userName},
        }}
      />

      <DevelopmentPlan
        userId={userId}
        userWorkId={data?.people_work_placements?.[0]?.id}
        origin="my-team"
        disallowEdit={disallowEdit}
      />

      <Aspiration
        userId={userId}
        userWorkId={data?.people_work_placements?.[0]?.id}
        origin="my-team"
        disallowEdit={disallowEdit}
      />
    </Section>
  )
}

export default withRouter(DetailMyTeam)
