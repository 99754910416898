import React from 'react'
import {Chip, Divider, Typography, makeStyles} from '@material-ui/core'
import Dompurify from 'dompurify'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  chip: {
    height: 'fit-content',
    border: '1px solid #E5E5E5',
    borderRadius: 40,
    padding: '15px 30px',
    marginRight: 32,
    minWidth: 183,
  },
  chipLabel: {
    whiteSpace: 'break-spaces',
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    textAlign: 'center',
  },
  name: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 8,
  },
  level: {
    textTransform: 'capitalize',
    color: '#333333',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 8,
  },
  label: {
    color: '#A9A8A8',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: 18,
  },
  body: {
    width: '100%',
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginTop: 0,
    },
  },
  content: {
    marginBottom: 30,
  },
  divider: {
    marginBottom: 30,
  },
  listTitle: {
    fontSize: 12,
    fontWeight: 400,
  },
  listLabel: {
    fontSize: 12,
    fontWeight: 400,
  },
  wrapperList: {
    maxWidth: 220,
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapperScore: {
    marginBottom: 12,
  },
  description: {
    fontSize: 12,
    fontWeight: 400,
    color: '#333333',
    margin: '0 0 4px',
  },
  ul: {
    margin: '0 0 0 22px',
    padding: 0,
  },
  li: {
    fontSize: 12,
  },
  seeMore: {
    margin: '16px 0 0',
    fontSize: 12,
    cursor: 'pointer',
    userSelect: 'none',
    color: '#039be5',
  },
}))

const List = ({label, value}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperList}>
      <Typography className={classes.listTitle}>{label}</Typography>
      <Typography
        style={{color: value < 0 ? '#EF4D5E' : '#333333'}}
        className={classes.listLabel}
      >
        {value}
      </Typography>
    </div>
  )
}

export default function GapCompetencyItem(props) {
  const {
    title,
    category,
    type,
    label,
    requiredLevel,
    individualLevel,
    gap,
    description,
    levels,
    isLast,
  } = props
  const classes = useStyles()
  const [isMore, setIsMore] = React.useState(false)

  return (
    <div className={classes.root}>
      <Chip
        variant="outlined"
        classes={{root: classes.chip, label: classes.chipLabel}}
        label={<Typography className={classes.title}>{title}</Typography>}
      />
      <div className={classes.body}>
        <div className={classes.content}>
          <Typography className={classes.name}>{category}</Typography>
          <Typography className={classes.level}>{type}</Typography>
          <Typography className={classes.label}>{label}</Typography>

          <div className={classes.wrapperScore}>
            <List label="Level yang Dibutuhkan" value={requiredLevel} />
            <List label="Level Individu" value={individualLevel} />
            <List label="Kesenjangan" value={gap} />
          </div>

          <p className={classes.description}>
            {description?.slice(0, !isMore ? 370 : description?.length) || '-'}
          </p>
          <ul className={classes.ul}>
            {levels
              ?.slice(0, !isMore ? 1 : levels?.length)
              ?.map((level, idx) => (
                <li
                  key={idx}
                  className={classes.li}
                  dangerouslySetInnerHTML={{
                    __html: Dompurify.sanitize(level.description),
                  }}
                />
              ))}
          </ul>

          <p className={classes.seeMore} onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </p>
        </div>
        {!isLast && <Divider className={classes.divider} />}
      </div>
    </div>
  )
}
