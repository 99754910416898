import {Typography, makeStyles} from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid #E5E5E5',
    width: 253,
    padding: 16,
    borderRadius: 5,
    boxSizing: 'border-box',
  },
  head: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: '#333333',
    marginBottom: 4,
  },
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: '#A9A8A8',
    marginBottom: 8,
  },
  listTitle: {
    fontSize: 12,
    fontWeight: 400,
  },
  listLabel: {
    fontSize: 12,
    fontWeight: 400,
  },
  wrapperList: {
    marginBottom: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  seeDetail: {
    cursor: 'pointer',
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
  },
}))

const List = ({label, value}) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperList}>
      <Typography className={classes.listTitle}>{label}</Typography>
      <Typography
        style={{color: value < 0 ? '#EF4D5E' : '#333333'}}
        className={classes.listLabel}
      >
        {value}
      </Typography>
    </div>
  )
}

export default function GapCompetencyItem(props) {
  const {title, label, requiredLevel, individualLevel, gap} = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.head}>
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.label}>{label}</Typography>
        </div>
      </div>

      <List label="Level yang Dibutuhkan" value={requiredLevel} />
      <List label="Level Individu" value={individualLevel} />
      <List label="Kesenjangan" value={gap} />
    </div>
  )
}
