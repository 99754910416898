import CommitteeTask from './CommitteeTaskComponent'
import HumanCapitalTalentVersion from './HumanCapitalTalentVersion'
import TalentDetail from './TalentDetail'
import DetailOtherCompany from './holding/DetailOtherCompanyComponent'
import ProcessTalentComponent from './holding/process/ProcessTalentComponent'
import SeeAllComponent from './holding/process/SeeAllComponent'

export const CommitteeTaskConfig = {
  routes: [
    {
      path: '/committee-task',
      component: CommitteeTask,
      state: 'committee-task',
      exact: true,
    },
    {
      path: '/committee-task/:id',
      component: HumanCapitalTalentVersion,
      state: 'committee-task',
      exact: true,
    },
    {
      // path: '/committee-task/:id/detail',
      path: '/committee-task/:id_user/:id_version',
      component: TalentDetail,
      state: 'committee-task',
      exact: true,
    },
    {
      path: '/committee-task/other-company/:id',
      component: DetailOtherCompany,
      state: 'committee-task',
      exact: true,
    },
    {
      path: '/committee-task/other-company/:id/process',
      component: ProcessTalentComponent,
      state: 'committee-task',
      exact: true,
    },
    {
      path: '/committee-task/other-company/:id/all-successor',
      component: SeeAllComponent,
      state: 'committee-task',
      exact: true,
    },
    {
      path: '/committee-task/other-company/:id/all-pool',
      component: SeeAllComponent,
      state: 'committee-task',
      exact: true,
    },
  ],
}
