import React from 'react'
import {Avatar, Typography} from '@material-ui/core'

import defaultAvatar from '../../../../assets/images/default-avatar.svg'
import {WrapperProfessionalItem} from '../../TalentProfileStyles'
import WorkingInformationDetail from './WorkingInformationDetail'

export default function WorkingInformationItem(props) {
  const [isMore, setIsMore] = React.useState(!!props.defaultMore)

  const {
    avatar,
    title,
    activeDate,
    location,
    joinDate,
    preretirementDate,
    supervisor,
    subordinate,
    employeeId,
    decreee,
    officeEmail,
    organization,
    level,
    employeeType,
    workingArrangement,
    timeArrangement,
    jobGrade,
    costCentre,
    facility,
    acessRights,
    attachments,
  } = props

  return (
    <WrapperProfessionalItem>
      <div className="content">
        <Avatar
          variant="square"
          src={avatar || defaultAvatar}
          className="avatar"
          alt="logo"
        />
        <div className="detail-section">
          <Typography className="mb-8 fs-14 fw-600">{title}</Typography>
          <Typography className="mb-8 fs-12 fw-400 color-dimmed">
            {activeDate}
          </Typography>
          <Typography className="mb-8 fs-12 fw-400 color-secondary">
            {location}
          </Typography>
          <Typography className="mb-8 fs-12 fw-400 color-primary">
            {joinDate}
          </Typography>
          <Typography className="mb-16 fs-12 fw-400">
            {preretirementDate}
          </Typography>

          {isMore && (
            <WorkingInformationDetail
              subordinate={subordinate}
              supervisor={supervisor}
              employeeId={employeeId}
              decreee={decreee}
              officeEmail={officeEmail}
              organization={organization}
              level={level}
              employeeType={employeeType}
              workingArrangement={workingArrangement}
              timeArrangement={timeArrangement}
              jobGrade={jobGrade}
              costCentre={costCentre}
              facility={facility}
              acessRights={acessRights}
              attachments={attachments}
            />
          )}

          {!props.defaultMore && (
            <span className="see-more" onClick={() => setIsMore(!isMore)}>
              ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
            </span>
          )}
        </div>
      </div>
    </WrapperProfessionalItem>
  )
}
