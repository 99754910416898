import styled from 'styled-components'
import {Typography} from '@material-ui/core'

export const WhiteModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

export const BoldTypography = styled(Typography)`
  font-weight: 600;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Flex = styled.div`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  justify-content: ${props =>
    props.flexEnd
      ? 'flex-end'
      : props.flexCenter
      ? 'center'
      : props.flexAround
      ? 'space-around'
      : props.flexBetween
      ? 'space-between'
      : 'flex-start'};
  align-items: ${props =>
    props.align === 'center'
      ? 'center'
      : props.align === 'flex-start'
      ? 'flex-start'
      : props.align === 'flex-end'
      ? 'flex-end'
      : props.align === 'baseline'
      ? 'baseline'
      : props.align === 'initial'
      ? 'initial'
      : props.align === 'inherit'
      ? 'inherit'
      : 'stretch'};
`
