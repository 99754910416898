import React from 'react'
import {Divider, Icon, Typography} from '@material-ui/core'
import {BoldTypography} from '../../../components/typography/TypographyStyles'

const clamp = {
  overflow: 'hidden',
  display: '-webkit-box',
  'word-break': 'break-word',
  '-webkit-box-orient': 'vertical',
  '-webkit-line-clamp': '2',
}

const NineBoxes = props => {
  const {data, litle, newWidth, handleChangeBage, badge} = props

  const renderData = () => {
    const newArr = []
    // for (let x = i; x >= 1; x--) {
    //   for (let y = 1; y <= newHeight; y++) {
    data &&
      data.map((res, ids) => {
        // console.log(condList, res.id)
        // if (res.index_x === x && res.index_y === y) {
        return newArr.push(
          <div key={ids} style={{width: `${100 / newWidth}%`}}>
            <div
              style={{
                // border: `2px solid ${condList.badge === res.id &&
                //   res.color_hex}`,
                border: badge === res.id && `2px solid ${res.color_hex}`,
                borderWidth: '3px',
                borderLeft: '0px',
                borderRadius: 10,
                height: `${250 / newWidth}px`,
                cursor: 'pointer',
                marginRight: 10,
                marginTop: 20,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
              }}
              onClick={() => handleChangeBage(res.id)}
            >
              <div
                style={{
                  backgroundColor: res.color_hex,
                  height: '100%',
                  width: '10px',
                  borderRadius: '7px',
                  left: '-3px',
                  position: 'absolute',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  padding: '0px 8px',
                }}
              >
                <img
                  src={res.badge}
                  alt={res.name}
                  style={{
                    width: `${
                      newWidth === 1 || newWidth === 2 ? 4 : 9 / newWidth
                    }vw`,
                  }}
                />
                <div style={{textAlign: 'right', marginLeft: '12px'}}>
                  <BoldTypography style={clamp} variant="body2">
                    {res.name}
                  </BoldTypography>
                  {!litle && (
                    <Typography
                      variant="h5"
                      style={{color: res.color_hex, fontWeight: '600'}}
                    >
                      {res.talent_assignments_aggregate.aggregate.count}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
        // }
      })
    //   }
    // }
    return newArr
  }

  return (
    <>
      {/* Garis Vertikal */}
      <div
        style={{
          marginTop: -16,
          // height: `${newHeight * (250 / newWidth) + 4 * newHeight}px`,
          height: 'auto',
          paddingBottom: 28,
          display: litle ? 'none' : 'block',
        }}
      >
        <Icon style={{height: 15}}>arrow_drop_up</Icon>
        <Divider
          orientation="vertical"
          style={{
            marginTop: -5,
            marginLeft: 11,
            background: '#707070',
            width: 2,
            // height: `${newHeight * (300 / newWidth) +
            //   4 * newHeight +
            //   30 / newWidth}px`,
          }}
        ></Divider>
      </div>
      {/* BOXES */}
      <div style={{width: '100%'}}>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>{renderData()}</div>

        {/* Garis Horizontal */}
        <div
          style={{
            width: '100%',
            display: litle ? 'none' : 'flex',
          }}
        >
          <Divider
            style={{
              background: '#707070',
              height: 2,
              marginTop: 11,
              width: `103%`,
              marginRight: -12,
              marginLeft: -13,
            }}
          ></Divider>
          <Icon>arrow_right</Icon>
        </div>
      </div>
    </>
  )
}

export default NineBoxes
