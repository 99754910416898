import React from 'react'
import {makeStyles, Typography} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  head: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
  },
  total: {
    fontSize: 10,
    fontWeight: 400,
    color: '#333333',
    marginTop: 2,
  },
  action: {
    textDecoration: 'none',
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
    color: '#039BE5',
  },
}))

export default function HeaderSection({title, total}) {
  const classes = useStyles()

  return (
    <div className={classes.head}>
      <div>
        <Typography color="primary" className={classes.title}>
          {title}
        </Typography>
        {total > 0 && (
          <Typography color="primary" className={classes.total}>
            +{total} Lebih Banyak {title}
          </Typography>
        )}
      </div>
    </div>
  )
}
