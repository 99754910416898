import React, {useState, useEffect} from 'react'
import {
  Menu,
  MenuList,
  TextField,
  Typography,
  makeStyles,
  Badge,
  InputAdornment,
  Divider,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FormFilter from './FormFilter'

const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
  },
  flexColumn: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flex1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1rem',
    cursor: 'pointer',
  },
  divroot1: {
    width: '50%',
    padding: '10px',
  },
  divroot2: {
    width: '50%',
    marginLeft: '2rem',
    padding: '10px',
    backgroundColor: '#f7f8f9',
  },
  scroll: {
    height: '170px',
    overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
  search: {
    backgroundColor: '#fff',
  },
}))
const Filter = ({
  handleClose,
  anchorEl,
  handleClickFilter,
  filterData,
  handleChange,
  state,
  handleApply,
  resetFilter,
  resetAll,
  selectAll,
}) => {
  const classes = useStyle()
  const [filters, setDataFilter] = useState({name: '', data: []})
  const [text, setText] = useState('')

  const handleFilters = event => {
    const query = event.target.value
    const dataFilter = filterData.data.filter(function(el) {
      return el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    })
    setText(event.target.value)
    setDataFilter({name: filterData.name, data: dataFilter})
  }
  useEffect(() => {
    setDataFilter({name: filterData.name, data: filterData.data})
  }, [filterData])

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: '620px',
        },
      }}
    >
      <MenuList>
        <div className={classes.root}>
          <div className={classes.divroot1}>
            <div className={classes.flexColumn}>
              <div>
                <div
                  className={classes.flex1}
                  onClick={() => handleClickFilter('badge')}
                >
                  <Typography variant="body1">Lencana</Typography>
                  {state.badge.length !== 0 ? (
                    <Badge badgeContent={state.badge.length} color="primary" />
                  ) : null}
                </div>
                <div
                  className={classes.flex1}
                  onClick={() => handleClickFilter('aspiration')}
                >
                  <Typography variant="body1">Aspirasi</Typography>
                  {state.aspiration.length !== 0 ? (
                    <Badge
                      badgeContent={state.aspiration.length}
                      color="primary"
                    />
                  ) : null}
                </div>
                <div
                  className={classes.flex1}
                  onClick={() => handleClickFilter('point')}
                >
                  <Typography variant="body1">Poin Peringkat</Typography>
                  {state.scoreFrom && state.scoreTo ? (
                    <Badge badgeContent={1} color="primary" />
                  ) : null}
                </div>
              </div>
              <div>
                <Divider style={{marginTop: '1rem'}} />
                <div className={classes.flex}>
                  <Typography
                    variant="body1"
                    onClick={resetAll}
                    style={{cursor: 'pointer'}}
                  >
                    Atur Ulang Semua
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{cursor: 'pointer'}}
                      onClick={handleClose}
                    >
                      Batal
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      onClick={handleApply}
                      style={{cursor: 'pointer', marginLeft: 12}}
                    >
                      Coba
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.divroot2}>
            {filterData.name !== 'point' && (
              <TextField
                className={classes.search}
                variant="outlined"
                fullWidth
                placeholder="Cari"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleFilters}
                value={text}
              />
            )}
            <div className={classes.flex}>
              {filterData.name === 'point' ? (
                <Typography variant="body1" style={{fontWeight: 'bold'}}>
                  Pick a Range
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  color="primary"
                  onClick={() => selectAll(filterData.name)}
                  style={{cursor: 'pointer'}}
                >
                  Pilih Semua
                </Typography>
              )}

              <Typography
                variant="body1"
                onClick={() => resetFilter(filterData.name)}
                style={{cursor: 'pointer'}}
              >
                Atur Ulang
              </Typography>
            </div>
            <div className={classes.scroll}>
              <FormFilter
                filterData={filters}
                handleChange={handleChange}
                state={state}
              />
            </div>
          </div>
        </div>
      </MenuList>
    </Menu>
  )
}
export default Filter
