import React, {useState, useEffect} from 'react'
// import QueryString from 'query-string'
import {
  TextField,
  makeStyles,
  Typography,
  Button,
  MenuItem,
} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import ReactSelect from 'react-select'
import {GET_LIST_COMPETENCY, DETAIL_PLAN} from '../../graphql/queries/index'
import {
  ADD_MY_TEAM_PLAN,
  ADD_PLAN,
  EDIT_MY_TEAM_PLAN,
  EDIT_PLAN,
} from '../../graphql/mutations/index'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {withRouter} from 'react-router-dom'
import Snackbar from '../shared-component/Snackbar'
import moment from 'moment'
import Section from '../shared-component/wrapper/Section'

const useStyles = makeStyles(() => ({
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
  formDate: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '2rem',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 11,
  },
  input: {
    width: '50%',
  },
  inputSelect: {
    width: '50%',
    '&:nth-child(1)': {
      height: 'auto',
    },
  },
  textareaError: {
    width: '50%',
    borderRadius: 5,
    borderColor: 'red',
    fontSize: 16,
    color: '#000',
    padding: 12,
    fontFamily: 'muli',
  },
  filled: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    margin: '3px 14px 0px',
  },
  filledCharacters: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    margin: '3px 0px',
    width: '50%',
    textAlign: 'right',
  },
  btn: {
    width: 169,
    height: 61.7,
    borderRadius: 5,
  },
  status: {
    width: '50%',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
  },
  box: {
    width: 30,
    height: 30,
    borderRadius: 5,
    marginRight: 15,
  },
  menuItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  teksMenu: {
    fontWeight: '500',
  },
  paddingError: {padding: '5px 0 0 10px'},
}))

const TODAY = moment(new Date()).format('YYYY-MM-DD')

function MutationPlan(props) {
  const classes = useStyles()

  const [change, setChange] = useState({
    name: '',
    measure: '',
    recommended: '',
    start: TODAY,
    end: TODAY,
    competency: [],
    status: 'WAITING',
  })
  const [error, setError] = useState(false)
  const [open, setOpen] = useState({open: false, message: null, type: null})
  const {type, from} = props.match.params
  const {developmentPlanId, userwork} = props.location.state

  useEffect(() => {
    if (props.location.state !== undefined) {
      const header = document.body
      if (header !== null) {
        header.scrollIntoView()
      }
    }
  }, [])

  const condition = {
    limit: 100,
    offset: 0,
  }

  const {data: dataCompetency} = useQuery(GET_LIST_COMPETENCY, {
    variables: condition,
  })
  const {data: dataEdit} = useQuery(DETAIL_PLAN, {
    skip: !developmentPlanId,
    fetchPolicy: 'network-only',
    variables: {
      id: developmentPlanId,
    },
  })

  useEffect(() => {
    if (type === 'Edit') {
      const {
        name,
        measure_of_success,
        start_date,
        end_date,
        recommended_action,
        status,
        talent_development_competencies,
      } = dataEdit?.talent_development_plans?.[0] || {}

      setChange({
        name,
        measure: measure_of_success || undefined,
        start: start_date,
        end: end_date,
        recommended: recommended_action || undefined,
        status,
        competency: talent_development_competencies?.map(competency => {
          return {
            value: competency.competency_dictionary.id,
            label: competency.competency_dictionary.name.en,
          }
        }),
      })
    }
  }, [dataEdit])

  const isTalentProfile = from === 'profile'

  const [addPlan] = useMutation(isTalentProfile ? ADD_PLAN : ADD_MY_TEAM_PLAN)
  const [editPlan] = useMutation(
    isTalentProfile ? EDIT_PLAN : EDIT_MY_TEAM_PLAN
  )

  const handleChange = name => event => {
    setChange({...change, [name]: event.target.value})
  }

  const handleDate = name => e => {
    setChange({...change, [name]: moment(e).format('YYYY-MM-DD')})
  }
  const onChangeMulti = (value, {action, removedValue, name}) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        setChange({...change, [name]: []})
        break
    }
    setChange({...change, [name]: value})
  }
  const mutationAdd = () => {
    const valCompetency = []

    if (from !== 'profile') {
      if (
        change.name === '' ||
        change.measure === '' ||
        change.recommended === '' ||
        !change.competency ||
        change.competency.length < 1
      ) {
        setError(true)
        const elmnt = document.getElementById('content')
        elmnt.scrollIntoView()
        setTimeout(() => setError(false), 1500)
      } else {
        if (type === 'Edit') {
          change.competency.map(res => {
            const datas = {
              competency: res.value,
              plan: developmentPlanId,
            }
            return valCompetency.push(datas)
          })
          const data = {
            id: developmentPlanId,
            name: change.name,
            measure_of_success: change.measure,
            start_date: change.start,
            end_date: change.end,
            competency: valCompetency,
            status: change.status,
            ...(!isTalentProfile && {
              recommended_action: change.recommended,
            }),
          }

          editPlan({variables: data}).then(() => {
            setOpen({
              open: true,
              message: 'Ubah Rencana Pengembangan Berhasil',
              type: 'success',
            })
            handleback()
          })
        } else {
          change.competency.map(res => {
            const datas = {
              competency: res.value,
            }
            return valCompetency.push(datas)
          })
          const data = {
            name: change.name,
            measure: change.measure,
            startdate: change.start,
            enddate: change.end,
            userwork: userwork,
            competency: valCompetency,
            status: change.status,
            ...(!isTalentProfile && {
              recommended_action: change.recommended,
            }),
          }

          addPlan({variables: data}).then(() => {
            setOpen({
              open: true,
              message: 'Berhasil menambahkan rencana pengembangan',
              type: 'success',
            })
            handleback()
          })
        }
      }
    } else {
      if (
        change.name === '' ||
        change.measure === '' ||
        !change.competency ||
        change.competency.length < 1
      ) {
        setError(true)
        const elmnt = document.getElementById('content')
        elmnt.scrollIntoView()
        setTimeout(() => setError(false), 1500)
      } else {
        if (type === 'Edit') {
          change.competency.map(res => {
            const datas = {
              competency: res.value,
              plan: developmentPlanId,
            }
            return valCompetency.push(datas)
          })
          const data = {
            id: developmentPlanId,
            name: change.name,
            measure_of_success: change.measure,
            start_date: change.start,
            end_date: change.end,
            competency: valCompetency,
            status: change.status,
            ...(!isTalentProfile && {
              recommended_action: change.recommended,
            }),
          }

          editPlan({variables: data}).then(() => {
            setOpen({
              open: true,
              message: 'Ubah Rencana Pengembangan Berhasil',
              type: 'success',
            })
            handleback()
          })
        } else {
          change.competency.map(res => {
            const datas = {
              competency: res.value,
            }
            return valCompetency.push(datas)
          })
          const data = {
            name: change.name,
            measure: change.measure,
            startdate: change.start,
            enddate: change.end,
            userwork: userwork,
            competency: valCompetency,
            status: change.status,
            ...(!isTalentProfile && {
              recommended_action: change.recommended,
            }),
          }

          addPlan({variables: data}).then(() => {
            setOpen({
              open: true,
              message: 'Berhasil menambahkan rencana pengembangan',
              type: 'success',
            })
            handleback()
          })
        }
      }
    }
  }
  const handleback = () => {
    props.history.goBack()
  }
  const colourStyles = {
    control: provided => ({
      ...provided,
      minHeight: 61,
      height: 'auto',
      outline: 'none',
      padding: 5,
      borderColor:
        (!change.competency || change.competency.length < 1) && error
          ? 'red'
          : null,
    }),

    multiValue: base => ({
      ...base,
      backgroundColor: '#039be5',
      height: 'auto',
      borderRadius: 5,
      paddingLeft: 14,
    }),
    multiValueLabel: base => ({
      ...base,
      color: '#fff',
      textAlign: 'center',
      // marginTop: 5,
      padding: '7px 12px',
      fontSize: 14,
    }),
    multiValueRemove: base => ({
      ...base,
      color: '#ffff',
      '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
    }),
  }

  return (
    <Section
      id="header"
      title={`${type === 'Edit' ? 'Ubah' : 'Tambah'} Rencana Pengembangan`}
      onBack={handleback}
    >
      <div className={classes.formControl} id="content">
        <Typography variant="subtitle1" className={classes.subtitle}>
          Nama sasaran pengembangan
        </Typography>
        <TextField
          variant="outlined"
          className={classes.input}
          onChange={handleChange('name')}
          value={change.name}
          inputProps={{maxLength: 255}}
          error={change.name === '' ? error : false}
          helperText={error && !change.name && 'tidak boleh kosong'}
        />
        <Typography variant="subtitle2" className={classes.filledCharacters}>
          Karakter {change.name && change.name.length}/255
        </Typography>
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Ukuran sukses
        </Typography>
        <TextField
          multiline
          rows="10"
          variant="outlined"
          className={classes.input}
          onChange={handleChange('measure')}
          value={change.measure}
          error={!change.measure ? error : false}
          helperText={error && !change.measure && 'tidak boleh kosong'}
        />
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Rekomendasi Tindakan Pegawai
        </Typography>
        <TextField
          multiline
          rows="10"
          variant="outlined"
          className={classes.input}
          onChange={handleChange('recommended')}
          value={change.recommended}
          disabled={from === 'profile'}
          style={
            from === 'profile' ? {backgroundColor: '#f7f8f9'} : {zIndex: '0'}
          }
          error={change.recommended === '' ? error : false}
          helperText={
            from !== 'profile' &&
            error &&
            !change.recommended &&
            'tidak boleh kosong'
          }
        />
        {from === 'profile' ? (
          <Typography variant="subtitle2" className={classes.filled}>
            Diisi Oleh Atasan
          </Typography>
        ) : null}
      </div>
      <div className={classes.formDate}>
        <div style={{marginRight: '1rem'}}>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Tanggal Mulai
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={change.start}
              onChange={handleDate('start')}
              name="start"
              error={change.start > change.end}
              helperText={
                change.start > change.end
                  ? 'Tanggal mulai lebih kecil dari tanggal akhir'
                  : null
              }
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <Typography variant="subtitle1" className={classes.subtitle}>
            Tanggal Berakhir
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              fullWidth
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={change.end}
              onChange={handleDate('end')}
              name="end"
              error={change.start > change.end}
              helperText={
                change.start > change.end
                  ? 'Tanggal akhir lebih besar dari tanggal mulai'
                  : null
              }
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Kompetensi
        </Typography>
        <ReactSelect
          isMulti
          value={change && change.competency}
          name="competency"
          options={
            dataCompetency?.competency_dictionaries?.map(dictionary => ({
              value: dictionary.id,
              label: dictionary.name.en,
            })) || []
          }
          className={classes.inputSelect}
          classNamePrefix="select"
          styles={colourStyles}
          onChange={onChangeMulti}
          isClearable
          placeholder="Pilih"
        />
        {error && (!change.competency || change.competency.length < 1) && (
          <Typography
            variant="caption"
            color="error"
            className={classes.paddingError}
          >
            tidak boleh kosong
          </Typography>
        )}
      </div>
      <div className={classes.formControl}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Status
        </Typography>
        <TextField
          disabled={from === 'profile'}
          select
          label="Pilih Status"
          value={change.status}
          onChange={handleChange('status')}
          variant="outlined"
          className={classes.status}
          style={
            from === 'profile'
              ? {backgroundColor: '#f7f8f9', zIndex: '0'}
              : {zIndex: '0'}
          }
        >
          <MenuItem className={classes.select} value="ONPROGRESS">
            <div className={classes.menuItemWrapper}>
              <div
                style={{backgroundColor: '#039be5'}}
                className={classes.box}
              ></div>
              <Typography className={classes.teksMenu}>Dalam Proses</Typography>
            </div>
          </MenuItem>
          <MenuItem className={classes.select} value="WAITING">
            <div className={classes.menuItemWrapper}>
              <div
                style={{backgroundColor: '#ffa000'}}
                className={classes.box}
              ></div>
              <Typography className={classes.teksMenu}>Menunggu</Typography>
            </div>
          </MenuItem>
          <MenuItem className={classes.select} value="DONE">
            <div className={classes.menuItemWrapper}>
              <div
                style={{backgroundColor: '#4caf50'}}
                className={classes.box}
              ></div>
              <Typography className={classes.teksMenu}>Selesai</Typography>
            </div>
          </MenuItem>
        </TextField>
        {from === 'profile' ? (
          <Typography variant="subtitle2" className={classes.filled}>
            Diisi Oleh Atasan
          </Typography>
        ) : null}
      </div>

      <div
        style={{
          alignItems: 'right',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          className={classes.btn}
          onChange={onChangeMulti}
          value={change.competency}
          onClick={mutationAdd}
        >
          Simpan {type === 'Edit' && 'Perubahan'}
        </Button>
      </div>
      <Snackbar open={open} setOpen={setOpen} />
    </Section>
  )
}

export default withRouter(MutationPlan)
