import React from 'react'

import {Paper, makeStyles} from '@material-ui/core'
import {Link} from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(() => ({
  paper: {
    width: '151px',
    height: '151px',
    borderRadius: '5px',
    textAlign: 'center',
    boxSizing: 'border-box',
    margin: 24,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    icon: {
      color: '#003d51',
      textAlign: 'center',
    },
  },
}))

export default function AddItemCard({to}) {
  const classes = useStyles()

  return (
    <Paper className={classes.paper} component={Link} to={to}>
      <AddIcon style={{fontSize: 80}} className={classes.icon} />
    </Paper>
  )
}
