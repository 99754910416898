import gql from 'graphql-tag'

export const GET_LIST_DEVELOPMENT_PLAN = gql`
  query getListDevelopmentPlan($user: uuid!, $company: uuid!, $limit: Int) {
    talent_development_plans(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
      order_by: [{id: desc}]
      limit: $limit
    ) {
      id
      name
      measure_of_success
      start_date
      end_date
      status
    }
    total: talent_development_plans_aggregate(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_LIST_ASPIRATION_PROFILE = gql`
  query getListAspiration($user: uuid!, $company: uuid!) {
    current_position: people_work_placements(
      where: {
        user: {_eq: $user}
        company: {_eq: $company}
        status: {_eq: "ACTIVE"}
      }
      order_by: {id: desc}
    ) {
      id
      company_job_profile {
        id
        title
        job_icon
      }
    }
    talent_aspirations(
      where: {
        people_work_placement: {
          user: {_eq: $user}
          company: {_eq: $company}
          status: {_eq: "ACTIVE"}
        }
      }
      order_by: {id: desc}
    ) {
      id
      company_job_profile {
        id
        title
        job_icon
      }
    }
  }
`
export const GET_DETAIL_EDIT_ASPIRATION = gql`
  query getListAspiration($id: Int!) {
    talent_aspirations(where: {id: {_eq: $id}}) {
      id
      planned_date
      company_job_profile {
        id
      }
    }
  }
`

export const GET_DETAIL_PWP_BY_ID = gql`
  query getDetailCurrentPosition($id: Int!) {
    position: people_work_placements(where: {id: {_eq: $id}}) {
      id
      start_date
      end_date
      company_job_profile {
        id
        title
        job_description
        job_summary
        company_job_profile_competencies {
          id
          competency_dictionary {
            id
            name
          }
        }
        company_job_profile_r_educations {
          id
          global_degree {
            id
            name
          }
          global_faculty {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_DETAIL_ASPIRATION_BY_ID = gql`
  query getDetailAspirationById($id: Int!) {
    position: talent_aspirations(where: {id: {_eq: $id}}) {
      id
      planned_date
      company_job_profile {
        id
        title
        job_description
        job_summary
        company_job_profile_competencies {
          id
          competency_dictionary {
            id
            name
          }
        }
        company_job_profile_r_educations {
          id
          global_degree {
            id
            name
          }
          global_faculty {
            id
            name
          }
        }
      }
    }
  }
`
export const GET_DETAIL_DEVELOPMENT_PLAN = gql`
  query getDevelopmentPlant($id: Int!) {
    talent_development_plans(where: {id: {_eq: $id}}) {
      id
      name
      measure_of_success
      start_date
      end_date
      status
      recommended_action
      talent_development_competencies {
        id
        competency_dictionary {
          id
          name
        }
      }
    }
  }
`

export const GET_LIST_COMPETENCY = gql`
  query($offset: Int, $limit: Int) {
    total: competency_dictionaries_aggregate {
      aggregate {
        count
      }
    }

    competency_dictionaries(
      order_by: {name: asc}
      offset: $offset
      limit: $limit
    ) {
      id
      dictionary_id
      name
      type
      description
    }
  }
`
export const USER_WORK = gql`
  query($user: uuid!, $company: uuid!) {
    people_work_placements(
      where: {
        status: {_eq: "ACTIVE"}
        user: {_eq: $user}
        company: {_eq: $company}
      }
    ) {
      id
    }
  }
`
export const DETAIL_PLAN = gql`
  query($id: Int) {
    talent_development_plans(where: {id: {_eq: $id}}) {
      name
      measure_of_success
      recommended_action
      start_date
      end_date
      status
      talent_development_competencies {
        id
        competency_dictionary {
          id
          name
        }
      }
    }
  }
`
export const JOB_PROFILE = gql`
  query($company_id: uuid) {
    company_job_profiles(where: {company: {_eq: $company_id}}) {
      id
      title
    }
  }
`
