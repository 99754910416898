import React from 'react'
import {GlobalHeader} from '@smartasn/wlb-utils-components'

export const Header = () => {
  const env = {
    REACT_APP_SOSMED: process.env.REACT_APP_HC_SOSMED + '/',
    REACT_APP_HC_ADMIN: process.env.REACT_APP_HC_ADMIN,
    REACT_APP_HC_HOLDING: process.env.REACT_APP_HC_HOLDING,
    REACT_APP_DOMAIN: process.env.REACT_APP_COOKIE,
    HOME_URL: process.env.REACT_APP_HC_TALENT,
    REACT_APP_CHAT: process.env.REACT_APP_CHAT,
    // notif_path dev = talent, staging,prod = all
    NOTIF_PATH: 'all',
    SSO_URL: process.env.REACT_APP_SSO + '/',
    REACT_APP_LEARNING: process.env.REACT_APP_HC_LEARNING,
    rest_url: process.env.REACT_APP_API_SOSMED_URL,
    wlb_rest_url: process.env.REACT_APP_API_URL,
  }

  return <GlobalHeader env={env} language="indonesian" />
}
