import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'

import App from './App'
import {SSO_URL} from './utils/globals'
import {getCookie} from './utils/helpers'

if (process.env.NODE_ENV !== 'development' && !getCookie('userData')) {
  window.location = SSO_URL
} else {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById('root')
  )
}
