import TalentProfile from './TalentProfileComponent'
import MutationPlan from './MutationPlan'
import MutationAspiration from '../shared-component/MutationAspiration'
import SeeAllProfessionalInformation from './see-all-professional-information/SeeAllProfessionalInformation'

export const TalentProfileConfig = {
  routes: [
    {
      path: '/profile',
      component: TalentProfile,
      state: 'profile',
      exact: true,
    },
    {
      path: '/:from/development-plan/:type/:id',
      component: MutationPlan,
      state: 'profile',
      exact: true,
    },
    {
      path: '/profile/aspiration/add/:id',
      component: MutationAspiration,
      state: 'profile',
      exact: true,
    },
    {
      path: '/profile/aspiration/edit/:id',
      component: MutationAspiration,
      state: 'profile',
      exact: true,
    },
    {
      path: '/profile/see-all-professional-information',
      component: SeeAllProfessionalInformation,
      state: 'profile',
      exact: true,
    },
  ],
}
