import React from 'react'
import {
  makeStyles,
  Paper,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Icon,
  Button,
} from '@material-ui/core'

import {GreyTypography} from '../../../components/typography/TypographyStyles'
import {withRouter, Link} from 'react-router-dom'
const useStyles = makeStyles(theme => ({
  paper: {
    padding: 24,
    marginLeft: 12,
    marginBottom: 30,
    minHeight: 417,
  },

  flexHeader: {display: 'flex', alignItems: 'center'},
  divider: {margin: '24px 0'},
  avaLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: 24,
    marginLeft: 12,
  },
  flexInnerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

function DetailOtherCompany(props) {
  const classes = useStyles()
  const {match, location, history} = props
  const data = location.state.data
  console.log('data', data)
  return (
    <Paper className={classes.paper}>
      <div className={classes.flexHeader}>
        <IconButton onClick={history.goBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Avatar
          variant="rounded"
          src={data.company_profile.logo}
          alt={data.company_profile.legal_name}
          className={classes.avaLarge}
        />
        <div className={classes.flexInnerHeader}>
          <div>
            <Typography variant="body1">
              {data.company_profile.brand_name}
            </Typography>
            <GreyTypography variant="body2" component="p">
              {data.company_profile.legal_name}
            </GreyTypography>
          </div>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={{
              pathname: `/committee-task/other-company/${match.params.id}/process`,
            }}
            size="large"
          >
            Process
          </Button>
        </div>
      </div>
      <Divider className={classes.divider} />
      <Typography variant="body1" color="primary">
        {data.name}
      </Typography>
      <Typography variant="body2" color="secondary" style={{marginBottom: 14}}>
        {`${data.company_job_profile.title} at ${data.company_profile.brand_name}`}
      </Typography>
      <Typography variant="body2">{data.description}</Typography>
    </Paper>
  )
}

export default withRouter(DetailOtherCompany)
