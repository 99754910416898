import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {NAME, USER_ID} from '../../../../utils/globals'
import {GET_LIST_DISCIPLINARIES} from '../../../../graphql/queries'
import {formatDate} from '../../talent-profile-helper'

import HeaderSection from '../shared-professional-information/HeaderSection'
import LoadingItem from '../../../../components/loading/LoadingItem'
import Disciplinaryitem from './Disciplinaryitem'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 36,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
}))

const LIMIT = 2

export default function Disciplinary({userId, to}) {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_DISCIPLINARIES, {
    context: {headers: {'X-Hasura-Role': 'public'}},
    fetchPolicy: 'network-only',
    variables: {
      id: userId || USER_ID,
      limit: LIMIT,
    },
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.wrapper}>
      <HeaderSection title="Kedisiplinan" />

      {loading && <LoadingItem />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Kedisiplinan"
          width={280}
        />
      )}

      {!loading &&
        data?.people_profile_disciplinary.map((disciplinary, idx) => {
          const start = formatDate(disciplinary.start_date, 'MMM dd, yyyy')
          const end = formatDate(disciplinary.end_date, 'MMM dd, yyyy')
          const refDate = formatDate(
            disciplinary.reference_date,
            'MMM dd, yyyy'
          )

          const date = [start, end].filter(d => d).join(' - ')

          return (
            <Disciplinaryitem
              key={disciplinary.id}
              disciplineName={disciplinary.discipline_name || '-'}
              referenceNumber={disciplinary.reference_number || '-'}
              date={date}
              givenTo={to?.state?.userName || NAME}
              letterNumber={disciplinary.discipline_letter_number || '-'}
              referenceDate={refDate}
              description={disciplinary.remark || '-'}
              attachments={disciplinary.attachments || []}
              isLast={data?.people_profile_disciplinary.length === idx + 1}
            />
          )
        })}

      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 5,
          }}
        >
          +{total} Lebih Banyak Kedisiplinan
        </Typography>
      )}
    </div>
  )
}
