import React from 'react'
import {Avatar, Divider, Typography} from '@material-ui/core'
import {AttachmentCard} from '@smartasn/wlb-utils-components'

import defaultAvatar from '../../../../assets/images/default-avatar.svg'
import {WrapperProfessionalItem} from '../../TalentProfileStyles'

export default function AwardItem(props) {
  const [isMore, setIsMore] = React.useState(false)

  const {
    avatar,
    title,
    company,
    date,
    reference,
    awardto,
    description,
    awardNumber,
    attachments = [],
    isLast,
  } = props

  return (
    <WrapperProfessionalItem>
      <div className="content">
        <Avatar
          variant="square"
          src={avatar || defaultAvatar}
          className="avatar"
          alt="logo"
        />
        <div className="detail-section">
          <Typography className="mb-8 fs-14 fw-600">{title}</Typography>
          <Typography className="mb-8 fs-12 color-secondary">
            {company}
          </Typography>
          <Typography className="mb-8 fs-12 color-dimmed">{date}</Typography>
          <Typography className="mb-16 fs-12 color-primary">
            {reference}
          </Typography>
          <Typography className="mb-8 fs-12 ">{awardto}</Typography>
          <Typography className="mb-16 fs-12 color-dimmed">
            {awardNumber}
          </Typography>
          <Typography className="mb-16 fs-12">
            {description?.slice(0, (!isMore && 370) || description?.length) ||
              '-'}
          </Typography>
          {isMore && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px',
                }}
              >
                {attachments.map((file, idx) => (
                  <AttachmentCard
                    key={idx}
                    name={file.name}
                    url={file.url || file.link}
                  />
                ))}
              </div>
            </>
          )}

          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
          <Divider className={`divider ${isLast && 'hide'}`} />
        </div>
      </div>
      <div className="divider" />
    </WrapperProfessionalItem>
  )
}
