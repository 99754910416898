import React from 'react'
import {DialogContent, Typography, Button, Dialog} from '@material-ui/core'
import {makeStyles} from '@material-ui/styles'
import {capitalize} from '../../utils/helpers'

export default function DeletePopup(props) {
  const {open, handleClose, mutation, type} = props
  const useStyles = makeStyles(() => ({
    checkContainer: {
      display: 'flex',
      padding: '16px 0 0',
      alignItems: 'center',
    },
    checkbox: {padding: 5, marginRight: 10},

    content: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      padding: 24,
      textAlign: 'center',
    },
    btnDel: {
      backgroundColor:
        type === 'add' || type === 'edit' ? '#055469' : '#ef4d5e',
      color: '#fff',
      '&:hover': {
        backgroundColor:
          type === 'add' || type === 'edit' ? '#055469' : '#ef4d5e',
      },
      width: '100%',
      height: '60px',
    },
    btnCancel: {
      width: '100%',
      height: '60px',
      backgroundColor: '#eff2f4',
    },
    title: {
      marginTop: 43,
      color: type === 'add' || type === 'edit' ? '#055469' : '#ef4d5e',
      marginBottom: 31,
    },
    actions: {display: 'flex', flexDirection: 'row', marginTop: 43},
    wordBreak: {wordBreak: 'break-all'},
  }))
  const classes = useStyles()

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      <DialogContent className={classes.content}>
        <Typography variant="h4" className={classes.title}>
          {type ? capitalize(type) : 'Hapus'} item ini ?
        </Typography>
        <Typography variant="subtitle1" display="inline">
          Apakah anda yakin untuk {!type ? 'menghapus' : type} item ini ?
        </Typography>
      </DialogContent>
      <div className={classes.actions}>
        <Button onClick={handleClose} className={classes.btnCancel}>
          Batalkan
        </Button>
        <Button
          className={classes.btnDel}
          variant="contained"
          onClick={() => {
            handleClose()
            mutation()
          }}
        >
          {!type ? 'Hapus' : type}
        </Button>
      </div>
    </Dialog>
  )
}
