import React from 'react'

import {
  Dialog,
  DialogContent,
  Typography,
  Icon,
  makeStyles,
} from '@material-ui/core'
import moment from 'moment'
import {useQuery} from '@apollo/react-hooks'

import {GET_DETAIL_DEVELOPMENT_PLAN} from '../../../graphql/queries'
import LoadingComponent from '../../../components/loading/LoadingComponent'

const current = {
  ONPROGRESS: 'Dalam Proses',
  WAITING: 'Menunggu',
  DONE: 'Selesai',
}

const color = {
  ONPROGRESS: '#039be5',
  WAITING: '#ffa000',
  DONE: '#4caf50',
}

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#eff2f4',
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    padding: 24,
    alignItems: 'center',
    marginBottom: 14,
  },
  titleHeader: {
    fontSize: 24,
    color: '#373e41',
    fontWeight: '600',
  },
  titleJob: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003d51',
    fontWeight: 'bold',
    fontSize: 20,
  },
  root: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  root2: {
    marginTop: '2rem',
  },
  dates: {
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#a9a8a8',
    marginTop: 6,
  },
  description: {
    lineHeight: 1.28,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#a9a8a8',
    fontSize: 16,
    marginBottom: '1rem',
  },
  subtitle: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#039be5',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: '5px',
    marginTop: 28,
  },
  font: {
    fontFamily: 'muli',
    width: '100px',
    height: '25px',
    borderRadius: '5px',
    textAlign: 'center',
    paddingTop: '0.2rem',
    color: '#ffff',
  },
  descriptionCompetence: {
    marginBottom: 0,
  },
}))

export default function DetailDevelopmentPlan(props) {
  const classes = useStyles()
  const {id, open, onClose} = props

  const {data, loading, error} = useQuery(GET_DETAIL_DEVELOPMENT_PLAN, {
    skip: !id,
    variables: {id},
  })

  if (error) {
    console.error(error)
  }

  const {
    name,
    measure_of_success,
    start_date,
    end_date,
    status,
    recommended_action,
    talent_development_competencies,
  } = data?.talent_development_plans?.[0] || []

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div className={classes.header}>
            <Typography
              id="alert-dialog-title"
              variant="h3"
              className={classes.titleHeader}
            >
              Detail Rencana Pengembangan
            </Typography>
            <Icon style={{fontSize: 30, cursor: 'pointer'}} onClick={onClose}>
              close
            </Icon>
          </div>
          <DialogContent>
            <div className={classes.root}>
              <div>
                <Typography variant="subtitle1" className={classes.titleJob}>
                  {name}
                </Typography>
                <Typography variant="body1" className={classes.dates}>
                  {start_date && moment(start_date).format('DD/MM/YYYY')} -{' '}
                  {end_date && moment(end_date).format('DD/MM/YYYY')}
                </Typography>
              </div>
              <div
                className={classes.font}
                style={{backgroundColor: color[status]}}
              >
                <Typography>{current[status]}</Typography>
              </div>
            </div>
            <div className={classes.root2}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Ukuran Keberhasilan
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {measure_of_success || '-'}
              </Typography>

              <Typography variant="subtitle1" className={classes.subtitle}>
                Rekomendasi Sikap Pegawai
              </Typography>
              <Typography variant="body2" className={classes.description}>
                {recommended_action || '-'}
              </Typography>

              <Typography variant="body2" className={classes.description}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  Kompetensi
                </Typography>
                {talent_development_competencies?.map((res, i) => {
                  return (
                    <Typography
                      key={i}
                      variant="body2"
                      className={classes.descriptionCompetence}
                    >
                      {res.competency_dictionary?.name?.en || '-'}
                    </Typography>
                  )
                })}
              </Typography>
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
