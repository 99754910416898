import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {GET_LIST_TRAINING_LISENCE} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {formatDate} from '../../talent-profile-helper'

import TrainingLicenseItem from '../../professional-information/training-license/TrainingLicenseItem'
import LoadingItem from '../../../../components/loading/LoadingItem'

export default function TrainingLisence({userId}) {
  const {data, loading, error, fetchMore} = useQuery(
    GET_LIST_TRAINING_LISENCE,
    {
      fetchPolicy: 'network-only',
      variables: {id: userId || USER_ID, limit: 6},
      context: {headers: {'X-Hasura-Role': 'user'}},
    }
  )

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_profile_certificates.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_profile_certificates: [
            ...prev.people_profile_certificates,
            ...fetchMoreResult.people_profile_certificates,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Pelatihan, Lisensi & Sertifikasi"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.people_profile_certificates.length || 0}
          next={fetchMoreData}
          hasMore={data?.people_profile_certificates.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.people_profile_certificates.map((certificate, idx) => {
            const label = [certificate.level, certificate.type]
              .filter(certificate => certificate)
              .join(' - ')

            return (
              <TrainingLicenseItem
                key={certificate.id}
                avatar={certificate.company_profile?.logo}
                title={certificate.name}
                label={label}
                company={certificate.issuer_name}
                date={formatDate(certificate.issues_date)}
                description={certificate.description}
                credential={certificate.credential_id}
                url={certificate.credential_url}
                attachments={certificate.attachments}
                isLast={data?.people_profile_certificates.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
