import React, {useState} from 'react'

import {
  Button,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
  ListItemText,
} from '@material-ui/core'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'
import moment from 'moment'
import AccessibilityIcon from '@material-ui/icons/Accessibility'

import {GET_LIST_DEVELOPMENT_PLAN} from '../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../utils/globals'
import {DELETE_PLAN} from '../../../graphql/mutations'

import ListDevelopmentPlan from './ListDevelopmentPlan'
import LoadingItem from '../../../components/loading/LoadingItem'
import DetailDevelopmentPlan from '../../shared-component/dialog/DetailDevelopmentPlan'
import Snackbar from '../../shared-component/Snackbar'
import DeletePopup from '../../shared-component/DeletePopup'
import Section from '../../shared-component/wrapper/Section'

const useStyles = makeStyles(() => ({
  seeMore: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#039be5',
    cursor: 'pointer',
  },
  fake: {
    fontSize: '18px',
    color: '#007fb2',
    width: 150,
    textAlign: 'center',
  },
}))

const DEFAULT_LIMIT = 2

export default function DevelopmentPlan({
  userId,
  userWorkId,
  origin,
  disallowEdit,
}) {
  const classes = useStyles()
  const [limit, setLimit] = useState(DEFAULT_LIMIT)
  const [anchorEl, setAnchorEl] = useState({element: null, id: null})
  const [openDelete, setOpenDelete] = useState(false)
  const [detail, setDetail] = useState({
    open: false,
    id: null,
  })

  const [openSnc, setOpenSnc] = useState({
    open: false,
    message: null,
    type: null,
  })

  const [deletePlan] = useMutation(DELETE_PLAN)

  const {data, loading, error, refetch} = useQuery(GET_LIST_DEVELOPMENT_PLAN, {
    fetchPolicy: 'network-only',
    variables: {
      user: userId || USER_ID,
      company: COMPANY_ID,
      limit: limit,
    },
  })

  if (error) {
    console.error(error)
  }

  const handleSeeAll = () => {
    setLimit(prev => (prev ? undefined : DEFAULT_LIMIT))
  }

  const handleDeleteDevelopmentPlan = () => {
    deletePlan({
      variables: {
        id: anchorEl.id,
      },
    })
      .then(() => {
        refetch()
        setOpenSnc({
          open: true,
          message: `Berhasil menghapus Rencana Pengembangan`,
          type: 'success',
        })
        setAnchorEl({element: null, id: null})
      })
      .catch(() => {
        setOpenSnc({
          open: true,
          message: `Gagal menghapus Rencana Pengembangan`,
          type: 'error',
        })
      })
  }

  const totalData = data?.total.aggregate.count

  return (
    <>
      <Section
        title="Rencana Pengembangan"
        icon={AccessibilityIcon}
        action={
          !disallowEdit && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={{
                pathname: `/${origin || 'profile'}/development-plan/Add`,
                state: {
                  userwork: userWorkId,
                },
              }}
            >
              Tambah Rencana
            </Button>
          )
        }
      >
        {loading ? (
          <LoadingItem />
        ) : (
          data?.talent_development_plans.map(res => {
            const start = moment(res.start_date).format('DD/MM/YYYY')
            const end = res.end_date
              ? moment(res.end_date).format('DD/MM/YYYY')
              : null

            const date = [start, end].filter(date => date).join(' - ')
            return (
              <ListDevelopmentPlan
                key={res.id}
                name={res.name}
                date={date}
                status={res.status}
                measureOfSuccess={res.measure_of_success}
                onClick={() => setDetail({open: true, id: res.id})}
                onClickMore={ev =>
                  !disallowEdit &&
                  setAnchorEl({element: ev.currentTarget, id: res.id})
                }
              />
            )
          })
        )}

        {!loading && totalData === 0 && (
          <EmptyState
            message1="Maaf, tidak ada daftar"
            message2="Belum ada Rencana Pengembangan"
            width={280}
          />
        )}

        {!loading && totalData > DEFAULT_LIMIT && (
          <Typography
            variant="h6"
            className={classes.seeMore}
            onClick={handleSeeAll}
          >
            {totalData === data?.talent_development_plans.length
              ? 'Lihat Lebih Sedikit'
              : 'Lihat Lebih Banyak'}
          </Typography>
        )}
      </Section>

      <DetailDevelopmentPlan
        id={detail.id}
        open={detail.open}
        onClose={() => setDetail({open: false, id: null})}
      />

      <Menu
        id="development-plan-menu"
        anchorEl={anchorEl.element}
        keepMounted
        open={Boolean(anchorEl.element)}
        onClose={() => setAnchorEl({element: null, id: null})}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={classes.fake}
          component={Link}
          to={{
            pathname: `/${origin || 'profile'}/development-plan/Edit`,
            state: {
              developmentPlanId: anchorEl.id,
            },
          }}
        >
          <ListItemText primary="Ubah" />
        </MenuItem>

        <MenuItem onClick={() => setOpenDelete(true)} className={classes.fake}>
          <ListItemText primary="Hapus" />
        </MenuItem>
      </Menu>

      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={handleDeleteDevelopmentPlan}
      />
      <Snackbar open={openSnc} setOpen={setOpenSnc} />
    </>
  )
}
