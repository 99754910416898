import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {GET_WORKING_INFORMATION} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {formatDate, getJobDuration} from '../../talent-profile-helper'

import LoadingItem from '../../../../components/loading/LoadingItem'
import WorkingInformationItem from '../../professional-information/working-information/WorkingInformationItem'

export default function WorkingInformation({userId}) {
  const {data, loading, error} = useQuery(GET_WORKING_INFORMATION, {
    variables: {id: userId || USER_ID},
    context: {headers: {'X-Hasura-Role': 'user'}},
  })

  if (error) {
    console.error(error)
  }

  return (
    <div>
      {loading && <LoadingItem count={1} />}

      {!loading &&
        data?.working.map(pwp => {
          const {title, company_address, supervisor, subordinate, roles} =
            pwp.company_job_profile || {}

          const activeDate = getJobDuration({
            startDate: pwp.start_date,
            endDate: pwp.end_date,
            isPresent: !!pwp.end_date,
          })

          const joinDate = pwp.join_date
            ? formatDate(pwp.join_date, 'MMMM dd, yyyy')
            : '-'
          const permanentDate = pwp.permanent_date
            ? formatDate(pwp.permanent_date, 'MMMM dd, yyyy')
            : '-'
          const preRetirementDate = pwp.pre_retirement_date
            ? formatDate(pwp.pre_retirement_date, 'MMMM dd, yyyy')
            : '-'

          const join = `Bergabung sejak ${joinDate} (Pegawai Tetap Sejak ${permanentDate})`
          const preretirement = `Prapensiun sejak ${preRetirementDate}`

          return (
            <WorkingInformationItem
              defaultMore
              key={pwp.id}
              avatar={pwp.company_logo?.logo}
              title={title}
              location={company_address?.office_name}
              activeDate={activeDate}
              joinDate={join}
              preretirementDate={preretirement}
              supervisor={supervisor}
              subordinate={subordinate}
              employeeId={pwp.regno || '-'}
              decreee={pwp.decree || '-'}
              officeEmail={pwp.email || '-'}
              organization={pwp.company_organization?.name || '-'}
              level={pwp.company_employee_rank?.name || '-'}
              employeeType={pwp.type}
              workingArrangement={pwp.type_place}
              timeArrangement={pwp.type_time}
              jobGrade={pwp.company_employee_rank?.job_grade || '-'}
              costCentre={pwp.company_organization?.cost_center}
              facility={pwp.facilities}
              acessRights={roles}
              attachments={pwp.attachment}
            />
          )
        })}
    </div>
  )
}
