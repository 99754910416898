// import {USER_ID} from '../../utils/globals'
import React, {useState, useEffect} from 'react'
// MUI
import Paper from '@material-ui/core/Paper'
import FilterListIcon from '@material-ui/icons/FilterList'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import BackIcons from '@material-ui/icons/ArrowBackTwoTone'
import SearchIcon from '@material-ui/icons/Search'
import {
  Typography,
  Button,
  Avatar,
  TextField,
  MenuItem,
  InputAdornment,
  TablePagination,
  Divider,
  IconButton,
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import {
  GET_TALENT_BOXES,
  GET_LIST_TALENT_LIST,
  USER_WORK,
} from '../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
import Moment from 'moment'

import useStyles, {
  TitleWrapStyled,
  TitleStyled,
  InfoStyled,
} from './CommitteeTaskStyles'
// import NineBoxes from './NineBoxes'
import {Link} from 'react-router-dom'
import {USER_ID, COMPANY_ID} from '../../utils/globals'
// import {MathBoxes, MathDetailBoxes} from '../../utils/helpers'
import Filter from './Filter'
import Loading from '../../components/loading/LoadingComponent'
import NewNineBoxes from '../shared-component/boxes/NineBoxesComponent'
import {GreyTypography} from '../../components/typography/TypographyStyles'
import {Flex} from '../../GlobalStyles'
import {parameters_talent_versions} from '../../utils/constants'

const HumanCapitalTalentVersion = props => {
  const [boxes, setBoxes] = useState(null)
  const [employee, setEmployee] = useState(null)
  // const [listBoxes, setListBoxes] = useState([])
  const [filter, setFilter] = useState({
    name: '',
    data: [],
  })
  const [state, setState] = useState({
    badge: [],
    aspiration: [],
    scoreFrom: '',
    scoreTo: '',
  })
  const [anchorEl, setAnchorEl] = useState(null)
  const [sorting, setSorting] = useState('')
  const [text, setText] = useState('')

  const classes = useStyles()
  const query = props.location.state
  const stateData = props.location.state.data
  const jobProfile =
    stateData && stateData.company_job_profile
      ? [stateData.company_job_profile.id]
      : null

  const [condList, setCondList] = useState({
    job_profile: jobProfile,
    version: props.match.params.id,
    badge: null,
    organization: null,
    position: null,
    office: null,
    aspiration: null,
    scoreFrom: null,
    scoreTo: null,
    filter: false,
    orderName: null,
    orderScore: null,
    searchName: null,
    // orderLatest: null,
  })
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [page, setPage] = React.useState(0)
  const condBoxes = {
    id: props.match.params.id,
    organization: condList.organization,
    position: condList.position,
    office: condList.office,
    aspiration: condList.aspiration,
    filter: condList.filter,
    search: condList.searchName,
    job_profile: jobProfile,
  }

  const {data: dataList, refetch, error: errList} = useQuery(
    GET_LIST_TALENT_LIST,
    {
      variables: condList,
    }
  )
  const {data: dataBoxes, refetch: refetchBoxes, error: errBoxes} = useQuery(
    GET_TALENT_BOXES,
    {
      variables: condBoxes,
    }
  )
  const {data: dataUserWork} = useQuery(USER_WORK, {
    variables: {
      user: USER_ID,
      company: COMPANY_ID,
    },
  })

  useEffect(() => {
    const condList = () => {
      document.title = `WLB - Talent - Comitte-Task - ${dataBoxes &&
        dataBoxes.talent_versions[0].name} `
      refetch()
      refetchBoxes()
      // setBoxes(MathBoxes(dataList, dataBoxes))
      // setListBoxes(MathDetailBoxes(dataBoxes, dataList))
      // setEmployee(MathBoxes(dataList, dataBoxes))
      setBoxes(dataBoxes)
      // setListBoxes(dataBoxes)
      setEmployee(dataList)
    }
    condList()
  }, [dataList, dataBoxes])

  // console.log('boxes:', boxes, 'emp: ', employee, ',var: ', condList)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSorting = event => {
    setSorting(event.target.value)
    switch (event.target.value) {
      case 'latest':
        setCondList({
          ...condList,
          orderLatest: 'desc',
          orderName: null,
          orderScore: null,
        })

        break
      case 'oldest':
        setCondList({
          ...condList,
          orderLatest: 'asc',
          orderName: null,
          orderScore: null,
        })

        break
      case 'name(a-z)':
        setCondList({
          ...condList,
          orderName: 'asc',
          orderLatest: null,
          orderScore: null,
        })

        break
      case 'scoreAsc':
        setCondList({
          ...condList,
          orderScore: 'asc',
          orderLatest: null,
          orderName: null,
        })

        break
      case 'scoreDesc':
        setCondList({
          ...condList,
          orderScore: 'desc',
          orderLatest: null,
          orderName: null,
        })

        break
      default:
        break
    }
  }
  const handleClickFilter = name => {
    let data = []
    switch (name) {
      case 'badge':
        dataBoxes &&
          dataBoxes.talent_versions[0].talent_boxes.forEach(element => {
            data.push({
              id: element.id,
              name: element.name,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      case 'aspiration':
        dataBoxes &&
          dataBoxes.aspiration.forEach(element => {
            data.push({
              id: element.company_job_profile.id,
              name: element.company_job_profile.title,
            })
          })
        setFilter({name: name, data: data})
        data = []
        break
      case 'point':
        setFilter({name: name, data: []})
    }
  }
  const handleChange = name => event => {
    const a = event.target.value
    const b = parseInt(a)
    switch (name) {
      case 'badge':
        var badge = state.badge
        if (!badge.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              badge: [...prevState.badge, b],
            }
          })
        } else {
          badge = badge.filter(e => e !== b)
          setState({...state, [name]: badge})
        }
        break
      case 'aspiration':
        var aspiration = state.aspiration
        if (!aspiration.includes(b)) {
          setState(prevState => {
            return {
              ...state,
              aspiration: [...prevState.aspiration, b],
            }
          })
        } else {
          aspiration = aspiration.filter(e => e !== b)
          setState({...state, [name]: aspiration})
        }
        break
      case 'scoreFrom':
        setState({...state, [name]: a})
        break
      case 'scoreTo':
        setState({...state, [name]: a})
        break
      default:
        break
    }
  }
  const resetFilter = name => {
    if (name === 'point') {
      setState({...state, scoreFrom: '', scoreTo: ''})
    } else {
      setState({...state, [name]: []})
    }
  }
  const resetAll = () => {
    setState({
      badge: [],
      aspiration: [],
      scoreFrom: '',
      scoreTo: '',
    })
  }
  const selectAll = name => {
    if (filter.name === name) {
      const loop = []
      filter.data.forEach(resx => {
        loop.push(resx.id)
      })
      setState({...state, [name]: loop})
    }
  }
  const handleApply = () => {
    setCondList({
      ...condList,
      version: props.match.params.id,
      badge: state.badge.length !== 0 ? state.badge : null,
      aspiration: state.aspiration.length !== 0 ? state.aspiration : null,
      scoreFrom: state.scoreFrom ? state.scoreFrom : null,
      scoreTo: state.scoreTo ? state.scoreTo : null,

      filter:
        state.aspiration.length !== 0 &&
        state.badge.length !== 0 &&
        state.scoreFrom &&
        state.scoreTo,
    })
    setAnchorEl(null)
  }
  const handleSearch = event => {
    setText(event.target.value)
    setCondList({
      ...condList,
      searchName: `%${event.target.value}%`,
    })
  }
  // const handleOnlyMyIssue = () => {
  //   let dataTemp = []
  //   if (only.length === 0) {
  //     query.data.filter.forEach(element => {
  //       setOnly([...only, element.job_profile])
  //       dataTemp.push(element.job_profile)
  //       setCondList({...condList, job_profile: dataTemp})
  //     })
  //   } else {
  //     setOnly([])
  //     dataTemp = []
  //     setCondList({...condList, job_profile: null})
  //   }
  // }
  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangeBage = id => {
    let tmp = condList.badge || []
    if (!tmp.includes(id)) {
      setCondList({...condList, badge: [id]})
    } else {
      tmp = tmp.filter(e => e !== id)
      setCondList({...condList, badge: null})
    }
  }

  const NoListComponent = () => {
    return (
      <Flex column flexAround style={{textAlign: 'center', marginTop: '20px'}}>
        <img
          src={require('../../assets/images/empty_state.svg')}
          alt="empty-state"
          style={{width: '30%', alignSelf: 'center'}}
        />
        <Typography
          variant="h6"
          className={classes.role}
          style={{marginTop: '20px'}}
        >
          No List
        </Typography>
      </Flex>
    )
  }

  if (
    (dataList === undefined && errList === undefined) ||
    (dataBoxes === undefined && errBoxes === undefined)
  ) {
    return <Loading />
  }

  return (
    <div>
      <Paper className={classes.paper}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '2rem',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton>
              <BackIcons
                style={{fontSize: 30}}
                onClick={() => props.history.goBack()}
              />
            </IconButton>

            <Typography variant="h6" style={{marginLeft: '1rem'}}>
              {dataBoxes && dataBoxes.talent_versions[0].name}
            </Typography>
          </div>
          <div
            style={{
              width: 'auto',
              height: '40px',
              borderRadius: 5,
              backgroundColor:
                query.status === 'Active' ? '#4caf50' : '#ef4d5e',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0px 16px',
            }}
          >
            <Typography
              variant="body1"
              style={{color: '#fff', textAlign: 'center'}}
            >
              {status[query.status] || query.status}
            </Typography>
          </div>
        </div>

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <AssignmentIndIcon />
            <TitleStyled>Klasifikasi Talenta</TitleStyled>
          </div>
          <Button
            variant="outlined"
            className={classes.filter}
            onClick={handleClick}
          >
            <FilterListIcon />
            <Typography className={classes.textFilter}>Saring</Typography>
          </Button>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            margin: '50px 0px 0px 0px',
          }}
        >
          <div
            style={{
              width: 400,
              height: 'auto',
              display: 'flex',
              alignItems: 'center',
              textAlign: 'right',
              boxSizing: 'border-box',
              paddingRight: 10,
            }}
          >
            <GreyTypography>
              {dataBoxes &&
                dataBoxes.talent_versions[0].flexy_components &&
                dataBoxes.talent_versions[0].flexy_components.y.name}{' '}
              ({getTalentParameters(dataBoxes, 'y')})
            </GreyTypography>
          </div>
          <NewNineBoxes
            newHeight={dataBoxes && dataBoxes.talent_versions[0].box_height}
            newWidth={dataBoxes && dataBoxes.talent_versions[0].box_width}
            data={boxes?.talent_versions[0].talent_boxes}
            badge={condList.badge && condList.badge[0]}
            handleChangeBage={handleChangeBage}
          />
          <div style={{width: 400, height: 'auto'}}></div>
        </div>
        <GreyTypography style={{textAlign: 'center'}}>
          {dataBoxes &&
            dataBoxes.talent_versions[0].flexy_components &&
            dataBoxes.talent_versions[0].flexy_components.x.name}{' '}
          ({getTalentParameters(dataBoxes, 'x')})
        </GreyTypography>
        <br />
        <div className={classes.header}>
          <TitleWrapStyled>
            <FormatListBulletedIcon />
            <TitleStyled>Daftar Talenta</TitleStyled>
          </TitleWrapStyled>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: '2rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '50%',
            }}
          >
            <TextField
              select
              variant="outlined"
              label="Sortir dengan"
              style={{width: '50%'}}
              onChange={handleSorting}
              value={sorting}
            >
              <MenuItem value="latest" style={{color: '#a9a8a8'}}>
                Terbaru
              </MenuItem>
              <MenuItem value="oldest" style={{color: '#a9a8a8'}}>
                Terlama
              </MenuItem>
              <MenuItem value="name(a-z)" style={{color: '#a9a8a8'}}>
                Nama (A-Z)
              </MenuItem>
              <MenuItem value="scoreDesc" style={{color: '#a9a8a8'}}>
                Skor Tertinggi
              </MenuItem>
              <MenuItem value="scoreAsc" style={{color: '#a9a8a8'}}>
                Skor Terendah
              </MenuItem>
            </TextField>
            {/* <div
              style={{
                width: '143.5px',
                height: '53px',
                borderRadius: '5px',
                backgroundColor: only.length === 0 ? null : '#014a62',
                marginLeft: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: only.length === 0 ? '#000' : '#ffff',
                cursor: 'pointer',
              }}
              onClick={handleOnlyMyIssue}
            >
              <Typography variant="body1">Only my issues</Typography>
            </div> */}
          </div>
          <TextField
            className={classes.search}
            variant="outlined"
            placeholder="Cari Talenta"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="primary" />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            value={text}
          />
        </div>
        <div className={classes.listContainer}>
          {employee && employee.talent_assignments.length > 0 ? (
            employee &&
            employee.talent_assignments
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((res, i) => {
                return (
                  <div key={i} className={classes.body}>
                    <Avatar
                      alt={res.people_work_placement.global_user.name}
                      style={{width: 80, height: 80}}
                      src={res.people_work_placement.global_user.avatar}
                    />
                    <InfoStyled>
                      <div className={classes.info1}>
                        <Typography
                          className={classes.name}
                          component={Link}
                          to={{
                            pathname: `/committee-task/${res.people_work_placement.global_user.id}/${dataBoxes?.talent_versions[0].id}`,
                            state: {
                              active: `committe-task`,
                              userwork:
                                dataUserWork &&
                                dataUserWork.people_work_placements[0].id,
                              newHeight:
                                dataBoxes &&
                                dataBoxes.talent_versions[0].box_height,
                              newWidth:
                                dataBoxes &&
                                dataBoxes.talent_versions[0].box_width,
                              dataBoxes,
                              idAssignment:
                                res.people_work_placement.talent_assignments[0]
                                  .id,
                            },
                          }}
                          style={{cursor: 'pointer'}}
                        >
                          {res.people_work_placement.global_user.name}
                        </Typography>
                        <Typography className={classes.role}>
                          {
                            res.people_work_placement.current_position
                              .company_employee_position.name
                          }{' '}
                          at{' '}
                          {
                            res.people_work_placement.current_position
                              .company_address.office_name
                          }
                        </Typography>
                      </div>
                      <Typography className={classes.info2}>
                        Ingin Menjadi{' '}
                        {res.people_work_placement.talent_aspirations.length !==
                        0
                          ? res.people_work_placement.talent_aspirations &&
                            res.people_work_placement.talent_aspirations[0]
                              .company_job_profile.company_employee_position
                              .name
                          : '-'}
                      </Typography>
                      <div className={classes.score}>
                        <img
                          alt="profil"
                          src={
                            res.people_work_placement.talent_assignments[0]
                              .talent_box.badge
                          }
                          className={classes.profil}
                        />
                        <Typography className={classes.classification}>
                          {
                            res.people_work_placement.talent_assignments[0]
                              .talent_box.name
                          }
                        </Typography>
                        <div className={classes.score2}>
                          <StarIcon style={{color: 'green'}} />
                          <Typography className={classes.score3}>
                            {res.people_work_placement.talent_assignments[0].talent_score.toFixed(
                              2
                            )}
                          </Typography>
                        </div>
                      </div>
                    </InfoStyled>
                    <Typography className={classes.time}>
                      {Moment(
                        res.people_work_placement.talent_assignments[0]
                          .date_modified
                      ).fromNow()}
                    </Typography>
                  </div>
                )
              })
          ) : (
            <NoListComponent />
          )}
        </div>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={employee && employee.talent_assignments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Filter
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleClickFilter={handleClickFilter}
        filterData={filter}
        handleChange={handleChange}
        state={state}
        handleApply={handleApply}
        resetFilter={resetFilter}
        resetAll={resetAll}
        selectAll={selectAll}
        setFilter={setFilter}
      />
    </div>
  )
}

export default HumanCapitalTalentVersion

const status = {
  INEVALUATION: 'Sedang Di Evaluasi',
}

const getTalentParameters = (dataBoxes, axis) => {
  const parameters =
    dataBoxes?.talent_versions?.[0]?.flexy_components?.[axis]?.parameters

  const arrParams =
    parameters?.map(
      ({parameter}) => parameters_talent_versions[parameter].ID || parameter
    ) || []

  return arrParams.join(', ')
}
