import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import NotFoundComponent from '../pages/error/NotFoundComponent'
import styled from 'styled-components'
import routes from '../configs/routesConfig'
import PrivateRoute from '../pages/private/PrivateRoute'
import NoRoles from '../pages/error/NoRoles'

const AppWrapper = styled.div``

const Main = () => {
  return (
    <AppWrapper>
      <Switch>
        <PrivateRoute
          exact={true}
          path="/"
          component={() => <Redirect to="/profile" />}
        />
        <Route path="/access-denied" component={NoRoles} />
        {routes.map((val, key) => (
          <Route
            exact={val.exact}
            key={key}
            path={val.path}
            component={val.component}
          />
        ))}
        <Route component={NotFoundComponent} />
      </Switch>
    </AppWrapper>
  )
}

export default Main
