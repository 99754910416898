import React from 'react'
import {AttachmentCard} from '@smartasn/wlb-utils-components'
import {Avatar, Divider, Typography} from '@material-ui/core'

import defaultAvatar from '../../../../assets/images/default-avatar.svg'

import {WrapperLicences} from './TrainingLisenceStyled'

export default function TrainingLicenseItem(props) {
  const [isMore, setIsMore] = React.useState(false)

  const {
    avatar,
    title,
    label,
    company,
    date,
    description,
    credential,
    url,
    attachments = [],
    isLast,
  } = props

  return (
    <WrapperLicences>
      <div className="content">
        <Avatar
          variant="square"
          src={avatar || defaultAvatar}
          className="avatar"
          onError={e => (e.target.src = defaultAvatar)}
        />
        <div className="detail-section">
          <Typography className="title">{title || '-'}</Typography>
          <Typography className="label">{label || '-'}</Typography>
          <Typography className="company">{company || '-'}</Typography>
          <Typography className="date">{date || '-'} </Typography>
          <Typography className="description">{description || '-'}</Typography>

          {isMore && (
            <div>
              <div style={{paddingTop: '10px'}}>
                <Typography className="certificate">
                  Tautan Sertifikat: <span>{url}</span>
                </Typography>

                <Typography className="credential">
                  Nomor sertifikat {credential}
                </Typography>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px',
                }}
              >
                {attachments.map((file, idx) => (
                  <AttachmentCard key={idx} name={file.name} url={file.link} />
                ))}
              </div>
            </div>
          )}
          <span className="see-more" onClick={() => setIsMore(prev => !prev)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>

          <Divider className={`divider ${isLast && 'hide'}`} />
        </div>
      </div>
    </WrapperLicences>
  )
}
