import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {GET_LIST_SEE_ALL_SKILLS} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'

import SkillsItem from './SkillsItem'
import LoadingItem from '../../../../components/loading/LoadingItem'

export default function Skills({userId}) {
  const {data, loading, error, fetchMore} = useQuery(GET_LIST_SEE_ALL_SKILLS, {
    fetchPolicy: 'network-only',
    context: {headers: {'X-Hasura-Role': 'user'}},
    variables: {
      id: userId || USER_ID,
      limit: 6,
    },
  })

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_profile_skills.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_profile_skills: [
            ...prev.people_profile_skills,
            ...fetchMoreResult.people_profile_skills,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Keahlian"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.people_profile_skills.length || 0}
          next={fetchMoreData}
          hasMore={data?.people_profile_skills.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.people_profile_skills.map((skill, idx) => {
            const {id, people_skill, people_skill_level} = skill
            return (
              <SkillsItem
                key={id}
                title={people_skill?.name}
                level={people_skill_level?.name}
                people_profile_skills={
                  people_skill?.people_profile_skills || []
                }
                totalPeople={
                  people_skill?.people_profile_skills_aggregate.aggregate
                    .count || 0
                }
                isLast={data?.people_profile_skills.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
