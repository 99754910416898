import React from 'react'
import {Redirect} from 'react-router-dom'
import {generateRoutesFromConfigs} from '../utils/helpers'
import {TalentProfileConfig} from '../pages/talent-profile/TalentProfileConfig'
import {MyTeamConfig} from '../pages/my-team/MyTeamConfig'
import {CommitteeTaskConfig} from '../pages/committee-task/CommitteeTaskConfig'

const routeConfigs = [TalentProfileConfig, MyTeamConfig, CommitteeTaskConfig]

const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/profile" />,
  },
]

export default routes
