import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import {Typography, TextField, Button, MenuItem} from '@material-ui/core'
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers'
import {withRouter} from 'react-router-dom'
import {
  GET_DETAIL_EDIT_ASPIRATION,
  JOB_PROFILE,
} from '../../graphql/queries/index'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {COMPANY_ID} from '../../utils/globals'
import {ADD_ASPIRATION, EDIT_ASPIRATION} from '../../graphql/mutations/index'
import Snackbar from './Snackbar'
import moment from 'moment'
import Section from './wrapper/Section'

const useStyles = makeStyles(() => ({
  wrapButton: {
    width: 169,
    height: 61.7,
    borderRadius: 5,
    display: 'block',
    marginLeft: 'auto',
  },
  typography: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: '1rem',
    marginLeft: 14,
  },
  textField: {
    width: '50%',
    marginLeft: 14,
  },
  time: {
    width: '40%',
    margin: '0px 0px 0px 14px',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '2rem',
  },
}))

const TODAY = moment(new Date()).format('YYYY-MM-DD')

const MutationAspiration = props => {
  const classes = useStyles()

  const [selectedDate, setSelectedDate] = useState(TODAY)
  const [change, setChange] = useState('')
  const [open, setOpen] = useState({open: false, message: null, type: null})
  const [error, setError] = useState(false)
  const [minDateValidation, setMinDateValidation] = useState(new Date())
  const {aspirationId, userwork, type} = props.location.state || {}

  const {data: job_profile} = useQuery(JOB_PROFILE, {
    variables: {
      company_id: COMPANY_ID,
    },
  })

  const {error: errorAspiration} = useQuery(GET_DETAIL_EDIT_ASPIRATION, {
    skip: type === 'Add' || !aspirationId,
    fetchPolicy: 'network-only',
    variables: {
      id: aspirationId,
    },
    onCompleted: data => {
      setChange(data?.talent_aspirations?.[0].company_job_profile.id)
      setSelectedDate(data?.talent_aspirations?.[0]?.planned_date || TODAY)
      if (type === 'Edit') {
        setMinDateValidation(data?.talent_aspirations?.[0]?.planned_date)
      }
    },
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  if (errorAspiration) {
    console.error(errorAspiration)
  }

  const [addAspiration] = useMutation(ADD_ASPIRATION)
  const [editAspiration] = useMutation(EDIT_ASPIRATION)

  const handleChange = e => {
    setChange(e.target.value)
  }

  const handleDateChange = e => {
    setSelectedDate(moment(e).format('YYYY-MM-DD'))
  }

  const handleback = () => {
    props.history.goBack()
  }

  const handleMutation = () => {
    if (!change || !selectedDate || selectedDate === 'Invalid date') {
      setError(true)
      const dxc = document.getElementById('content')
      dxc.scrollIntoView()
      setTimeout(() => setError(false), 1500)
    } else {
      if (type === 'Edit') {
        const dataEdit = {
          id: aspirationId,
          employee: userwork,
          aspired: change,
          date: selectedDate,
        }
        editAspiration({variables: dataEdit})
          .then(() => {
            setOpen({
              open: true,
              message: 'Ubah Aspirasi Berhasil',
              type: 'success',
            })
            handleback()
          })
          .catch(() => {
            setOpen({
              open: true,
              message: 'Ubah Aspirasi Gagal',
              type: 'Error',
            })
          })
      } else {
        const dataAdd = {
          employee: userwork,
          aspired: change,
          date: selectedDate,
        }
        addAspiration({variables: dataAdd}).then(() => {
          setOpen({
            open: true,
            message: 'Tambah Aspirasi Berhasil',
            type: 'success',
          })
          handleback()
        })
      }
    }
  }
  return (
    <Section
      id="content"
      title={`${type === 'Edit' ? 'Ubah' : 'Tambah'} Aspirasi`}
      onBack={handleback}
    >
      <div className={classes.formControl}>
        <Typography className={classes.typography}>
          Ekspektasi Pegawai
        </Typography>
        <TextField
          variant="outlined"
          className={classes.textField}
          onChange={handleChange}
          value={change}
          helperText="Konten tidak boleh kosong"
          error={error && !change}
          select
        >
          {job_profile?.company_job_profiles.map(res => {
            return (
              <MenuItem key={res.id} value={res.id}>
                {res.title}
              </MenuItem>
            )
          })}
        </TextField>
      </div>
      <div className={classes.formControl}>
        <Typography className={classes.typography}>Tanggal Rencana</Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            className={classes.time}
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            error={error && (!selectedDate || selectedDate === 'Invalid date')}
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            minDate={minDateValidation}
          />
        </MuiPickersUtilsProvider>
      </div>

      <Button
        variant="contained"
        color="primary"
        className={classes.wrapButton}
        onClick={handleMutation}
      >
        Simpan {type === 'Edit' && 'Perubahan'}
      </Button>
      <Snackbar open={open} setOpen={setOpen} />
    </Section>
  )
}

export default withRouter(MutationAspiration)
