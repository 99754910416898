import gql from 'graphql-tag'

export const GET_COMPANY_HEAD = gql`
  query getNominationByID($id: Int) {
    holding_nomination_config(where: {id: {_eq: $id}}) {
      id_nomination: id
      company_head: head_company
    }
  }
`
export const GET_DETAIL_NOMINATION_HOLDING = gql`
  query getDataDetailNomination($id_nomination: Int, $company_head: uuid) {
    detail: holding_nomination_config(where: {id: {_eq: $id_nomination}}) {
      id
      name
      description
      company_job_profile {
        id
        title
      }
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
    }

    current_incumbent: holding_nomination_config(
      where: {id: {_eq: $id_nomination}}
    ) {
      company_job_profile {
        people_work_placements {
          id
          regno
          start_date
          end_date
          placement_fields
          global_user {
            id
            name
            avatar
            talent_assignments {
              id
              talent_score
              badge_classification
              talentVersionByTalentVersion {
                id
                name
                start_date
                end_date
                indicator_perf_nps
                indicator_perf_ogf
                indicator_perf_goal
                indicator_perf_multirater
                indicator_pot_attendance
                indicator_pot_competence
                indicator_pot_socmed_post
                indicator_pot_total_course
                indicator_perf_nps_fields
                indicator_perf_ogf_fields
                indicator_perf_goal_fields
                indicator_perf_multirater_fields
                indicator_pot_attendance_fields
                indicator_pot_competence_fields
                indicator_pot_socmed_post_fields
                indicator_pot_total_course_fields
                box_width
                box_height
                max_perf
                max_pot
                min_perf
                min_pot

                talent_boxes {
                  id
                  name
                  index_x
                  index_y
                  badge
                  color_hex
                  description
                }
              }
              indicator_pot_competence
              indicator_pot_attendance
              indicator_pot_total_course
              indicator_pot_socmed_post
              indicator_pot_experience
              indicator_perf_ogf
              indicator_perf_goal
              indicator_perf_multirater
              indicator_perf_nps
              status
              talent_box {
                id
                name
                index_x
                index_y
                badge
                color_hex
                description
              }
            }
          }
        }
      }
    }

    successor: holding_nomination_people(
      where: {nomination_id: {_eq: $id_nomination}}
    ) {
      id
      status
      holding_source {
        people_work_placement {
          id
          regno
          start_date
          end_date
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            legal_name
            brand_name
          }
        }
      }
    }

    talent_pool: holding_source(
      where: {
        company_head: {_eq: $company_head}
        status: {_eq: "VERIFIED"}
        _not: {
          holding_nomination_people: {nomination_id: {_eq: $id_nomination}}
        }
      }
    ) {
      source_id: id
      people_work_placement {
        id
        regno
        global_user {
          id
          name
          avatar
        }
        company_job_profile {
          id
          title
        }
        company_profile {
          id
          legal_name
          brand_name
        }
      }
    }
  }
`
