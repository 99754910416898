import React from 'react'
import {Paper, Typography, makeStyles} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

import {AvatarStyled} from '../MyTeamStyles'
import InfoComponent from './InfoComponent'

import noBadge from '../../../assets/images/icon_badges_not_found.svg'

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
    boxShadow: '0px 3px 6px 0px #00000029',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '26px 48px',
    borderRadius: 5,
    cursor: 'pointer',
  },
  user: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  expansion: {
    padding: '0 14px 0 38px',
    border: 'none',
  },
  avatar: {
    marginRight: 45,
  },
  grayTitle: {
    color: '#a9a8a8',
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 260,
  },
  tabs: {
    background: '#f7f8f9',
    color: '#000000',
  },
  tab: {
    padding: '21px 47px',
  },
}))

const MyTeamItem = ({data, history, disallowEdit}) => {
  const {talent_box, talent_score} = data.talent_assignments?.[0] || {}
  const classes = useStyles()

  const handleClickUser = () => {
    history.push({
      pathname: '/my-team/detail',
      state: {
        active: 'my-team',
        userId: data.global_user.id,
        userName: data.global_user.name,
        disallowEdit,
      },
    })
  }

  return (
    <div onClick={handleClickUser} className={classes.root}>
      <div className={classes.user}>
        <AvatarStyled
          src={data.global_user.avatar}
          className={classes.avatar}
        />
        <InfoComponent
          name={data.global_user.name}
          title={data.company_job_profile?.title}
          want={data.talent_aspirations?.[0]}
        />
      </div>
      <Badge
        badge={talent_box?.badge}
        name={talent_box?.name}
        score={talent_score}
      />
    </div>
  )
}

const Badge = ({badge, name, score}) => {
  const classes = useStyles()

  return (
    <Paper
      elevation={0}
      style={{
        textAlign: 'center',
        width: 160,
      }}
    >
      <img src={badge || noBadge} alt="lencana" style={{width: '2vw'}} />
      <Typography variant="body2" className={classes.grayTitle}>
        {name || 'Lencana Kosong'}
      </Typography>
      {name && (
        <Typography variant="body2" className={classes.grayTitle}>
          {score.toFixed(2)}
        </Typography>
      )}
    </Paper>
  )
}

export default withRouter(MyTeamItem)
