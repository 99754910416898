import gql from 'graphql-tag'

export const GET_DROPDOWN_CHART_HOLDING = gql`
  query getList($company_head: uuid) {
    list_axis_x: holding_clusters(
      where: {company: {_eq: $company_head}}
      order_by: {id: asc}
    ) {
      id
      name
    }
    list_axis_y: holding_classes(
      where: {company: {_eq: $company_head}}
      order_by: {id: asc}
    ) {
      id
      name
    }
    list_axis_z: holding_categories(
      where: {company: {_eq: $company_head}}
      order_by: {id: asc}
    ) {
      id
      name
    }
  }
`

export const GET_HOLDING_CHART = gql`
  query($company_head: uuid!, $search_x: Int, $search_y: Int, $search_z: Int) {
    list_axis_x: holding_clusters(
      where: {
        company: {_eq: $company_head}
        id: {_eq: $search_x}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    list_axis_y: holding_classes(
      where: {
        company: {_eq: $company_head}
        id: {_eq: $search_y}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    holding_axis_z: holding_categories(
      where: {
        company: {_eq: $company_head}
        id: {_eq: $search_z}
        holding_version: {status: {_eq: "ACTIVE"}}
      }
      order_by: {name: asc_nulls_last}
    ) {
      id
      name
    }

    v_holding_chart(
      where: {
        company_head: {_eq: $company_head}
        member_cluster: {_eq: $search_x}
        member_class: {_eq: $search_y}
        member_category: {_eq: $search_z}
      }
    ) {
      company_head_profile {
        id
        legal_name
      }
      axis_x: holding_cluster {
        id
        name
      }
      axis_y: holding_class {
        id
        name
      }
      axis_z: holding_category {
        id
        name
      }
      count
    }
  }
`
