import React, {useState} from 'react'
import useStyles, {InfoStyled} from './CommitteeTaskStyles'
import {Paper, Typography, IconButton, Avatar} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Moment from 'moment'
import EditBadge from './ChangeBoxes'
import {TALENT_LIST_HOME} from '../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
import {isValidUrl, switchFlexyComponents} from '../../utils/helpers'
import IconSvg from '../../assets/images/icon_badges_not_found.svg'
import LoadingComponent from '../../components/loading/LoadingComponent'
import {BoldTypography} from '../../components/typography/TypographyStyles'
import CardItem from '../shared-component/CardItem'
import DetailAspiration from '../shared-component/dialog/DetailAspiration'
import ListDevelopmentPlan from '../talent-profile/development-plan/ListDevelopmentPlan'
import DetailDevelopmentPlan from '../shared-component/dialog/DetailDevelopmentPlan'

const TalentDetail = props => {
  const classes = useStyles()
  const [more, setMore] = useState(2)
  const [open, setOpen] = useState(false)

  const [detail, setDetail] = useState({
    openAspiration: false,
    openType: '',
    id: undefined,
  })

  // const data = props.location.state.data
  const idAssignment = props.location.state.idAssignment
  const condition = {
    user: props.match.params.id_user,
    version: props.match.params.id_version,
  }
  const {data: dataList, loading, error} = useQuery(TALENT_LIST_HOME, {
    variables: condition,
  })

  const dataPwp = dataList?.people_work_placements[0]

  if (loading || !dataPwp) {
    return <LoadingComponent />
  }
  if (error) {
    return JSON.stringify(error)
  }

  const dataAssignment =
    dataPwp?.talent_assignments.length > 0 && dataPwp.talent_assignments[0]
  const dataTalentByTalent = dataAssignment?.talentVersionByTalentVersion

  const handleback = () => {
    props.history.goBack()
  }
  const seeAll = () => {
    if (more !== dataPwp.talent_development_plans.length) {
      setMore(dataPwp.talent_development_plans.length)
    } else {
      setMore(2)
    }
  }
  const Items = ({data}) => {
    return (
      <Paper
        style={{
          textAlign: 'center',
          flexGrow: 1,
          flexShrink: 0,
        }}
        elevation={0}
      >
        <img src={data.talent_box?.badge} alt="badge" style={{width: '8vw'}} />
        <Typography variant="h6">{data.talent_box?.name}</Typography>
        <BoldTypography variant="h6">
          {data.talent_score?.toFixed(2)}
        </BoldTypography>
        <Typography variant="body2">
          {Moment(data.talentVersionByTalentVersion?.start_date).format('YYYY')}
          /{Moment(data.talentVersionByTalentVersion?.end_date).format('YYYY')}
        </Typography>
      </Paper>
    )
  }
  const ItemsNull = () => {
    return (
      <Paper
        style={{
          textAlign: 'center',
          flexGrow: 1,
          flexShrink: 0,
        }}
        elevation={0}
      >
        <img src={IconSvg} alt="badge" style={{width: '10vw'}} />
        <Typography variant="h6">No Badge</Typography>
      </Paper>
    )
  }
  const colorInformation = status => {
    if (status === 'HIGH') {
      return {
        fontWeght: 600,
        color: '#ef4d5e',
      }
    } else if (status === 'LOW') {
      return {
        fontWeght: 600,
        color: '#4caf50',
      }
    } else {
      return {
        fontWeght: 600,
        color: '#ffa000',
      }
    }
  }

  const xArr = []
  const yArr = []

  if (dataTalentByTalent?.flexy_components) {
    dataTalentByTalent.flexy_components.x.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'x', dataAssignment, xArr, yArr)
    )
    dataTalentByTalent.flexy_components.y.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'y', dataAssignment, xArr, yArr)
    )
  }

  const handleOpenDetail = (openType, id) => {
    setDetail({
      open: true,
      openType,
      id,
    })
  }

  return (
    <Paper className={classes.paper} elevation={1}>
      <div className={classes.headerRow}>
        <IconButton onClick={handleback} aria-label="delete">
          <ArrowBackIcon />
        </IconButton>
        <Typography className={classes.detailTitleName}>
          {dataPwp.global_user.name}&apos;s Detail Talenta
        </Typography>
      </div>
      <Paper className={classes.paperDetail}>
        <div className={classes.bodyDetail}>
          <Avatar
            alt={dataPwp.global_user.name}
            className={classes.largeTalent}
            src={dataPwp.global_user.avatar}
          />
          <InfoStyled>
            <div className={classes.infoDetail}>
              <Typography variant="h6">{dataPwp.global_user.name}</Typography>
              <Typography variant="body2" className={classes.role}>
                {dataPwp.regno}
                {/* id number */}
              </Typography>
            </div>
            <Typography variant="body1">
              {dataPwp.current_position.company_employee_position.name}
            </Typography>
            <Typography variant="body2" className={classes.role}>
              Sejak {Moment(dataPwp.company_date).format('LL')}
            </Typography>
          </InfoStyled>
        </div>
      </Paper>

      <Typography className={classes.titleDetail}>Lencana Talenta</Typography>
      <div className={classes.badge}>
        <Paper className={classes.paperBadge}>
          <div className={classes.titleBadge}>
            <BoldTypography variant="body1">Lencana Saat Ini</BoldTypography>
            {dataAssignment && (
              <BoldTypography
                variant="body1"
                className={classes.edit}
                onClick={() => setOpen(true)}
              >
                Ubah
              </BoldTypography>
            )}
          </div>

          <center>
            {dataAssignment ? (
              <Items data={dataAssignment} type="current" />
            ) : (
              <ItemsNull />
            )}
          </center>
        </Paper>
        <Paper className={classes.paperPostBadge}>
          <div className={classes.titleBadge}>
            <BoldTypography variant="body1">Lencana Lampau</BoldTypography>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              overflow: 'auto',
            }}
          >
            {dataPwp.past_badge.length > 0 ? (
              dataPwp.past_badge.map((res, i) => {
                return <Items data={res} type="past" key={i} />
              })
            ) : (
              <ItemsNull />
            )}
          </div>
        </Paper>
      </div>
      <div className={classes.wrapperTitle}>
        <Typography className={classes.titleDetail}>
          Penilaian Talenta
        </Typography>
        <Typography className={classes.lastUpdate}>
          Pembaharuan Terakhir{' '}
          {Moment(dataAssignment.date_modified).format('DD/MM/YYYY')}
        </Typography>
      </div>
      <Paper className={classes.paperRating}>
        <div className={classes.indicator1}>
          <Typography className={classes.titleIndicator}>
            {dataTalentByTalent?.flexy_components?.x.name}
          </Typography>
          <div className={classes.valueIndicator}>
            {xArr.length > 0 &&
              xArr.map((res, i) => (
                <div className={classes.itemIndicator} key={i}>
                  <Typography className={classes.text}>
                    {res.param} ({res.index})
                  </Typography>
                  <Typography className={classes.text}>{res.value}</Typography>
                </div>
              ))}
          </div>
          {/* <Typography className={classes.textNote}>
            Committee can edit indicator score to move talent classification
          </Typography> */}
        </div>
        <div className={classes.indicator2}>
          <Typography className={classes.titleIndicator}>
            {dataTalentByTalent?.flexy_components?.y.name}
          </Typography>
          <div className={classes.valueIndicator}>
            {yArr.length > 0 &&
              yArr.map((res, i) => (
                <div className={classes.itemIndicator} key={i}>
                  <Typography className={classes.text}>
                    {res.param} ({res.index})
                  </Typography>
                  <Typography className={classes.text}>{res.value}</Typography>
                </div>
              ))}
          </div>
          {/* <Typography className={classes.textNote}>
            Committee can edit inicator score to move talent classification
          </Typography> */}
        </div>
      </Paper>

      <Typography className={classes.titleDetail}>Informasi Talenta</Typography>
      <div className={classes.talentDetailTalentInfo}>
        <Paper className={classes.talentInfoPaper}>
          <Typography className={classes.talentDetailInfo}>
            Resiko Kehilangan
          </Typography>
          <Typography
            variant="body1"
            style={colorInformation(
              dataPwp.placement_fields.talent_information.risk_of_loss
            )}
          >
            {
              INFORMATION_LEVEL[
                dataPwp.placement_fields.talent_information.risk_of_loss
              ]
            }
          </Typography>
        </Paper>
        <Paper className={classes.talentInfoPaper}>
          <Typography className={classes.talentDetailInfo}>
            Akibat Kehilangan
          </Typography>
          <Typography
            variant="body1"
            style={colorInformation(
              dataPwp.placement_fields.talent_information.impact_of_loss
            )}
          >
            {
              INFORMATION_LEVEL[
                dataPwp.placement_fields.talent_information.impact_of_loss
              ]
            }
          </Typography>
        </Paper>
        <Paper className={classes.talentInfoPaper}>
          <Typography className={classes.talentDetailInfo}>
            Mobilisasi Talenta
          </Typography>
          <Typography
            variant="body1"
            style={{fontWeight: '600', color: '#a9a8a8'}}
          >
            {dataPwp.placement_fields.talent_information.reason_for_leaving
              ? dataPwp.placement_fields.talent_information.reason_for_leaving
              : 'Tidak ada'}
          </Typography>
        </Paper>
        <Paper className={classes.talentInfoPaper}>
          <Typography className={classes.talentDetailInfo}>
            Pemimpin Masa Depan
          </Typography>
          <Typography
            variant="body1"
            style={{
              fontWeight: '600',
              color: dataPwp.placement_fields.talent_information.future_leader
                ? '#4caf50'
                : '#ef4d5e',
            }}
          >
            {dataPwp.placement_fields.talent_information.future_leader
              ? 'Iya'
              : 'Tidak'}
          </Typography>
        </Paper>
      </div>

      <Typography className={classes.titleDetail}>Aspirasi</Typography>
      <Paper className={classes.aspiration}>
        {dataPwp.talent_aspirations.map(position => {
          const {job_icon} = position.company_job_profile || {}
          const image = isValidUrl(job_icon) ? job_icon : null
          return (
            <CardItem
              key={position.id}
              image={image}
              label={position?.company_job_profile?.title || '-'}
              onClick={() => handleOpenDetail('ASPIRATION', position.id)}
            />
          )
        })}
      </Paper>

      <Typography className={classes.titleDetail}>
        Rencana Pengembangan
      </Typography>
      {dataPwp.talent_development_plans.map(res => {
        const start = Moment(res.start_date).format('DD/MM/YYYY')
        const end = res.end_date
          ? Moment(res.end_date).format('DD/MM/YYYY')
          : null

        const date = [start, end].filter(date => date).join(' - ')
        return (
          <ListDevelopmentPlan
            key={res.id}
            name={res.name}
            date={date}
            status={res.status}
            measureOfSuccess={res.measure_of_success}
            onClick={() => handleOpenDetail('DEVELOPMENT_PLAN', res.id)}
          />
        )
      })}
      {dataPwp.talent_development_plans.length !== 2 ? (
        <Typography variant="h6" className={classes.seeMore} onClick={seeAll}>
          {more === dataPwp.talent_development_plans.length
            ? 'Lihat Sedikit'
            : 'Lihat Semua'}
        </Typography>
      ) : null}
      <EditBadge
        newHeight={props.location.state.newHeight}
        newWidth={props.location.state.newWidth}
        data={props.location.state.dataBoxes}
        dataCurrent={dataAssignment}
        open={open}
        onClose={() => setOpen(false)}
        back={() => props.history.goBack()}
        nameUser={dataPwp.global_user.name}
        idAssignment={idAssignment}
      />

      <DetailAspiration
        id={detail.id}
        open={detail.open && detail.openType === 'ASPIRATION'}
        onClose={() => setDetail({open: false, openType: '', id: undefined})}
        type="SUGGESTED"
      />

      <DetailDevelopmentPlan
        id={detail.id}
        open={detail.open && detail.openType === 'DEVELOPMENT_PLAN'}
        onClose={() => setDetail({open: false, openType: '', id: undefined})}
      />
    </Paper>
  )
}

export default TalentDetail

const INFORMATION_LEVEL = {
  LOW: 'Rendah',
  MEDIUM: 'Sedang',
  HIGH: 'Tinggi',
}
