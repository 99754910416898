import React from 'react'

import {makeStyles} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    maxWidth: '70%',
  },
  wrapName: {
    display: 'flex',
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    color: '#014a62',
    fontFamily: 'Muli',
    marginBottom: 4,
  },
  role: {
    fontSize: 14,
    fontWeight: 400,
    color: '#a9a8a8',
  },
  want: {
    color: '#a9a8a8',
    fontSize: 14,
    fontFamily: 'Muli',
  },
}))

const InfoComponent = ({name, title, want}) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <Typography className={classes.name}>
        {name} <span className={classes.role}>{title}</span>
      </Typography>
      {!!want && (
        <Typography className={classes.want}>
          ingin menjadi{' '}
          {want.company_job_profile?.company_employee_position?.name}
        </Typography>
      )}
    </div>
  )
}

export default InfoComponent
