import React from 'react'
import {Paper, makeStyles, Typography, Icon} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  paper: {
    padding: '16px 24px',
    marginBottom: 16,
    boxShadow: '0px 3px 6px 0px #00000029',
    borderRadius: 5,
    cursor: 'pointer',
  },
  titleJob: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003d51',
    cursor: 'pointer',
  },
  dates: {
    textAlign: 'left',
    color: '#a9a8a8',
  },
  description: {
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#a9a8a8',
    width: '70%',
    marginTop: '2rem !important',
  },
  root: {
    justifyContent: 'space-between',
    display: 'flex',
  },
  root2: {
    display: 'flex',
  },
  fake: {
    fontSize: '18px',
    color: '#007fb2',
    width: 150,
    textAlign: 'center',
  },
  status: {
    fontWeight: 'bold',
    color: '#fff',
  },
}))

const current = {
  ONPROGRESS: 'Dalam Proses',
  WAITING: 'Menunggu',
  DONE: 'Selesai',
}

const color = {
  ONPROGRESS: '#039be5',
  WAITING: '#ffa000',
  DONE: '#4caf50',
}

export default function ListDevelopmentPlan(props) {
  const {name, date, status, onClick, onClickMore, measureOfSuccess} = props
  const classes = useStyles()

  const handleClickMore = e => {
    e.stopPropagation()
    onClickMore(e)
  }

  return (
    <Paper onClick={onClick} elevation={0} className={classes.paper}>
      <div className={classes.root}>
        <div>
          <Typography variant="h6" className={classes.titleJob}>
            {name}
          </Typography>
          <Typography variant="body1" className={classes.dates}>
            {date}
          </Typography>
        </div>
        <div className={classes.root2}>
          <div
            style={{
              width: '100px',
              height: '25px',
              borderRadius: '5px',
              backgroundColor: color[status],
              textAlign: 'center',
              paddingTop: '0.2rem',
            }}
          >
            <Typography variant="body2" className={classes.status}>
              {current[status]}
            </Typography>
          </div>
          {onClickMore && (
            <Icon onClick={handleClickMore} style={{cursor: 'pointer'}}>
              more_vert
            </Icon>
          )}
        </div>
      </div>
      <p className={classes.description}>{measureOfSuccess}</p>
    </Paper>
  )
}
