import React from 'react'
import {
  Avatar,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'

import JobIcon from '../../assets/images/default-job.png'

const useStyles = makeStyles(() => ({
  paper: {
    position: 'relative',
    width: '151px',
    height: '151px',
    borderRadius: '5px',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    margin: 24,
    padding: 10,
    cursor: 'pointer',
    boxShadow: '0px 3px 6px 0px #00000029',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  title: {
    marginTop: 8,
    maxWidth: '90%',
    textAlign: 'center',
    lineHeight: '1.25',
    letterSpacing: 'normal',
    color: '#003d51',
    fontSize: 16,
    margin: '7px 2px',
    fontWeight: 600,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  titlePaper: {
    color: '#a9a8a8',
    marginTop: 4,
    lineHeight: '1.2',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
  },
  image: {
    width: 60,
    height: 'auto',
    marginBottom: 4,
  },
  button: {
    position: 'absolute',
    right: 12,
    display: 'flex',
    marginLeft: 'auto',
    padding: 0,
  },
  textButton: {
    width: '100%',
    padding: 6,
  },
  fake: {
    fontSize: '18px',
    width: 120,
    textAlign: 'center',
  },
  input: {
    width: '50%',
  },
  icon: {
    width: 80,
    marginTop: '-1rem',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 12,
  },
}))

export default function CardItem(props) {
  const classes = useStyles()
  const {onClick, onClickAction, label, title, image, withImage = true} = props

  const handleClickAction = e => {
    e.stopPropagation()
    onClickAction(e)
  }

  return (
    <Paper elevation={0} className={classes.paper} onClick={onClick}>
      {onClickAction && (
        <IconButton
          className={classes.button}
          size="small"
          onClick={handleClickAction}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      <div className={classes.center}>
        {withImage && (
          <Avatar
            variant="square"
            src={image || JobIcon}
            alt="icon"
            className={classes.image}
          />
        )}
        {title && (
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
        )}
        <Typography variant="body1" className={classes.titlePaper}>
          {label}
        </Typography>
      </div>
    </Paper>
  )
}
