import React, {useState} from 'react'
import {makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'

import {COMPANY_ID, USER_ID} from '../../utils/globals'
import {GET_LIST_MYTEAM} from '../../graphql/queries'

import MyTeamItem from './list-my-team/MyTeamItem'
import Section from '../shared-component/wrapper/Section'
import LoadingItem from '../../components/loading/LoadingItem'

const useStyles = makeStyles(() => ({
  seeMore: {
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#039be5',
    fontSize: 20,
    cursor: 'pointer',
  },
  emptyState: {
    padding: '46px 0px',
  },
}))

const LIMIT = 6

const MyTeam = () => {
  const [loadingMore, setLoadingMore] = useState(false)
  const classes = useStyles()

  const {data, loading, error, fetchMore} = useQuery(GET_LIST_MYTEAM, {
    variables: {
      company: COMPANY_ID,
      supervisor: USER_ID,
      offset: 0,
      limit: LIMIT,
    },
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count

  const handleFetchMore = () => {
    const isMore = totalData > data?.people_work_placements.length

    setLoadingMore(true)

    fetchMore({
      variables: {offset: isMore ? data?.people_work_placements.length : 0},
      updateQuery: (prev, {fetchMoreResult}) => {
        setLoadingMore(false)
        if (!isMore) return fetchMoreResult
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_work_placements: [
            ...prev.people_work_placements,
            ...fetchMoreResult.people_work_placements,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  return (
    <div id="top">
      <Section title="Tim">
        {loading && <LoadingItem count={LIMIT} />}

        {!loading &&
          data?.people_work_placements?.map(res => {
            const isPeer = res.company_job_profile.is_peer.aggregate.count > 0
            return <MyTeamItem key={res.id} data={res} disallowEdit={isPeer} />
          })}

        {loading || (loadingMore && <LoadingItem count={LIMIT} />)}

        {!loading && totalData === 0 && (
          <div className={classes.emptyState}>
            <EmptyState
              message1="Maaf, tidak ada daftar"
              message2="Anda tidak terdaftar di tim mana pun"
              width={280}
            />
          </div>
        )}

        {!loading && totalData > LIMIT && (
          <h5 className={classes.seeMore} onClick={handleFetchMore}>
            {totalData > data?.people_work_placements.length
              ? 'Lihat lebih banyak'
              : 'Lihat lebih sedikit'}
          </h5>
        )}
      </Section>
    </div>
  )
}

export default MyTeam
