import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {GET_LIST_DETAIL_GAP_COMPETENCY} from '../../../../graphql/queries'

import GapCompetencyItem from './GapCompetencyItem'
import LoadingItem from '../../../../components/loading/LoadingItem'

export default function GapCompetency({userWorkId}) {
  const {data, loading, error, fetchMore} = useQuery(
    GET_LIST_DETAIL_GAP_COMPETENCY,
    {
      skip: !userWorkId,
      fetchPolicy: 'network-only',
      variables: {
        placement: userWorkId,
        limit: 2,
      },
    }
  )

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.competency_individuals.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          competency_individuals: [
            ...prev.competency_individuals,
            ...fetchMoreResult.competency_individuals,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Kompetensi Kesenjangan"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.competency_individuals.length || 0}
          next={fetchMoreData}
          hasMore={data?.competency_individuals.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.competency_individuals.map((competency, idx) => {
            const {
              competency_dictionary,
              competency_version,
              competency_model_items,
              individual_level,
            } = competency.competency_version_dictionary || {}

            const individual = individual_level || 0
            const required = competency_model_items?.[0]?.required_level || 0

            return (
              <GapCompetencyItem
                key={competency.id}
                title={competency_dictionary?.name?.en}
                category={competency_dictionary?.competency_category?.name}
                type={competency_dictionary?.type}
                label={competency_version?.name}
                requiredLevel={required}
                individualLevel={individual}
                gap={individual - required}
                description={competency_dictionary?.description?.text}
                levels={competency_dictionary?.description?.levels}
                isLast={data?.competency_individuals.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
