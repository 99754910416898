import React from 'react'
import Skeleton from 'react-loading-skeleton'

import {makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function LoadingItem(props) {
  const {height = 168, count = 2, width = '100%', direction = 'column'} = props
  const classes = useStyles()

  return (
    <Skeleton
      width={width}
      height={height}
      count={count}
      containerClassName={direction === 'row' ? classes.row : classes.column}
    />
  )
}
