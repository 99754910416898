import React from 'react'
import {Avatar, Divider, Typography} from '@material-ui/core'
import {AttachmentCard} from '@smartasn/wlb-utils-components'

import defaultAvatar from '../../../../assets/images/default-avatar.svg'
import {WrapperProfessionalItem} from '../../TalentProfileStyles'

export default function EducationItem(props) {
  const [isMore, setIsMore] = React.useState(false)

  const {
    avatar,
    school,
    degree,
    date,
    description,
    location,
    certificate,
    attachments = [],
    gpa,
    isLast,
  } = props

  return (
    <WrapperProfessionalItem>
      <div className="content">
        <Avatar
          variant="square"
          src={avatar || defaultAvatar}
          className="avatar"
          alt="logo"
        />
        <div className="detail-section">
          <Typography className="mb-8 fs-14 fw-600">{school}</Typography>
          <Typography className="mb-8 fs-12 fw-400">{location}</Typography>
          <Typography className="mb-8 fs-12 fw-400 color-secondary">
            {degree}
          </Typography>
          <Typography className="mb-8 fs-12 fw-400 color-dimmed">
            {date}
          </Typography>
          <Typography className="mb-8 fs-12">{`Indeks Prestasi Kumulatif (IPK): ${gpa ||
            '-'}`}</Typography>
          <Typography className="fs-12 mb-16">
            {description?.slice(0, (!isMore && 370) || description?.length) ||
              '-'}
          </Typography>
          {isMore && (
            <>
              <Typography className="fs-12 fw-400 color-primary">{`Nomor Ijazah ${certificate ||
                '-'}`}</Typography>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px',
                }}
              >
                {attachments.map((file, idx) => (
                  <AttachmentCard
                    key={idx}
                    name={file.name}
                    url={file.url || file.link}
                  />
                ))}
              </div>
            </>
          )}

          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
          <Divider className={`divider ${isLast && 'hide'}`} />
        </div>
      </div>
      <div className="divider" />
    </WrapperProfessionalItem>
  )
}
