import {
  Avatar,
  Divider,

  //   Button,
  Icon,
  IconButton,
  Paper,
  TablePagination,
  Typography,
  Tooltip,
  TextField,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {GreyTypography} from '../../../../components/typography/TypographyStyles'
import {BoldTypography} from '../../../../GlobalStyles'
import {SEE_ALL_SUCCESSOR} from '../../../../graphql/queries/holding/committee/seeAllSuccessor.query'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {SEE_ALL_POOL} from '../../../../graphql/queries/holding/committee/seeAllPool.query'
import {
  ADD_TO_NOMINATION_HOLDING,
  REMOVE_FROM_NOMINATION_HOLDING,
} from '../../../../graphql/mutations'

import Snackbar from '../../../shared-component/Snackbar'
import DeletePopup from '../../../shared-component/DeletePopup'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: 30,
  },
  paperContainer: {
    padding: 24,
    marginLeft: 12,
    marginBottom: 30,
    minHeight: 417,
  },
  paper2: {
    padding: '20px 30px 0px 30px',
    minHeight: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  informationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
  },
  badgeWrapper: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 16,
  },
  name: {
    fontSize: 16,
  },
  commite: {
    color: '#A9A8A8',
    fontSize: 14,
    margin: '0px 0px 7px 0px',
  },
  year: {
    color: '#4CAF50',
    fontSize: 10,
  },
  avatar: {
    width: '80px !important',
    height: '80px !important',
    marginRight: 30,
  },
  badge: {
    width: 40,
    margin: 'auto',
  },
  rank: {
    fontSize: 10,
    marginTop: 6,
  },
  score: {
    fontSize: 12,
    fontWeight: '600',
  },
  grey: {
    color: '#A9A8A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px 0px',
  },
  title: {
    fontSize: 16,
    color: '#A9A8A8',
    marginBottom: 10,
  },
  pagination: {
    marginTop: 'auto',
  },
  addButton: {
    marginLeft: 'auto',
    marginRight: 20,
    padding: '9px 34px',
  },
  flexTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dividerLittle: {
    height: '20px !important',
    margin: ' 0 4px 0 7px !important',
  },
  flexHeader: {display: 'flex', alignItems: 'center'},
  divider: {margin: '24px 0'},
  avaLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  filtercontainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
}))

const SeeAll = props => {
  const classes = useStyles()
  const {type, company_head, detail} = props.location.state
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const [confirmType, setConfirmType] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [search, setSearch] = useState('')
  const [id, setId] = useState('')
  const [openSnc, setOpenSnc] = useState({
    open: false,
    message: null,
    type: null,
  })

  const [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    event.preventDefault()
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleOpen = (ids, type) => {
    setOpen(true)
    setId(ids)
    // setName(names)
    setConfirmType(type)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const variables =
    type === 'pool'
      ? {
          id_nomination: props.match.params.id,
          company_head,
          search: `%${search}%`,
          order:
            sortBy === 'name'
              ? {people_work_placement: {global_user: {name: 'asc'}}}
              : {},
        }
      : {
          id_nomination: props.match.params.id,
          search: `%${search}%`,
          order:
            sortBy === 'name'
              ? {
                  holding_source: {
                    people_work_placement: {global_user: {name: 'asc'}},
                  },
                }
              : {},
        }

  const {data, error, refetch} = useQuery(
    type === 'pool' ? SEE_ALL_POOL : SEE_ALL_SUCCESSOR,
    {
      variables,
      // context: {headers: {'X-Hasura-Role': 'organization-staff'}},
      fetchPolicy: 'cache-and-network',
    }
  )

  const [mutationAddToNomination] = useMutation(ADD_TO_NOMINATION_HOLDING)
  const [mutationDelFromNomination] = useMutation(
    REMOVE_FROM_NOMINATION_HOLDING
  )

  const handleMutation = () => {
    if (confirmType === 'add') {
      const object = {
        source_id: id,
        nomination_id: props.match.params.id,
      }
      mutationAddToNomination({
        variables: {object},
      })
        .then(() => {
          setOpenSnc({
            open: true,
            message: `Update data success`,
            type: 'success',
          })
          refetch()
        })
        .catch(e => {
          setOpenSnc({
            open: true,
            message: JSON.stringify(e),
            type: 'error',
          })
        })
    } else {
      mutationDelFromNomination({
        variables: {id},
      })
        .then(() => {
          setOpenSnc({
            open: true,
            message: `Update data success`,
            type: 'success',
          })
          refetch()
        })
        .catch(e => {
          setOpenSnc({
            open: true,
            message: JSON.stringify(e),
            type: 'error',
          })
        })
    }
  }

  if (data === undefined && error === undefined) {
    return <div>loading</div>
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  const response = type === 'pool' ? data.talent_pool : data.successor
  return (
    <Paper className={classes.paperContainer}>
      <div className={classes.flexHeader}>
        <IconButton onClick={props.history.goBack}>
          <Icon>arrow_back</Icon>
        </IconButton>
        <Avatar
          variant="rounded"
          src={detail.company_profile.logo}
          className={classes.avaLarge}
          style={{marginRight: 24, marginLeft: 12}}
        />
        <div className={classes.flexInnerHeader}>
          <div>
            <Typography variant="body1" color="primary">
              {detail.name}
            </Typography>
            <div style={{display: 'flex'}}>
              <Typography
                variant="body2"
                color="secondary"
                style={{marginBottom: 14, marginRight: 6}}
              >
                {detail.company_job_profile.title} at{' '}
                {detail.company_profile.brand_name}
              </Typography>
              <GreyTypography variant="body2" style={{marginBottom: 14}}>
                {' '}
                / {type === 'pool' ? 'Talent Pool' : 'Successor Nomination'}
              </GreyTypography>
            </div>
          </div>
        </div>
      </div>
      <Divider className={classes.divider} />
      <BoldTypography variant="body1" gutterBottom>
        {type === 'pool' ? 'Talent Pool' : 'Successor Nomination'}
      </BoldTypography>
      <div className={classes.filtercontainer}>
        <TextField
          size="small"
          select
          variant="outlined"
          margin="normal"
          value={sortBy}
          onChange={e => setSortBy(e.target.value)}
          label="Sort By"
          style={{minWidth: 165}}
        >
          <MenuItem value="" disabled>
            Sort By
          </MenuItem>
          <MenuItem value="name">Name</MenuItem>
        </TextField>
        <TextField
          size="small"
          placeholder="Search Talent"
          margin="normal"
          variant="outlined"
          value={search}
          onChange={e => setSearch(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon color="primary">search</Icon>
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>

      <div style={{minHeight: '35vh'}}>
        {(rowsPerPage > 0
          ? response.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : response
        ).map((rowData, i) => {
          const avatar =
            type === 'pool'
              ? rowData.people_work_placement.global_user.avatar
              : rowData.holding_source.people_work_placement.global_user.avatar

          const name =
            type === 'pool'
              ? rowData.people_work_placement.global_user.name
              : rowData.holding_source.people_work_placement.global_user.name

          const position =
            type === 'pool'
              ? rowData.people_work_placement.company_job_profile.title
              : rowData.holding_source.people_work_placement.company_job_profile
                  .title

          const office =
            type === 'pool'
              ? rowData.people_work_placement.company_profile.brand_name
              : rowData.holding_source.people_work_placement.company_profile
                  .brand_name
          const badge =
            type === 'pool'
              ? rowData.people_work_placement.talent_assignments[0].talent_box
                  .badge
              : rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_box.badge
          const score =
            type === 'pool'
              ? rowData.people_work_placement.talent_assignments[0].talent_score
              : rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_score

          const badgeName =
            type === 'pool'
              ? rowData.people_work_placement.talent_assignments[0].talent_box
                  .name
              : rowData.holding_source.people_work_placement
                  .talent_assignments[0].talent_box.name
          return (
            <div key={i} className={classes.row}>
              <Avatar src={avatar} alt={name} className={classes.avatar} />
              <div className={classes.informationWrapper}>
                <Typography className={classes.name} gutterBottom>
                  {name}
                </Typography>
                <Typography className={classes.commite}>
                  {`${position} at ${office}`}
                </Typography>
                <div className={classes.flexTitle}>
                  <img
                    src={badge}
                    alt="asd"
                    width="20"
                    style={{marginRight: 6}}
                  ></img>
                  <Typography variant="caption">{badgeName}</Typography>
                  <Divider
                    orientation="vertical"
                    className={classes.dividerLittle}
                  ></Divider>
                  <Icon
                    style={{
                      fontSize: 20,
                      color: '#4caf50',
                      marginRight: 4,
                    }}
                  >
                    star
                  </Icon>
                  <Typography variant="caption">{score.toFixed(2)}</Typography>
                </div>
              </div>
              <div className={classes.badgeWrapper}>
                {type === 'pool' ? (
                  <Tooltip title="Add to Talent Nomination">
                    <IconButton
                      size="small"
                      onClick={() => handleOpen(rowData.source_id, 'add')}
                    >
                      <Icon color="primary">person_add</Icon>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title="Remove from Talent Nomination">
                    <IconButton
                      size="small"
                      onClick={() => handleOpen(rowData.id, 'delete')}
                    >
                      <Icon color="error">person_remove</Icon>
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
          )
        })}
      </div>
      <div className={classes.pagination}>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={response.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>

      <DeletePopup
        open={open}
        type={confirmType}
        handleClose={handleClose}
        mutation={handleMutation}
      />
      <Snackbar open={openSnc} setOpen={setOpenSnc} />
    </Paper>
  )
}

export default withRouter(SeeAll)
