import React, {useState} from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Icon,
  makeStyles,
  Divider,
} from '@material-ui/core'
import {GET_ALL_USER_BADGE} from '../../../graphql/queries/index'
import {useQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../../utils/globals'
import LoadingComponent from '../../../components/loading/LoadingComponent'
import {BoldTypography} from '../../../components/typography/TypographyStyles'
import DetailAllBadge from './DetailAllBadge'

const useStyles = makeStyles(() => ({
  talentDetailHeader: {
    backgroundColor: '#eff2f4',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '24px',
  },
  talentDetailTitle: {
    fontWeight: '600',
  },
  iconClose: {
    cursor: 'pointer',
    padding: '16px 24px',
    marginLeft: 'auto',
    fontSize: '32px !important',
    paddingRight: 0,
  },
  iconBack: {
    cursor: 'pointer',
    padding: '16px 24px',
    fontSize: '32px !important',
    paddingLeft: 0,
  },
  paper: {
    padding: 30,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  paper2: {
    padding: 24,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2rem',
  },
  titleBadge: {
    marginLeft: '2rem',
  },
  desc: {
    color: '#a9a8a8',
  },
  badge: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 26,
  },
  score: {
    color: '#039be5',
  },
  padding: {
    padding: 40,
    width: '100%',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    marginBottom: '1rem',
  },
  displayRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  talentInfoScore1: {
    width: 150,
    textAlign: 'right',
    marginLeft: 'auto',
    marginTop: '2rem',
  },
  descr: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    '&:nth-child(n+1)': {
      fontSize: 20,
      fontWeight: '600',
    },
  },
  name: {
    maxWidth: 340,
  },
  badgeName: {
    maxWidth: 560,
  },
}))

export default function ModalAll({onClose, open, name}) {
  const classes = useStyles()
  const [isDetail, setIsDetail] = useState(false)
  const [id, setId] = useState(null)

  const {data, loading, error} = useQuery(GET_ALL_USER_BADGE, {
    variables: {
      user: USER_ID,
    },
  })

  if (loading) {
    return <LoadingComponent />
  }
  if (error) {
    return JSON.stringify(error)
  }

  const handleClickBadge = id => {
    setIsDetail(prev => !prev)
    setId(id)
  }

  const closeModal = () => {
    onClose()
    setIsDetail(false)
  }

  return (
    <Dialog
      scroll="body"
      fullWidth
      maxWidth={`${isDetail ? 'lg' : 'sm'}`}
      onClose={closeModal}
      open={open}
    >
      <div className={classes.talentDetailHeader}>
        {isDetail && (
          <Icon
            className={classes.iconBack}
            color="primary"
            onClick={handleClickBadge}
          >
            arrow_back
          </Icon>
        )}
        <Typography variant="h6" className={classes.talentDetailTitle}>
          {isDetail ? `Detail ${name} Lencana` : `${name} Lencana`}
        </Typography>
        <Icon className={classes.iconClose} onClick={closeModal}>
          close
        </Icon>
      </div>
      <DialogContent>
        {isDetail && id ? (
          <DetailAllBadge classes={classes} id={id} />
        ) : (
          data.talent_assignments.map((res, i) => (
            <div key={i}>
              <div
                className={classes.paper}
                key={i}
                onClick={() => {
                  handleClickBadge(res.id)
                }}
              >
                <img
                  src={res.talent_box?.badge}
                  alt={res.talent_box?.name}
                  style={{width: 110}}
                />
                <div className={classes.titleBadge}>
                  <BoldTypography className={classes.name} variant="h6">
                    {res.talent_box?.name}
                  </BoldTypography>
                  <BoldTypography variant="body1" className={classes.desc}>
                    {res.talentVersionByTalentVersion?.name}
                  </BoldTypography>
                  <BoldTypography variant="body1" className={classes.score}>
                    {res.talent_score?.toFixed(2)}
                  </BoldTypography>
                </div>
              </div>
              <Divider style={{marginLeft: '31%'}} />
            </div>
          ))
        )}
      </DialogContent>
    </Dialog>
  )
}
