export const parameters_talent_versions = {
  attendance: {EN: 'Attendance', ID: 'Kehadiran'},
  competency: {EN: 'Competency', ID: 'Kompetensi'},
  course: {EN: 'Course', ID: 'Pembelajaran'},
  socmed_post: {EN: 'Social Media Post', ID: 'Postingan Sosial Media'},
  task: {EN: 'Task', ID: 'Rencana Hasil Kerja'},
  ogf: {EN: 'On Going Feedback', ID: 'Umpan Balik Berkelanjutan'},
  multirater: {EN: 'Multirater', ID: 'Multirater'},
  nps: {EN: 'NPS', ID: 'NPS'},
}
