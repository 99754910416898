import gql from 'graphql-tag'

export const ADD_ASPIRATION_MYTEAM = gql`
  mutation($employee: Int, $aspired: Int, $date: date) {
    insert_talent_aspirations(
      objects: {
        employee_placement: $employee
        aspired_job: $aspired
        planned_date: $date
      }
    ) {
      affected_rows
    }
  }
`

export const DELETE_ASPIRATION = gql`
  mutation($id: Int!) {
    q1: update_talent_aspirations(
      _set: {deletedAt: "now()"}
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }
  }
`
