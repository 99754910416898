import styled from 'styled-components'

export const WrapperExperience = styled.div`
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: row;

    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 5px;
      margin-right: 50px;
    }

    .detail-section {
      display: flex;
      flex-direction: column;
      margin-right: auto;
      width: 100%;

      p {
        margin: 0px;
      }

      .title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 8px;
      }

      .company {
        font-size: 12px;
        color: #007fb2;
        margin-bottom: 8px;
      }

      .date {
        font-size: 12px;
        text-align: left;
        color: #a9a8a8;
        margin-bottom: 8px;
      }
      .line-of-business {
        font-size: 12px;
        text-align: left;
        color: #a9a8a8;
        margin-bottom: 12px;
      }
      .location {
        font-size: 12px;
        text-align: left;
        color: #014a62;
        margin-bottom: 8px;
      }
      .description {
        font-size: 12px;
        color: #000000;
        white-space: break-spaces;
        overflow-wrap: break-word;
        margin-bottom: 8px;
      }
      .achievement {
        font-size: 12px;
        color: #000000;
        white-space: break-spaces;
        overflow-wrap: break-word;
        margin-bottom: 12px;
      }
      .info {
        color: #a9a8a8;
        font-size: 12px;

        span {
          color: #333333;
        }
      }
      .reason {
        margin-bottom: 10px;
      }
      .salary {
        margin-bottom: 16px;
      }
      .reference {
        color: #007fb2;
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      .ref {
        font-size: 12px;
        margin-bottom: 8px;
      }
      .ref-name {
        color: #000;
      }
      .ref-position {
        color: #a9a8a8;
      }

      .see-more {
        margin-top: 8px;
        font-size: 12px;
        cursor: pointer;
        user-select: none;
        color: #039be5;
      }

      .divider {
        margin: 30px 0px;
      }

      .hide {
        display: none;
      }
    }
  }
`
