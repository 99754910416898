import React, {memo} from 'react'
import {Avatar, Divider} from '@material-ui/core'
import {WrapperExperience} from './ExperienceStyled'

import defaultAvatar from '../../../../assets/images/default-avatar.svg'

export default memo(function ExperienceItem({
  avatar,
  title,
  company,
  date,
  location,
  description,
  achievements,
  reasonForLeaveing,
  refName,
  refPosition,
  refPhone,
  company_line_of_business,
  isLast,
}) {
  const [isMore, setIsMore] = React.useState(false)

  return (
    <WrapperExperience>
      <div className="content">
        <Avatar
          variant="square"
          src={avatar || defaultAvatar}
          className="avatar"
          alt="logo"
        />
        <div className="detail-section">
          <div className="title">{title}</div>
          <div className="company">{company}</div>
          <div className="location">{location}</div>
          <div className="date">{date}</div>
          <div className="line-of-business">{company_line_of_business}</div>
          <p className="description">
            {description?.slice(0, (!isMore && 370) || description?.length) ||
              '-'}
          </p>
          {isMore && (
            <>
              <p className="achievement">{achievements || '-'}</p>
              <p className="info reason">
                Alasan Keluar Perusahaan:{' '}
                <span>{reasonForLeaveing || '-'}</span>
              </p>
              <p className="reference">Referensi</p>
              <p className="ref ref-name">{refName || '-'}</p>
              <p className="ref ref-position">{refPosition || '-'}</p>
              <p className="ref ref-phone">{refPhone || '-'}</p>
            </>
          )}
          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
          <Divider className={`divider ${isLast && 'hide'}`} />
        </div>
      </div>
    </WrapperExperience>
  )
})
