import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {GET_LIST_TRAINING_LISENCE} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {formatDate} from '../../talent-profile-helper'

import TrainingLicenseItem from './TrainingLicenseItem'
import HeaderSection from '../shared-professional-information/HeaderSection'
import LoadingItem from '../../../../components/loading/LoadingItem'

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 20,
  },
  wrapper: {
    marginBottom: 36,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
}))

const LIMIT = 2

export default function TrainingLicense({userId, to}) {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_TRAINING_LISENCE, {
    fetchPolicy: 'network-only',
    variables: {id: userId || USER_ID, limit: LIMIT},
    context: {headers: {'X-Hasura-Role': 'user'}},
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.wrapper}>
      <HeaderSection title="Pelatihan, Lisensi & Sertifikasi" />

      {loading && <LoadingItem />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Pelatihan, Lisensi & Sertifikasi"
          width={280}
        />
      )}

      {!loading &&
        data?.people_profile_certificates.map((certificate, idx) => {
          const label = [certificate.level, certificate.type]
            .filter(certificate => certificate)
            .join(' - ')

          return (
            <TrainingLicenseItem
              key={certificate.id}
              avatar={certificate.company_profile?.logo}
              title={certificate.name}
              label={label}
              company={certificate.issuer_name}
              date={formatDate(certificate.issues_date)}
              description={certificate.description}
              credential={certificate.credential_id}
              url={certificate.credential_url}
              attachments={certificate.attachments}
              isLast={data?.people_profile_certificates.length === idx + 1}
            />
          )
        })}

      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 3,
          }}
        >
          +{total} Lebih Banyak Pelatihan, Lisensi & Sertifikasi
        </Typography>
      )}
    </div>
  )
}
