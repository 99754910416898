import React from 'react'
import {
  UserLoggedInProfileWrapper,
  RenderBadges,
} from '../job-portal/JobPortalStyles'
import {getCookie} from '../../utils/helpers'
import {withRouter} from 'react-router-dom'
import TalentSidebar from '../../pages/shared-component/sidebar-menu/TalentSidebar'
import {USER_ID, SOSMED_APP_URL} from '../../utils/globals'

const customStyle = {
  usernameLabel: {
    color: '#252525',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 8,
    cursor: 'pointer',
  },

  posisitionLabel: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 8,
    marginBottom: 8,
    width: 180,
  },
}

const renderSidebar = activeTab => {
  switch (activeTab) {
    case 'profile':
      return <TalentSidebar />
    case 'my-team':
      return <TalentSidebar />
    default:
      return null
  }
}

const UserLoggedIn = props => {
  let regexUrl = ''
  if (props.location.pathname) {
    regexUrl = props.location.pathname.match('[a-z0-9-]+')
  }
  const activeTab = regexUrl ? regexUrl[0] : '/'
  const data = getCookie('userData') && JSON.parse(getCookie('userData'))

  const handleClick = () => {
    window.location.replace(`${SOSMED_APP_URL}/profile/${USER_ID}/detail`)
  }

  return (
    <>
      <UserLoggedInProfileWrapper onClick={handleClick}>
        <img
          alt="user-logged-icon"
          src={
            data.avatar
              ? data.avatar
              : require('../../assets/slicing/default-avatar.png')
          }
          height="120"
          width="120"
          style={{
            borderRadius: '50%',
          }}
        />
        <label style={customStyle.usernameLabel}>{data && data.name}</label>
        <label style={customStyle.posisitionLabel}>
          {data && `${data.position_name} di ${data.company_name}`}
        </label>
      </UserLoggedInProfileWrapper>
      <RenderBadges>{renderSidebar(activeTab)}</RenderBadges>
    </>
  )
}

export default withRouter(UserLoggedIn)
