import gql from 'graphql-tag'

export const EDIT_TALENT_INFORMATION = gql`
  mutation($id: Int, $talent: jsonb!) {
    update_people_work_placements(
      where: {id: {_eq: $id}}
      _set: {placement_fields: $talent}
    ) {
      returning {
        placement_fields
      }
    }
  }
`

export const EDIT_BADGES = gql`
  mutation($id: Int!, $talentBoxId: Int!) {
    update_talent_assignments(
      where: {id: {_eq: $id}}
      _set: {badge_classification: $talentBoxId}
    ) {
      affected_rows
    }
  }
`
