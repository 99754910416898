import gql from 'graphql-tag'

export const ADD_ASPIRATION = gql`
  mutation($employee: Int, $aspired: Int, $date: date) {
    insert_talent_aspirations(
      objects: {
        employee_placement: $employee
        aspired_job: $aspired
        planned_date: $date
      }
    ) {
      affected_rows
    }
  }
`
export const EDIT_ASPIRATION = gql`
  mutation($id: Int!, $employee: Int, $aspired: Int, $date: date) {
    update_talent_aspirations(
      where: {id: {_eq: $id}}
      _set: {
        employee_placement: $employee
        aspired_job: $aspired
        planned_date: $date
      }
    ) {
      affected_rows
    }
  }
`
