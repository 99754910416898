import React from 'react'
// import development from '../../assets/slicing/webondev.svg'
import error404 from '../../assets/slicing/404.png'
import {Button} from '@material-ui/core'
import {withRouter} from 'react-router-dom'

const NotFoundComponent = props => {
  const style = {
    absolute: {
      height: '86vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    wrapper: {
      width: '86vw',
      display: 'flex',
      flexDirection: 'row',
      height: 'auto',
      alignItems: 'center',
      flexWrap: 'wrap',
      backgroundSize: 'cover',
      justifyContent: 'space-between',
      // marginLeft: '-42vw',
    },
    textRight: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      fontFamily: 'muli',
      margin: 'auto',
    },
    h1: {
      fontFamily: 'inherit',
      fontWeight: '600',
    },
    p: {
      fontFamily: 'inherit',
      color: '#a9a8a8',
      margin: '10px 0px 30px 0px',
      fontSize: 14,
    },
    image: {
      width: '260px',
      marginLeft: 'auto',
      marginRight: '6vw',
      fontFamily: 'muli',
    },
    textWrapper: {
      maxWidth: '36vw',
      minWidth: '500px',
    },
  }

  return (
    <div style={style.absolute}>
      <div style={style.wrapper}>
        <img style={style.image} src={error404} />
        <div style={style.textWrapper}>
          <div style={style.textRight}>
            <h1 style={style.h1}>Oops, Page not found</h1>
            <p style={style.p}>
              We are really sorry for the inconvenience, It look like you to
              trying to access a page that has been deleted or never exsisted
            </p>
            <div>
              <Button
                disableElevation
                style={{width: 'auto', fontSize: 12, padding: '8px 18px'}}
                color="primary"
                variant="contained"
                onClick={() => props.history.goBack()}
              >
                Back to Homepage
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(NotFoundComponent)
