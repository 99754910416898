import React from 'react'
import useStyles from '../../committee-task/CommitteeTaskStyles'
import {Dialog, DialogContent, Typography, Paper, Icon} from '@material-ui/core'
import {capitalize} from '../../../utils/helpers'
import {BoldTypography} from '../../../components/typography/TypographyStyles'

import moment from 'moment'

export default function ModalDetail({onClose, open, data, xArr, yArr}) {
  const classes = useStyles()
  const statusInformation = status => {
    const style = {fontWeght: 600}
    if (status === 'HIGH') {
      return {
        level: 'Tinggi',
        style: {...style, color: '#ef4d5e'},
      }
    } else if (status === 'LOW') {
      return {
        level: 'Rendah',
        style: {...style, color: '#4caf50'},
      }
    } else if (status === 'MEDIUM') {
      return {
        level: 'Sedang',
        style: {...style, color: '#FFA713'},
      }
    } else {
      return {
        level: status,
        style: {...style, color: '#ffa000'},
      }
    }
  }

  const placement_fields =
    data?.people_work_placement?.placement_fields?.talent_information

  const resTalent = data?.talentVersionByTalentVersion

  return (
    <Dialog scroll="body" fullWidth maxWidth="lg" onClose={onClose} open={open}>
      <div className={classes.talentDetailHeader}>
        <BoldTypography variant="h6">Detail Lencana</BoldTypography>
        <Icon className={classes.iconClose} onClick={onClose}>
          close
        </Icon>
      </div>
      <DialogContent>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Paper className={classes.globalInfoTalentDetail}>
            <div style={{marginRight: 25}}>
              <BoldTypography variant="body1" paragraph>
                Deskripsi
              </BoldTypography>
              <img
                src={data?.talent_box?.badge}
                alt={data?.talent_box?.name}
                style={{width: 135}}
              />
            </div>
            <div style={{marginTop: '2rem'}}>
              <BoldTypography variant="body1" paragraph>
                Versi : {data?.talentVersionByTalentVersion?.name}
              </BoldTypography>
              <BoldTypography variant="body1" paragraph>
                {data?.talent_box?.name}
              </BoldTypography>
              <Typography variant="body1">
                {data?.talent_box?.description}
              </Typography>
            </div>
          </Paper>
          <Paper className={classes.talentDetaiInfo}>
            <BoldTypography variant="body1">Rata-Rata</BoldTypography>
            <Typography
              variant="h2"
              style={{
                fontWeight: 'bold',
                marginLeft: '1rem',
                textAlign: 'center',
                marginTop: '2rem',
              }}
            >
              {data?.talent_score?.toFixed(2)}
              <Icon
                style={{fontSize: 50, fontWeight: 'bold', color: '#4caf50'}}
              >
                arrow_upward
              </Icon>
            </Typography>
            <BoldTypography
              variant="body1"
              style={{textAlign: 'center', marginRight: '2rem'}}
            >
              Poin
            </BoldTypography>
          </Paper>
        </div>
        <div className={classes.talentDetailTalentInfo}>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Resiko Kehilangan
            </Typography>
            <Typography
              variant="body1"
              style={statusInformation(placement_fields?.risk_of_loss)['style']}
            >
              {data &&
                capitalize(
                  statusInformation(placement_fields?.risk_of_loss)['level']
                )}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Dampak Kehilangan
            </Typography>
            <Typography
              variant="body1"
              style={
                statusInformation(placement_fields?.impact_of_loss)['style']
              }
            >
              {data &&
                capitalize(
                  statusInformation(placement_fields?.impact_of_loss)['level']
                )}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Mobilisasi Talenta
            </Typography>
            <Typography
              variant="body1"
              style={{fontWeight: '600', color: '#a9a8a8'}}
            >
              {placement_fields?.reason_for_leaving}
            </Typography>
          </Paper>
          <Paper className={classes.talentInfoPaper}>
            <Typography className={classes.talentDetailInfo}>
              Pemimpin Masa Depan
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontWeight: '600',
                color: placement_fields?.future_leader ? '#4caf50' : '#ef4d5e',
              }}
            >
              {placement_fields?.future_leader ? 'Iya' : 'Tidak'}
            </Typography>
          </Paper>
        </div>

        <div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
          <Paper className={classes.padding} style={{marginRight: '1rem'}}>
            <div className={classes.displayRow}>
              <BoldTypography variant="body1" gutterBottom>
                {resTalent?.flexy_components?.x.name}
              </BoldTypography>
              <Typography variant="caption" paragraph>
                Terakhir diperbarui{' '}
                {data?.date_modified
                  ? moment(data.date_modified).format('DD/MM/YYYY')
                  : '-'}
              </Typography>
            </div>
            <Typography variant="body1" align="right" gutterBottom>
              Tercapai
            </Typography>
            {xArr?.length > 0 &&
              xArr.map((axis, i) => (
                <div className={classes.displayRow} key={i}>
                  <Typography variant="body1">
                    {axis.param} ({axis.index})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                  >
                    {axis.value}
                  </Typography>
                </div>
              ))}
          </Paper>

          <Paper className={classes.padding}>
            <div className={classes.displayRow}>
              <BoldTypography variant="body1">
                {resTalent?.flexy_components?.y.name}
              </BoldTypography>
              <Typography variant="caption" paragraph>
                Terakhir diperbarui{' '}
                {data?.date_modified
                  ? moment(data.date_modified).format('DD/MM/YYYY')
                  : '-'}
              </Typography>
            </div>
            <Typography variant="body1" align="right" gutterBottom>
              Tercapai
            </Typography>
            {yArr?.length > 0 &&
              yArr.map((axis, i) => (
                <div className={classes.displayRow} key={i}>
                  <Typography variant="body1">
                    {axis.param} ({axis.index})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                  >
                    {axis.value}
                  </Typography>
                </div>
              ))}
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  )
}
