import React from 'react'
import {Chip, Typography, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    height: 'auto',
    border: '1px solid #E5E5E5',
    borderRadius: 40,
    padding: '12px 30px',
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    marginBottom: 2,
  },
  level: {
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 12,
  },
  label: {
    whiteSpace: 'break-spaces',
  },
}))

export default function SkilsItem({title, level}) {
  const classes = useStyles()

  return (
    <Chip
      variant="outlined"
      classes={{root: classes.root, label: classes.label}}
      label={
        <div>
          <Typography className={classes.title}>{title}</Typography>
          <Typography color="primary" className={classes.level}>
            {level}
          </Typography>
        </div>
      }
    />
  )
}
