import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {USER_ID, NAME} from '../../../../utils/globals'
import {GET_LIST_AWARDS} from '../../../../graphql/queries'
import {formatDate} from '../../talent-profile-helper'

import LoadingItem from '../../../../components/loading/LoadingItem'
import AwardItem from '../../professional-information/award/AwardItem'

export default function Award({userId, userName}) {
  const {data, loading, error, fetchMore} = useQuery(GET_LIST_AWARDS, {
    fetchPolicy: 'network-only',
    variables: {id: userId || USER_ID, limit: 6},
    context: {headers: {'X-Hasura-Role': 'user'}},
  })

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_profile_awards.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_profile_awards: [
            ...prev.people_profile_awards,
            ...fetchMoreResult.people_profile_awards,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Penghargaan"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.people_profile_awards.length}
          next={fetchMoreData}
          hasMore={data?.people_profile_awards.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.people_profile_awards?.map((award, idx) => {
            const date = formatDate(award.reference_date, 'MMM dd, yyyy')

            return (
              <AwardItem
                key={award.id}
                avatar={award?.company_profile?.logo}
                title={award.award_name || '-'}
                company={award.issuing_organization || '-'}
                date={date}
                reference={`Nomor Referensi ${award.reference_number || '-'}`}
                awardto={`Diberikan kepada ${userName || NAME}`}
                awardNumber={`${award.letter_number} - ${award.certificate_number}`}
                description={award.remark || '-'}
                attachments={award.attachments}
                isLast={data?.people_profile_awards.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
