import React, {useState} from 'react'

import {makeStyles, Menu, MenuItem, ListItemText} from '@material-ui/core'
import {useMutation, useQuery} from '@apollo/react-hooks'
import {Link} from 'react-router-dom'
import RssFeedIcon from '@material-ui/icons/RssFeed'

import {GET_LIST_ASPIRATION_PROFILE} from '../../../graphql/queries'
import {COMPANY_ID, USER_ID} from '../../../utils/globals'
import {DELETE_ASPIRATION} from '../../../graphql/mutations'

import ListAspiration from './ListAspiration'
import DetailAspiration from '../../shared-component/dialog/DetailAspiration'
import Snackbar from '../../shared-component/Snackbar'
import DeletePopup from '../../shared-component/DeletePopup'
import Section from '../../shared-component/wrapper/Section'
import Tabs from '../../../components/tab/Tabs'
import Tab from '../../../components/tab/Tab'

const useStyles = makeStyles(() => ({
  fake: {
    fontSize: '18px',
    color: '#007fb2',
    width: 150,
    textAlign: 'center',
  },
}))

export default function Aspiration({userId, userWorkId, origin, disallowEdit}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState({element: null, data: null})
  const [openDelete, setOpenDelete] = useState(false)
  const [positionType, setPositionType] = useState(0)
  const [detail, setDetail] = useState({
    open: false,
    type: '',
    id: null,
  })
  const [openSnc, setOpenSnc] = useState({
    open: false,
    message: null,
    type: null,
  })

  const [deleteAspiration] = useMutation(DELETE_ASPIRATION)

  const {data, loading, error, refetch} = useQuery(
    GET_LIST_ASPIRATION_PROFILE,
    {
      fetchPolicy: 'network-only',
      variables: {
        user: userId || USER_ID,
        company: COMPANY_ID,
      },
    }
  )

  if (error) {
    console.error(error)
  }

  const handleChanngeTab = (event, newValue) => {
    event.preventDefault()
    setPositionType(newValue)
  }

  const handleOpenDetail = (id, type) => {
    setDetail({
      open: true,
      type,
      id,
    })
  }

  const handleCloseDetail = () => {
    setDetail({
      open: false,
      id: null,
    })
  }

  const handleClickAction = (e, data) => {
    setAnchorEl({element: e.currentTarget, data})
  }

  const handleDeleteAspiration = () => {
    deleteAspiration({
      variables: {
        id: anchorEl.data.id,
      },
    })
      .then(() => {
        refetch()
        setOpenSnc({
          open: true,
          message: `Hapus ${anchorEl.data.company_job_profile.title} Berhasil`,
          type: 'success',
        })
        setAnchorEl({element: null, data: null})
      })
      .catch(() => {
        setOpenSnc({
          open: true,
          message: `Hapus ${anchorEl.data.company_job_profile.title} Gagal`,
          type: 'error',
        })
      })
  }

  return (
    <>
      <Section title="Aspirasi" icon={RssFeedIcon} childrenStyle={{padding: 0}}>
        <Tabs value={positionType} onChange={handleChanngeTab}>
          <Tab label="Posisi Sekarang" />
          <Tab label="Posisi Disarankan" />
        </Tabs>

        {positionType === 0 && (
          <ListAspiration
            positions={data?.current_position || []}
            loading={loading}
            onClick={id => handleOpenDetail(id, 'CURRENT')}
          />
        )}
        {positionType === 1 && (
          <ListAspiration
            positions={data?.talent_aspirations || []}
            loading={loading}
            onClick={id => handleOpenDetail(id, 'SUGGESTED')}
            addActionTo={
              !disallowEdit && {
                pathname: `/${origin ||
                  'profile'}/aspiration/add/${userWorkId}`,
                state: {
                  active: `${origin || 'profile'}`,
                  type: 'Add',
                  userwork: userWorkId,
                },
              }
            }
            withAction={!disallowEdit}
            onClickAction={handleClickAction}
          />
        )}
      </Section>

      <DetailAspiration
        open={detail.open}
        id={detail.id}
        type={detail.type}
        onClose={handleCloseDetail}
      />

      <Menu
        id="aspiration-menu"
        anchorEl={anchorEl.element}
        keepMounted
        open={Boolean(anchorEl.element)}
        onClose={() => setAnchorEl({element: null, data: null})}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={classes.fake}
          component={Link}
          to={{
            pathname: `/${origin || 'profile'}/aspiration/edit/${userWorkId}`,
            state: {
              active: `${origin || 'profile'}`,
              type: 'Edit',
              userwork: userWorkId,
              aspirationId: anchorEl.data?.id,
            },
          }}
        >
          <ListItemText primary="Ubah" />
        </MenuItem>

        <MenuItem onClick={() => setOpenDelete(true)} className={classes.fake}>
          <ListItemText primary="Hapus" />
        </MenuItem>
      </Menu>

      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={handleDeleteAspiration}
      />
      <Snackbar open={openSnc} setOpen={setOpenSnc} />
    </>
  )
}
