import React, {useMemo, useState} from 'react'

import {Avatar, Grid, Typography, makeStyles} from '@material-ui/core'
import {AttachmentCard} from '@smartasn/wlb-utils-components'

import {
  accessRightNEWSwitcher,
  getSubordinate,
  getSupervisor,
} from '../../talent-profile-helper'

import SeeAllHierarchy from './SeeAllHierarchy'

const useStyles = makeStyles(() => ({
  wrapper: {padding: 0},
  employees: {
    marginBottom: 36,
  },
  employeesLabel: {
    fontWeight: 400,
    fontSize: 14,
    marginBottom: 16,
  },
  employee: {
    display: 'flex',
    marginBottom: 16,
  },
  employeeAvatar: {
    marginRight: 8,
    width: 40,
    height: 40,
  },
  employeeName: {
    fontSize: 14,
    fontWeight: 600,
    color: '#333333',
    marginBottom: 2,
  },
  employeePosition: {
    fontSize: 12,
    fontWeight: 400,
    color: '#A9A8A8',
  },
  detail: {
    display: 'flex',
    flexDireciton: 'row',
    marginBottom: 20,
  },
  detailLabel: {
    minWidth: 122,
    fontSize: 12,
    fontWeight: 400,
    color: '#333333',
    marginRight: 25,
  },
  detailValue: {
    fontSize: 12,
    fontWeight: 400,
    color: '#A9A8A8',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 1,
  },
  facilityName: {
    fontSize: 12,
    fontWeight: 400,
    color: '#A9A8A8',
    marginBottom: 4,
  },
  itm: {
    fontSize: 12,
    fontWeight: 400,
    color: '#A9A8A8',
    marginBottom: 10,
  },
  showMore: {
    fontSize: 12,
    fontWeight: 600,
    color: '#039BE5',
    cursor: 'pointer',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    color: '#A9A8A8',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    color: '#A9A8A8',
  },
  dimmed: {
    color: '#A9A8A8',
  },
}))

export default function WorkingInformationDetail(props) {
  const classes = useStyles()

  const [detail, setDetail] = useState({
    open: false,
    data: null,
  })

  const {
    subordinate,
    supervisor,
    employeeId,
    decreee,
    officeEmail,
    organization,
    level,
    employeeType,
    workingArrangement,
    timeArrangement,
    jobGrade,
    costCentre,
    facility,
    acessRights,
    attachments,
  } = props

  const sub = useMemo(() => getSubordinate(subordinate), [])
  const sup = useMemo(() => getSupervisor(supervisor), [])

  const handleClickMore = () => {
    setDetail({open: true, data: {subordinate: sub, supervisor: sup}})
  }

  return (
    <>
      <Grid className={classes.wrapper} container spacing={2}>
        <Grid item xs={12} md={5}>
          <Mate
            data={sup}
            total={sup?.length || 0}
            label="Atasan Langsung"
            onMore={handleClickMore}
          />
          <Mate
            data={sub}
            total={sub?.length || 0}
            label="Bawahan Langsung"
            onMore={handleClickMore}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Detail label="NIP" value={employeeId} />
          <Detail label="Surat Ketetapan" value={decreee} />
          <Detail label="Email" value={officeEmail} />
          <Detail label="Organisasi" value={organization} />
          <Detail label="Jabatan" value={level} />
          <Detail label="Jenis Kepegawaian" value={employeeType} />
          <Detail label="Pengaturan Lokasi Kerja" value={workingArrangement} />
          <Detail label="Pengaturan Waktu Kerja" value={timeArrangement} />
          <Detail label="Kelas Jabatan" value={jobGrade} />
          <Detail label="Pusat Pembiayaan" value={costCentre} />
          <Detail label="Fasilitas" value={<Facilities data={facility} />} />
          <Detail
            label="Hak Akses"
            value={<AccessRight data={acessRights} />}
          />
          <Detail label="Lampiran" value={<Attachments data={attachments} />} />
        </Grid>
      </Grid>

      <SeeAllHierarchy
        open={detail.open}
        data={detail.data}
        onClose={() => setDetail({open: false, data: null})}
      />
    </>
  )
}

const Mate = ({label, data, total, onMore}) => {
  const classes = useStyles()

  return (
    <div className={classes.employees}>
      <Typography className={classes.employeesLabel}>{label}</Typography>

      {data?.slice(0, 5).map((employee, idx) => {
        return <Employee employee={employee} key={idx} />
      }) || <Typography className={classes.dimmed}>-</Typography>}
      {total > 5 && (
        <Typography onClick={onMore} className={classes.showMore}>
          Lihat Lebih Banyak...
        </Typography>
      )}
    </div>
  )
}

export const Employee = ({employee}) => {
  const classes = useStyles()

  return (
    <div className={classes.employee}>
      <Avatar
        src={employee.global_user?.avatar}
        className={classes.employeeAvatar}
        alt={employee.global_user?.name}
      />
      <div>
        <Typography className={classes.employeeName}>
          {employee.global_user?.name}
        </Typography>
        <Typography className={classes.employeePosition}>
          {employee?.title}
        </Typography>
      </div>
    </div>
  )
}

const Detail = ({label, value}) => {
  const classes = useStyles()

  const isString = typeof value === 'string'

  return (
    <div className={classes.detail}>
      <Typography className={classes.detailLabel}>{label}</Typography>
      {isString ? (
        <Typography className={classes.detailValue}>{value || '-'}</Typography>
      ) : (
        value
      )}
    </div>
  )
}

const Facilities = ({data}) => {
  const classes = useStyles()

  return (
    <div className={classes.col}>
      {data?.map((f, idx) => (
        <Typography key={idx} className={classes.itm}>
          {f.name}
        </Typography>
      )) || '-'}
    </div>
  )
}

const AccessRight = ({data}) => {
  const classes = useStyles()

  return (
    <div className={classes.col}>
      {accessRightNEWSwitcher(data).map((ar, idx) => (
        <Typography key={idx} className={classes.itm}>
          {ar}
        </Typography>
      )) || '-'}
    </div>
  )
}

const Attachments = ({data}) => {
  const classes = useStyles()

  return (
    <div className={classes.row}>
      {data?.map((file, idx) => (
        <AttachmentCard
          key={idx}
          name={file.name}
          url={file.link || file.url}
        />
      )) || '-'}
    </div>
  )
}
