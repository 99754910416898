import styled from 'styled-components'
import {Avatar, Accordion, Typography} from '@material-ui/core'

export const AvatarStyled = styled(Avatar)`
  width: 87px;
  height: 89px;
`
export const ExpansionPanelStyled = styled(Accordion)`
  padding: 38px;
`

export const TitleJob = styled(Typography)`
  text-align: center;
  line-height: 1.25;
  letter-spacing: normal;
  color: #003d51;
  font-size: 16px;
  margin: 7px 2px;
  font-weight: 600;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
