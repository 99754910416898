import {Paper, Typography} from '@material-ui/core'
import React, {useState} from 'react'
import moment from 'moment'
// import NineBoxes from '../../committee-task/NineBoxes'
import {useQuery} from '@apollo/react-hooks'
import {GET_DETAIL_USER_BADGE} from '../../../graphql/queries'
import LoadingComponent from '../../../components/loading/LoadingComponent'
import {
  BoldTypography,
  GreyTypography,
} from '../../../components/typography/TypographyStyles'
import NineBoxes from '../boxes/NineBoxesComponent'
import {switchFlexyComponents} from '../../../utils/helpers'

export default function DetailAllBadge({classes, id}) {
  const [badge, setBadge] = useState(null)
  const {data, loading, error} = useQuery(GET_DETAIL_USER_BADGE, {
    variables: {
      id,
    },
  })

  if (loading) {
    return <LoadingComponent />
  }

  if (error) {
    return JSON.stringify(error)
  }

  const res = data?.talent_assignments[0]
  const resTalent = res.talentVersionByTalentVersion

  const handleChangeBage = id => {
    setBadge(badge === id ? null : id)
  }

  const xArr = []
  const yArr = []

  if (resTalent?.flexy_components) {
    resTalent.flexy_components.x.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'x', res, xArr, yArr)
    )
    resTalent.flexy_components.y.parameters.forEach(axis =>
      switchFlexyComponents(axis, 'y', res, xArr, yArr)
    )
  }

  return (
    <div style={{padding: '24px 12px'}}>
      <BoldTypography variant="h5">{resTalent?.name}</BoldTypography>
      <BoldTypography variant="body1" className={classes.desc}>{`${moment(
        resTalent?.start_date
      ).format('LL')} - ${moment(resTalent?.end_date).format(
        'LL'
      )}`}</BoldTypography>
      <Paper className={classes.paper2}>
        <div style={{width: '70%'}}>
          <div className={classes.badge}>
            <img
              src={res.talent_box?.badge}
              alt={res.talent_box?.name}
              style={{width: 116}}
            />
            <div className={classes.titleBadge}>
              <BoldTypography className={classes.badgeName} variant="h5">
                {res.talent_box?.name}
              </BoldTypography>
              <BoldTypography variant="h6" className={classes.score}>
                {res.talent_score?.toFixed(2)}
              </BoldTypography>
            </div>
          </div>
          <BoldTypography variant="body1" className={classes.desc}>
            {res.talent_box?.description}
          </BoldTypography>
        </div>
        <div style={{display: 'flex', width: '30%'}}>
          <NineBoxes
            newHeight={resTalent?.box_height}
            newWidth={resTalent?.box_width}
            data={resTalent?.talent_boxes}
            badge={badge}
            handleChangeBage={handleChangeBage}
            litle={true}
          />
        </div>
      </Paper>

      <div style={{display: 'flex', flexDirection: 'row', marginTop: '2rem'}}>
        <Paper className={classes.padding} style={{marginRight: '1rem'}}>
          <BoldTypography variant="body1" gutterBottom>
            {resTalent?.flexy_components?.x.name}
          </BoldTypography>
          <GreyTypography variant="body2" paragraph>
            Last Update on{' '}
            {res.date_modified
              ? moment(res.date_modified).format('DD/MM/YYYY')
              : '-'}
          </GreyTypography>
          {xArr.length > 0 &&
            xArr.map((axis, i) => (
              <div className={classes.displayRow} key={i}>
                <Typography variant="body1">
                  {axis.param} ({axis.index})
                </Typography>
                <Typography
                  variant="body1"
                  style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                >
                  {axis.value}
                </Typography>
              </div>
            ))}
        </Paper>

        <Paper className={classes.padding}>
          <BoldTypography variant="body1">
            {resTalent?.flexy_components?.y.name}
          </BoldTypography>
          <GreyTypography variant="body2" paragraph>
            Last Update on{' '}
            {res.date_modified
              ? moment(res.date_modified).format('DD/MM/YYYY')
              : '-'}
          </GreyTypography>
          {yArr.length > 0 &&
            yArr.map((axis, i) => (
              <div className={classes.displayRow} key={i}>
                <Typography variant="body1">
                  {axis.param} ({axis.index})
                </Typography>
                <Typography
                  variant="body1"
                  style={{color: axis.value >= 0 ? '#4caf50' : '#ef4d5e'}}
                >
                  {axis.value}
                </Typography>
              </div>
            ))}
        </Paper>
      </div>
    </div>
  )
}
