import React from 'react'
import {makeStyles, Tabs as TabsStyled} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  tabs: {
    background: '#f7f8f9',
    color: '#333333',
    boxShadow: '0px !important',
  },
}))

export default function Tabs(props) {
  const {value, onChange, children} = props
  const classes = useStyles()

  return (
    <TabsStyled
      variant="scrollable"
      scrollButtons="auto"
      value={value}
      onChange={onChange}
      className={classes.tabs}
    >
      {children}
    </TabsStyled>
  )
}
