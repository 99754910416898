import Moment from 'moment'
import {APP_COOKIE, ROLES, SSO_URL} from './globals'
import {parameters_talent_versions} from './constants'

// Currency formatting
const idrFormatterPadded = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
})

const idrFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
})

export function convertToRupiah(nominal, paddedFraction = true) {
  const formatter = paddedFraction ? idrFormatterPadded : idrFormatter

  // The formatter does rounding when truncating fractions,
  // if you have 0.999, it should not mean that you have Rp 1,00
  const fractions = paddedFraction ? 2 : 0
  const formatted = formatter.format(truncateFractional(nominal, fractions))

  // Additionally, there shouldn't be a space between currency sign and nominal
  return formatted.replace(/\s+/g, '')
}

export function truncateFractional(number, digit) {
  const pow = 10 ** digit
  return Math.trunc(number * pow) / pow
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}
export function substract(x, y) {
  const dataX = x
  const dataY = y
  const nilai = dataX - dataY
  return nilai
}
export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export function getDate(date) {
  const initDate = new Date(date)
  return Moment(initDate).format('DD MMMM YYYY')
  // let dd = initDate.getDate()
  // let mm = initDate.getMonth() + 1
  // let yyyy = initDate.getFullYear()
  // if (dd < 10) {
  //   dd = `0${dd}`
  // }

  // if (mm < 10) {
  //   mm = `0${mm}`
  // }

  // const dateFull = `${dd}/${mm}/${yyyy}`
  // return dateFull
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie =
    name + '=' + value + expires + '; path=/; domain=' + APP_COOKIE
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function getWithin(start_date) {
  if (start_date !== '') {
    // var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // var firstDate = new Date(start_date);
    // var secondDate = new Date();
    // var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));

    // return diffDays
    // var date2 = new Date(start_date);
    // var date1 = new Date();
    // var diff = Math.floor(date1.getTime() - date2.getTime());
    // var day = 1000 * 60 * 60 * 24;

    // var days = Math.floor(diff / day);
    // // var weeks = Math.floor(diff / 7);
    // var months = Math.floor(days / 31);
    // var years = Math.floor(months / 12);

    // var message = '';
    // message += " was "
    // message += days + " days "
    // // message += weeks + " weeks "
    // message += months + " months "
    // message += years + " years ago \n"

    // return message
    // Calculate time between two dates:
    const date1 = new Date(start_date) // the date you already commented/ posted
    const date2 = new Date() // today

    let message = ''

    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor((diffInSeconds / 60 / 60) % 24)
    const minutes = Math.floor((diffInSeconds / 60) % 60)

    const months = Math.floor(days / 31)
    const years = Math.floor(months / 12)

    // the below object is just optional
    // if you want to return an object instead of a message

    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in days if no years / months
      if (days > 0) {
        if (days === 1) {
          message = days + ' day'
        } else {
          message = days + ' days'
        }
      } else if (hours > 0) {
        if (hours === 1) {
          message = hours + ' hour'
        } else {
          message = hours + ' hours'
        }
      } else {
        // show in minutes if no years / months / days
        if (minutes === 1) {
          message = minutes + ' minute'
        } else {
          message = minutes + ' minutes'
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = months + ' month'
      } else {
        message = months + ' months'
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = years + ' year'
      } else {
        message = years + ' years'
      }
    }

    return 'Within ' + message
  }
}

export function setRoutes(config) {
  let routes = [...config.routes]

  if (config.settings || config.auth) {
    routes = routes.map(route => {
      let auth = config.auth ? [...config.auth] : []
      auth = route.auth ? [...auth, ...route.auth] : auth
      return {
        ...route,
        settings: {...config.settings, ...route.settings},
        auth,
      }
    })
  }

  return [...routes]
}

export function generateRoutesFromConfigs(configs) {
  let allRoutes = []
  configs.forEach(config => {
    allRoutes = [...allRoutes, ...setRoutes(config)]
  })
  return allRoutes
}
export const capitalize = ([first, ...rest]) =>
  first.toUpperCase() + rest.join('').toLowerCase()

export const MathBoxes = (dataList, dataBoxes) => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let uid = null
  let Xnpf = []
  let Xgoal = []
  let Xmultirater = []
  let Xogf = []
  let Yattedance = []
  let Ycompetency = []
  let dataProfile = []
  const setBoxes = []

  dataList &&
    dataList.people_work_placements.forEach(data => {
      data.talent_assignments.forEach(resx => {
        Xnpf.push(resx.indicator_perf_nps)
        Xgoal.push(resx.indicator_perf_goal)
        Xmultirater.push(resx.indicator_perf_multirater)
        Xogf.push(resx.indicator_perf_ogf)
        Yattedance.push(resx.indicator_pot_attendance)
        Ycompetency.push(resx.indicator_pot_competence)
      })
      dataProfile = data
      uid = data.global_user.id
      const dataIndicator = {
        npf: Xnpf.reduce((a, b) => a + b, 0).toFixed(2),
        ogf: Xogf.reduce((a, b) => a + b, 0).toFixed(2),
        goal: Xgoal.reduce((a, b) => a + b, 0).toFixed(2),
        multirater: Xmultirater.reduce((a, b) => a + b, 0).toFixed(2),
        attendance: Yattedance.reduce((a, b) => a + b, 0).toFixed(2),
        competency: Ycompetency.reduce((a, b) => a + b, 0).toFixed(2),
      }

      dataBoxes &&
        dataBoxes.talent_versions.forEach(res => {
          start = res.start_date
          end = res.end_date

          x =
            (res.indicator_perf_nps
              ? (Xnpf.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_nps_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_ogf
              ? (Xogf.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_ogf_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_goal
              ? (Xgoal.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_goal_fields.weight) /
                100
              : 0) +
            (res.indicator_perf_multirater
              ? (Xmultirater.reduce((a, b) => a + b, 0) *
                  res.indicator_perf_multirater_fields.weight) /
                100
              : 0)
          y =
            (res.indicator_pot_attendance
              ? (Yattedance.reduce((a, b) => a + b, 0) *
                  res.indicator_pot_attendance_fields.weight) /
                100
              : 0) +
            (res.indicator_pot_competence
              ? (Ycompetency.reduce((a, b) => a + b, 0) *
                  res.indicator_pot_competence_fields.weight) /
                100
              : 0)
          const nilai = (x + y) / 2

          if (x >= res.min_perf && y >= res.min_pot) {
            persentaseX = (res.max_perf - res.min_perf) / 2
            persentaseY = (res.max_pot - res.min_pot) / 2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            // const modX = x % persentaseX
            // const modY = y % persentaseY
            // if (modX > 0) {
            //   akhirX = Math.floor(akhirX)
            // } else {
            //   akhirX = Math.floor(akhirX)
            // }
            akhirX = Math.floor(akhirX)

            // if (modY > 0) {
            //   akhirY = Math.floor(akhirY)
            // } else {
            //   akhirY = Math.floor(akhirY)
            // }
            akhirY = Math.floor(akhirY)

            data.talent_assignments.forEach(resx => {
              res.talent_boxes.forEach(resd => {
                if (resx.badge_classification !== null) {
                  if (resx.badge_classification === resd.id) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                      res,
                    })
                  }
                } else {
                  if (akhirX === resd.index_x && akhirY === resd.index_y) {
                    setBoxes.push({
                      resd,
                      nilai: nilai.toFixed(2),
                      start,
                      end,
                      dataProfile,
                      dataIndicator,
                      uid,
                      res,
                    })
                  }
                }
              })
            })
          }
        })
      Xnpf = []
      Xgoal = []
      Xmultirater = []
      Xogf = []
      Yattedance = []
      Ycompetency = []
    })
  return setBoxes
}
export const PersonalBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      data.talent_assignments.forEach(resx => {
        const dataIndicator = {
          npf: parseInt(resx.indicator_perf_nps).toFixed(2),
          ogf: parseInt(resx.indicator_perf_ogf).toFixed(2),
          goal: parseInt(resx.indicator_perf_goal).toFixed(2),
          multirater: parseInt(resx.indicator_perf_multirater).toFixed(2),
          attendance: parseInt(resx.indicator_pot_attendance).toFixed(2),
          competency: parseInt(resx.indicator_pot_competence).toFixed(2),
        }

        x =
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_perf_nps
            ? (resx.indicator_perf_nps *
                resx.talentVersionByTalentVersion.indicator_perf_nps_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_perf_ogf
            ? (resx.indicator_perf_ogf *
                resx.talentVersionByTalentVersion.indicator_perf_ogf_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_perf_goal
            ? (resx.indicator_perf_goal *
                resx.talentVersionByTalentVersion.indicator_perf_goal_fields
                  .weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_perf_multirater
            ? (resx.indicator_perf_multirater *
                resx.talentVersionByTalentVersion
                  .indicator_perf_multirater_fields.weight) /
              100
            : 0)
        y =
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_pot_attendance
            ? (resx.indicator_pot_attendance *
                resx.talentVersionByTalentVersion
                  .indicator_pot_attendance_fields.weight) /
              100
            : 0) +
          (resx.talentVersionByTalentVersion &&
          resx.talentVersionByTalentVersion.indicator_pot_competence
            ? (resx.indicator_pot_competence *
                resx.talentVersionByTalentVersion
                  .indicator_pot_competence_fields.weight) /
              100
            : 0)
        const nilai = (x + y) / 2
        if (resx.talentVersionByTalentVersion) {
          if (
            x >= resx.talentVersionByTalentVersion.min_perf &&
            y >= resx.talentVersionByTalentVersion.min_pot
          ) {
            persentaseX =
              (resx.talentVersionByTalentVersion.max_perf -
                resx.talentVersionByTalentVersion.min_perf) /
              2
            persentaseY =
              (resx.talentVersionByTalentVersion.max_pot -
                resx.talentVersionByTalentVersion.min_pot) /
              2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            // const modX = x % persentaseX
            // const modY = y % persentaseY
            // if (modX > 0) {
            //   akhirX = Math.floor(akhirX)
            // } else {
            //   akhirX = Math.floor(akhirX)
            // }
            akhirX = Math.floor(akhirX)

            // if (modY > 0) {
            //   akhirY = Math.floor(akhirY)
            // } else {
            //   akhirY = Math.floor(akhirY)
            // }
            akhirY = Math.floor(akhirY)

            dataProfile = data
            start = resx.talentVersionByTalentVersion.start_date
            end = resx.talentVersionByTalentVersion.end_date
            resx.talentVersionByTalentVersion.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                    dataP: resx.talentVersionByTalentVersion,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                    dataP: resx.talentVersionByTalentVersion,
                  })
                }
              }
            })
          }
        }
      })
    })

  return setBoxes
}
export const MyTeamBoxes = dataList => {
  let x = 0
  let y = 0
  let persentaseX = 0
  let persentaseY = 0
  let akhirX = 0
  let akhirY = 0
  let start = null
  let end = null
  let dataProfile = []
  const setBoxes = []
  dataList &&
    dataList.people_work_placements.forEach(data => {
      if (data.talent_assignments.length === 0) {
        setBoxes.push({
          resd: null,
          dataProfile: data,
        })
      } else {
        data.talent_assignments.forEach(resx => {
          const dataIndicator = {
            npf:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_perf_nps).toFixed(2)
                : 0,
            ogf:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_perf_ogf).toFixed(2)
                : 0,
            goal:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_perf_goal).toFixed(2)
                : 0,
            multirater:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_perf_multirater).toFixed(2)
                : 0,
            attendance:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_pot_attendance).toFixed(2)
                : 0,
            competency:
              data.talent_assignments.length > 0
                ? parseInt(resx.indicator_pot_competence).toFixed(2)
                : 0,
          }
          const nilai =
            data.talent_assignments.length > 0
              ? ((resx.indicator_perf_nps *
                  resx.talent_version?.indicator_perf_nps_fields?.weight) /
                  100 +
                  (resx.indicator_perf_ogf *
                    resx.talent_version?.indicator_perf_ogf_fields?.weight) /
                    100 +
                  (resx.indicator_perf_goal *
                    resx.talent_version?.indicator_perf_goal_fields?.weight) /
                    100 +
                  (resx.indicator_perf_multirater *
                    resx.talent_version?.indicator_perf_multirater_fields
                      ?.weight) /
                    100 +
                  (resx.indicator_pot_attendance *
                    resx.talent_version?.indicator_pot_attendance_fields
                      ?.weight) /
                    100 +
                  (resx.indicator_pot_competence *
                    resx.talent_version?.indicator_pot_competence_fields
                      ?.weight) /
                    100) /
                2
              : 0

          x =
            (resx.indicator_perf_nps *
              resx.talent_version?.indicator_perf_nps_fields?.weight) /
              100 +
            (resx.indicator_perf_ogf *
              resx.talent_version?.indicator_perf_ogf_fields?.weight) /
              100 +
            (resx.indicator_perf_goal *
              resx.talent_version?.indicator_perf_goal_fields?.weight) /
              100 +
            (resx.indicator_perf_multirater *
              resx.talent_version?.indicator_perf_multirater_fields?.weight) /
              100
          y =
            (resx.indicator_pot_attendance *
              resx.talent_version?.indicator_pot_attendance_fields?.weight) /
              100 +
            (resx.indicator_pot_competence *
              resx.talent_version?.indicator_pot_competence_fields?.weight) /
              100

          if (
            x >= resx.talent_version?.min_perf &&
            y >= resx.talent_version?.min_pot
          ) {
            persentaseX =
              (resx.talent_version.max_perf - resx.talent_version.min_perf) / 2
            persentaseY =
              (resx.talent_version.max_pot - resx.talent_version.min_pot) / 2
            akhirX = x / persentaseX
            akhirY = y / persentaseY
            // const modX = x % persentaseX
            // const modY = y % persentaseY
            // if (modX > 0) {
            //   akhirX = Math.floor(akhirX)
            // } else {
            //   akhirX = Math.floor(akhirX)
            // }
            akhirX = Math.floor(akhirX)

            // if (modY > 0) {
            //   akhirY = Math.floor(akhirY)
            // } else {
            //   akhirY = Math.floor(akhirY)
            // }
            akhirY = Math.floor(akhirY)

            dataProfile = data
            start = resx.talent_version.start_date
            end = resx.talent_version.end_date
            resx.talent_version.talent_boxes.forEach(resd => {
              if (resx.badge_classification !== null) {
                if (resx.badge_classification === resd.id) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              } else {
                if (akhirX === resd.index_x && akhirY === resd.index_y) {
                  setBoxes.push({
                    resd,
                    nilai: nilai.toFixed(2),
                    start,
                    end,
                    dataProfile,
                    dataIndicator,
                  })
                }
              }
            })
          } else {
            setBoxes.push({
              resd: null,
              dataProfile: data,
            })
          }
        })
      }
    })

  return setBoxes
}
export const MathDetailBoxes = (dataBoxes, dataList) => {
  const setBoxes = []
  dataBoxes &&
    dataBoxes.talent_versions.forEach(res => {
      const boxRangeY = (res.max_pot - res.min_pot) / res.box_height
      const boxRangeX = (res.max_perf - res.min_perf) / res.box_width
      res.talent_boxes.forEach(resd => {
        const minBoxY = res.min_pot + resd.index_y * boxRangeY
        const minBoxX = res.min_perf + resd.index_x * boxRangeX
        const nps = res.indicator_perf_nps
          ? (res.indicator_perf_nps_fields.weight * minBoxX) / 100
          : 0
        const ogf = res.indicator_perf_ogf
          ? (res.indicator_perf_ogf_fields.weight * minBoxX) / 100
          : 0
        const goal = res.indicator_perf_goal
          ? (res.indicator_perf_goal_fields.weight * minBoxX) / 100
          : 0
        const multirater = res.indicator_perf_multirater
          ? (res.indicator_perf_multirater_fields.weight * minBoxX) / 100
          : 0
        const competency = res.indicator_pot_competence
          ? (res.indicator_pot_competence_fields.weight * minBoxY) / 100
          : 0
        const attendance = res.indicator_pot_attendance
          ? (res.indicator_pot_attendance_fields.weight * minBoxY) / 100
          : 0
        const data = {
          nps: nps.toFixed(2),
          ogf: ogf.toFixed(2),
          goal: goal.toFixed(2),
          multirater: multirater.toFixed(2),
          competency: competency.toFixed(2),
          attendance: attendance.toFixed(2),
          resd,
          res,
          idList: dataList
            ? dataList && dataList.people_work_placements.length === 0
              ? 0
              : dataList.people_work_placements[0].talent_assignments[0].id
            : null,

          current: dataList
            ? dataList && dataList.people_work_placements.length === 0
              ? 0
              : dataList.people_work_placements[0].talent_assignments[0]
                  .badge_classification
            : null,
        }
        setBoxes.push(data)
      })
    })

  return setBoxes
}

export const isHolding = ROLES.includes('holding-administrator')
export const tempDataHolding = [
  {
    avatar: 'https://avatarfiles.alphacoders.com/115/thumb-115925.jpg',
    name: 'Marvelous',
    legal: 'PT. Marvelous Indonesia',
    motto: 'The Next Leader',
    position: 'CEO at Marvelous',
    description: 'This is description',
  },
  {
    avatar: 'https://avatarfiles.alphacoders.com/115/thumb-115925.jpg',
    name: 'Marvelous',
    legal: 'PT. Marvelous Indonesia',
    motto: 'The Next Leader',
    position: 'CEO at Marvelous',
    description: 'This is description',
  },
  {
    avatar: 'https://avatarfiles.alphacoders.com/115/thumb-115925.jpg',
    name: 'Marvelous',
    legal: 'PT. Marvelous Indonesia',
    motto: 'The Next Leader',
    position: 'CEO at Marvelous',
    description: 'This is description',
  },
  {
    avatar: 'https://avatarfiles.alphacoders.com/115/thumb-115925.jpg',
    name: 'Marvelous',
    legal: 'PT. Marvelous Indonesia',
    motto: 'The Next Leader',
    position: 'CEO at Marvelous',
    description: 'This is description',
  },
  {
    avatar: 'https://avatarfiles.alphacoders.com/115/thumb-115925.jpg',
    name: 'Marvelous',
    legal: 'PT. Marvelous Indonesia',
    motto: 'The Next Leader',
    position: 'CEO at Marvelous',
    description: 'This is description',
  },
]

export const selectProps = val => {
  return {
    displayEmpty: true,
    style: {
      color: !val ? '#808080' : 'unset',
    },
  }
}

export const switchFlexyComponents = (data, axis, detail, xArr, yArr) => {
  let indicatorState
  let index
  switch (data.parameter) {
    case 'competency':
      indicatorState = 'indicator_pot_competence'
      index = 'Indeks'
      break
    case 'attendance':
      indicatorState = 'indicator_pot_attendance'
      index = 'Kehadiran'
      break
    case 'course':
      indicatorState = 'indicator_pot_total_course'
      index = 'Courses'
      break
    case 'socmed_post':
      indicatorState = 'indicator_pot_socmed_post'
      index = 'Posts'
      break
    case 'task':
      indicatorState = 'indicator_perf_goal'
      index = 'Indeks'
      break
    case 'ogf':
      indicatorState = 'indicator_perf_ogf'
      index = 'Total'
      break
    case 'multirater':
      indicatorState = 'indicator_perf_multirater'
      index = 'Total'
      break
    case 'nps':
      indicatorState = 'indicator_perf_nps'
      index = 'Total'
      break
  }

  const newAxisValue = {
    param: parameters_talent_versions[data.parameter].ID,
    value: detail[indicatorState]?.toFixed(2),
    index,
  }

  if (axis === 'x') {
    xArr.push(newAxisValue)
  } else {
    yArr.push(newAxisValue)
  }
}

export const hasModule = (data, moduleName) =>
  data?.company_module_settings?.length === 0 ||
  data?.company_module_settings?.some(o => o.global_module.name === moduleName)

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

export const isValidUrl = urlString => {
  var urlPattern = new RegExp(
    '^(https?:\\/\\/)?' + // validate protocol 
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // validate fragment locator
  return !!urlPattern.test(urlString)
}
