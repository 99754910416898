import {gql} from 'apollo-boost'

export const GET_WORKING_INFORMATION = gql`
  query getWorkingInformation($id: uuid!) {
    working: people_work_placements(
      where: {
        _and: [
          {user: {_eq: $id}}
          {deletedAt: {_is_null: true}}
          {status: {_in: ["ACTIVE", "INACTIVE", "REGISTERED"]}}
        ]
      }
      order_by: [{id: desc}]
      limit: 1
    ) {
      id
      regno
      email
      type
      type_time
      type_place
      start_date
      decree: reference
      end_date
      join_date
      pre_retirement_date
      permanent_date
      decree: reference
      facilities {
        id
        name
      }
      attachment
      global_user {
        id
        date_added
      }
      company_logo: company_profile {
        id
        logo
      }
      company_job_profile {
        id
        title
        roles
        company_organization {
          id
          name
          cost_center
        }
        company_employee_rank {
          id
          name
          job_grade
        }
        company_address {
          id
          office_name
        }
        supervisor: job_profile_supervisor {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {global_user: {deletedAt: {_is_null: true}}}
                {status: {_eq: "ACTIVE"}}
                {deletedAt: {_is_null: true}}
              ]
            }
            limit: 1
            order_by: {global_user: {name: asc}}
          ) {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }

        subordinate: job_profile_children {
          id
          title
          people_work_placements(
            where: {
              _and: [
                {
                  company_job_profile: {
                    job_profile_supervisor: {
                      people_work_placements: {
                        _and: [
                          {user: {_eq: $id}}
                          {status: {_eq: "ACTIVE"}}
                          {deletedAt: {_is_null: true}}
                        ]
                      }
                    }
                  }
                }
                {status: {_eq: "ACTIVE"}}
                {deletedAt: {_is_null: true}}
              ]
            }
          ) {
            id
            global_user {
              id
              name
              avatar
            }
          }
        }
      }
    }
  }
`

export const GET_LIST_EXPERIENCE = gql`
  query getListExperience($id: uuid!, $limit: Int, $offset: Int) {
    total: people_profile_experiences_aggregate(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      aggregate {
        count
      }
    }
    people_profile_experiences(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
      order_by: [{from: desc_nulls_last}, {to: desc_nulls_first}]
      limit: $limit
      offset: $offset
    ) {
      id
      title
      company_name
      company_profile {
        id
        brand_name
        legal_name
        logo
      }
      location
      from
      to
      decree
      description
      achievements
      is_present
      company_name
      referal_name
      referal_position
      reason_to_leave
      company_line_of_business
      additional_fields
      is_masterpiece
    }
  }
`
export const GET_LIST_EDUCATION = gql`
  query getListEducation($id: uuid!, $limit: Int, $offset: Int) {
    total: people_profile_educations_aggregate(
      where: {
        _and: [
          {user: {_eq: $id}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {
                company_profile: {
                  _and: [
                    {types: {_ilike: "_1%"}}
                    {deletedAt: {_is_null: true}}
                  ]
                }
              }
              {other_school: {_is_null: false}}
            ]
          }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    people_profile_educations(
      where: {
        _and: [
          {user: {_eq: $id}}
          {deletedAt: {_is_null: true}}
          {
            _or: [
              {
                company_profile: {
                  _and: [
                    {types: {_ilike: "_1%"}}
                    {deletedAt: {_is_null: true}}
                  ]
                }
              }
              {other_school: {_is_null: false}}
            ]
          }
        ]
      }
      limit: $limit
      offset: $offset
      order_by: [{from: desc_nulls_last}, {to: desc_nulls_first}]
    ) {
      id
      company_profile {
        id
        logo
        school_name: legal_name
      }
      faculty: global_faculty {
        id
        name
      }
      degree: global_degree {
        id
        name
      }
      major: global_major {
        id
        name
      }
      other_school
      start_date: from
      end_date: to
      description
      is_present
      education_fields
    }
  }
`

export const GET_LIST_TRAINING_LISENCE = gql`
  query getListExperience($id: uuid!, $limit: Int, $offset: Int) {
    total: people_profile_certificates_aggregate(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      aggregate {
        count
      }
    }

    people_profile_certificates(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
      order_by: [
        {date_issued: desc_nulls_last}
        {date_expired: desc_nulls_first}
      ]
      limit: $limit
      offset: $offset
    ) {
      id
      name
      type
      level
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      issuer_name
      issues_date: date_issued
      expirations_date: date_expired
      can_expire
      credential_id
      credential_url
      description
      attachments
    }
  }
`
export const GET_LIST_AWARDS = gql`
  query getListAwards($id: uuid!, $limit: Int, $offset: Int) {
    total: people_profile_awards_aggregate(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      aggregate {
        count
      }
    }

    people_profile_awards(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
      limit: $limit
      offset: $offset
      order_by: [{reference_date: desc}]
    ) {
      id
      award_name
      company_profile {
        id
        legal_name
        brand_name
        logo
      }
      reference_date
      reference_number
      letter_number
      remark
      certificate_number
      attachments
      issuing_organization
    }
  }
`

export const GET_LIST_DISCIPLINARIES = gql`
  query getListDisciplinaries($id: uuid!, $limit: Int) {
    total: people_profile_disciplinary_aggregate(
      where: {user: {_eq: $id}, deletedAt: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }

    people_profile_disciplinary(
      where: {user: {_eq: $id}, deletedAt: {_is_null: true}}
      order_by: [{start_date: desc}, {end_date: desc}, {reference_date: desc}]
      limit: $limit
    ) {
      id
      discipline_name
      reference_number
      start_date
      end_date
      discipline_letter_number
      reference_date
      remark
      attachments
    }
  }
`
export const GET_LIST_SKILLS = gql`
  query($id: uuid!, $limit: Int) {
    total: people_profile_skills_aggregate(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      aggregate {
        count
      }
    }

    people_profile_skills(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
      limit: $limit
      order_by: [{id: desc}]
    ) {
      id
      people_skill_level {
        id
        name
      }
      people_skill {
        id
        name
      }
    }
  }
`
export const GET_LIST_SEE_ALL_SKILLS = gql`
  query getListSeeAllSkills($limit: Int, $offset: Int, $id: uuid!) {
    total: people_profile_skills_aggregate(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
    ) {
      aggregate {
        count
      }
    }

    people_profile_skills(
      where: {_and: [{user: {_eq: $id}}, {deletedAt: {_is_null: true}}]}
      limit: $limit
      offset: $offset
      order_by: [{id: desc}]
    ) {
      id
      people_skill_level {
        id
        name
      }
      people_skill {
        id
        name
        people_profile_skills(
          where: {
            _and: [
              {user: {_neq: $id}}
              {deletedAt: {_is_null: true}}
              {is_share: {_eq: true}}
            ]
          }
          limit: 4
          order_by: {id: desc}
        ) {
          id
          global_user {
            id
            name
            avatar
          }
        }
        people_profile_skills_aggregate(
          where: {
            _and: [
              {user: {_neq: $id}}
              {deletedAt: {_is_null: true}}
              {is_share: {_eq: true}}
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  }
`
export const GET_LIST_GAP_COMPETENCY = gql`
  query($placement: Int, $limit: Int) {
    total: competency_individuals_aggregate(
      where: {placement: {_eq: $placement}}
    ) {
      aggregate {
        count
      }
    }
    competency_individuals(
      where: {placement: {_eq: $placement}}
      limit: $limit
    ) {
      id
      individual_level: level
      competency_version_dictionary {
        id
        competency_version {
          id
          name
        }
        competency_dictionary {
          id
          name
        }
        competency_model_items(
          where: {
            company_job_profile: {
              people_work_placements: {id: {_eq: $placement}}
            }
          }
        ) {
          id
          required_level: level
        }
      }
    }
  }
`
export const GET_LIST_DETAIL_GAP_COMPETENCY = gql`
  query($placement: Int!, $limit: Int, $offset: Int) {
    total: competency_individuals_aggregate(
      where: {placement: {_eq: $placement}}
    ) {
      aggregate {
        count
      }
    }
    competency_individuals(
      where: {placement: {_eq: $placement}}
      limit: $limit
      offset: $offset
    ) {
      id
      individual_level: level
      competency_version_dictionary {
        id
        competency_version {
          id
          name
          date_start
          date_end
          status
        }
        competency_dictionary {
          id
          dictionary_id
          name
          description
          type
          competency_category {
            id
            name
          }
        }
        competency_model_items(
          where: {
            company_job_profile: {
              people_work_placements: {id: {_eq: $placement}}
            }
          }
        ) {
          id
          required_level: level
        }
      }
    }
  }
`
