import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import {makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'

import {USER_WORK} from '../../../graphql/queries'
import {COMPANY_ID, NAME, USER_ID} from '../../../utils/globals'

import Section from '../../shared-component/wrapper/Section'
import Tabs from '../../../components/tab/Tabs'
import Tab from '../../../components/tab/Tab'
import Experience from './experience/Experience'
import TrainingLisence from './training-lisence/TrainingLisence'
import Award from './award/Award'
import Skills from './skills/Skills'
import GapCompetency from './gap-competency/GapCompetency'
import Education from './education/Education'
import Disciplinary from './disciplinary/Disciplinary'
import WorkingInformation from './working-information/WorkingInformation'

const useStyles = makeStyles(() => ({
  tabsChildren: {
    padding: 30,
  },
}))

const SeeAllProfessionalInformation = props => {
  const classes = useStyles()
  const {userId, userName} = props.location?.state || {}

  const [value, setValue] = useState(props.location.activeTab || 0)

  const {data} = useQuery(USER_WORK, {
    variables: {
      user: userId || USER_ID,
      company: COMPANY_ID,
    },
  })

  const handleChangeTab = (e, value) => {
    e.preventDefault()
    setValue(value)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <Section
      title={`Informasi profesional ${userName || NAME}`}
      onBack={() => props.history.goBack()}
      childrenStyle={{padding: 0}}
    >
      <Tabs value={value} onChange={handleChangeTab}>
        <Tab label="Status Perkerjaan" />
        <Tab label="Pengalaman" />
        <Tab label="Pendidikan" />
        <Tab label="Pelatihan, Lisensi & Sertifikasi" />
        <Tab label="Penghargaan" />
        <Tab label="Kedisiplinan" />
        <Tab label="Keahlian" />
        <Tab label="Kompetensi Kesenjangan" />
      </Tabs>

      <div className={classes.tabsChildren}>
        {value === 0 && <WorkingInformation userId={userId} />}
        {value === 1 && <Experience userId={userId} />}
        {value === 2 && <Education userId={userId} />}
        {value === 3 && <TrainingLisence userId={userId} />}
        {value === 4 && <Award userId={userId} userName={userName} />}
        {value === 5 && <Disciplinary userId={userId} userName={userName} />}
        {value === 6 && <Skills userId={userId} />}
        {value === 7 && (
          <GapCompetency userWorkId={data?.people_work_placements?.[0]?.id} />
        )}
      </div>
    </Section>
  )
}

export default withRouter(SeeAllProfessionalInformation)
