import React from 'react'
import moment from 'moment'
import {makeStyles, CircularProgress, Avatar, Divider} from '@material-ui/core'
import {CustomTypography} from '../../../components/typography/TypographyStyles'
import {withRouter} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {GET_COMMITTEE_HOLDING_TASK} from '../../../graphql/queries'
import {USER_ID} from '../../../utils/globals'
import {Flex} from '../../../GlobalStyles'

const useStyles = makeStyles(() => ({
  container: {display: 'flex', flexWrap: 'wrap'},
  companyWrapper: {
    width: '25%',
  },
  innerCompanyWrapper: {
    border: '1px solid #EFF2F4',
    borderRadius: 5,
    margin: '0 10px 20px',
    padding: 14,
    minHeight: '20vh',
  },
  flexHeader: {display: 'flex', alignItems: 'center', cursor: 'pointer'},
  divider: {margin: '12px 0'},
  overHidden: {overflow: 'hidden'},
}))

function OtherCompany(props) {
  const classes = useStyles()
  const {data, error} = useQuery(GET_COMMITTEE_HOLDING_TASK, {
    variables: {id: USER_ID},
  })

  const LoadingComponent = () => {
    return (
      <Flex align="center">
        <CircularProgress style={{margin: '30px auto'}} />
      </Flex>
    )
  }

  const EmptyState = () => {
    return (
      <Flex column style={{margin: '30px auto'}} align="center">
        <img
          src={require('../../../assets/images/empty_state.svg')}
          alt="empty-state"
        />
        <CustomTypography bold top="20px">
          Maaf, Tidak ada daftar
        </CustomTypography>
      </Flex>
    )
  }

  if (data === undefined && error === undefined) {
    return <LoadingComponent />
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  return (
    <div className={classes.container}>
      {data.holding_nomination_config.length > 0 ? (
        data.holding_nomination_config.map((res, i) => {
          return (
            <div className={classes.companyWrapper} key={i}>
              <div className={classes.innerCompanyWrapper}>
                <div
                  className={classes.flexHeader}
                  onClick={() => {
                    props.history.push({
                      pathname: `/committee-task/other-company/${res.id}`,
                      state: {active: 'committee-task', data: res},
                    })
                  }}
                >
                  <Avatar
                    variant="rounded"
                    src={
                      res && res.company_profile ? res.company_profile.logo : ''
                    }
                    alt={
                      res && res.company_profile
                        ? res.company_profile.legal_name
                        : ''
                    }
                    style={{marginRight: 11}}
                  />
                  <div className={classes.overHidden}>
                    <CustomTypography size="13px" noWrap>
                      {res && res.company_profile
                        ? res.company_profile.brand_name
                        : 'No Brand Name'}
                    </CustomTypography>
                    <CustomTypography size="13px" grey noWrap>
                      {res && res.company_profile
                        ? res.company_profile.legal_name
                        : 'No Legal Name'}
                    </CustomTypography>
                  </div>
                </div>
                <CustomTypography blue bold top="20px" bottom="5px">
                  {res && res.description ? res.description : '-'}
                </CustomTypography>
                <Divider className={classes.divider} />
                <CustomTypography lightBlue size="13px" bottom="5px">
                  {res.status}
                </CustomTypography>
                {res.company_profile.talent_versions.length > 0 && (
                  <CustomTypography grey size="13px">
                    {res.company_profile.talent_versions.start_date} - 29 Mar
                    2021
                    {moment(
                      res.company_profile.talent_versions.start_date
                    ).format('D MMM YYYY')}{' '}
                    -{' '}
                    {moment(
                      res.company_profile.talent_versions.start_date
                    ).format('D MMM YYYY')}
                  </CustomTypography>
                )}
              </div>
            </div>
          )
        })
      ) : (
        <EmptyState />
      )}
    </div>
  )
}

export default withRouter(OtherCompany)
