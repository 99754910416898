import gql from 'graphql-tag'

export const ADD_MY_TEAM_PLAN = gql`
  mutation(
    $name: String
    $measure: String
    $recommended_action: String
    $startdate: date
    $enddate: date
    $userwork: Int
    $competency: [talent_development_competencies_insert_input!]!
    $status: String
  ) {
    insert_talent_development_plans(
      objects: {
        name: $name
        measure_of_success: $measure
        recommended_action: $recommended_action
        start_date: $startdate
        end_date: $enddate
        user_work_id: $userwork
        talent_development_competencies: {data: $competency}
        status: $status
      }
    ) {
      affected_rows
    }
  }
`

export const EDIT_MY_TEAM_PLAN = gql`
  mutation(
    $id: Int!
    $name: String
    $measure_of_success: String
    $recommended_action: String
    $start_date: date
    $end_date: date
    $competency: [talent_development_competencies_insert_input!]!
    $status: String
  ) {
    q1: update_talent_development_plans(
      _set: {
        name: $name
        measure_of_success: $measure_of_success
        recommended_action: $recommended_action
        start_date: $start_date
        end_date: $end_date
        status: $status
      }
      where: {id: {_eq: $id}}
    ) {
      affected_rows
    }

    q2: delete_talent_development_competencies(where: {plan: {_eq: $id}}) {
      affected_rows
    }

    q3: insert_talent_development_competencies(objects: $competency) {
      affected_rows
    }
  }
`
