import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {
  Card,
  Divider,
  Grid,
  CircularProgress,
  Snackbar,
  IconButton,
  Typography,
} from '@material-ui/core'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import CloseIcon from '@material-ui/icons/Close'
import {makeStyles} from '@material-ui/core/styles'
import {GET_TALENT_TASK} from '../../graphql/queries/comitteeTask/talentList.query'
import {useQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../utils/globals'
import OtherCompany from './holding/OtherCompanyComponent'
import {isHolding} from '../../utils/helpers'
import {CustomTypography} from '../../components/typography/TypographyStyles'
import {Flex} from '../../GlobalStyles'

import Section from '../shared-component/wrapper/Section'
import Tabs from '../../components/tab/Tabs'
import Tab from '../../components/tab/Tab'

const useStyles = makeStyles(() => ({
  card: {
    border: 'solid 1px #e9e9e9',
    padding: '10px',
    cursor: 'pointer',
  },
  emptyWrapper: {
    margin: '30px auto',
  },
  wrapperTab: {
    padding: '22px 30px',
  },
}))

const CommitteeTask = props => {
  const classes = useStyles()
  const [taskList, setTaskList] = useState([])
  const [open, setOpen] = useState(false)

  const [tabsValue, setTabsValue] = useState(0)
  const handleChangeTabs = (event, newValue) => {
    event.preventDefault()
    setTabsValue(newValue)
  }
  const condition = {
    user: USER_ID,
  }
  const {data, loading} = useQuery(GET_TALENT_TASK, {
    variables: condition,
  })

  const renderStatus = {
    INEVALUATION: 'Sedang di evaluasi',
  }

  const handleClose = (event, reason) => {
    event.preventDefault()
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleDetail = val => {
    const data = val.talent_candidate_group
    const dateNow = moment(new Date()).format('YYYY-MM-DD')
    const isInRange = moment(dateNow).isBetween(
      data.committee_date_start,
      data.committee_date_end,
      null,
      '[]'
    )
    if (isInRange) {
      props.history.push({
        pathname: `/committee-task/${data.talent_version.id}`,
        state: {
          active: 'committee-task',
          data: data,
          status: data.talent_version.status,
        },
      })
    } else {
      setOpen(true)
    }
  }

  const EmptyState = () => {
    return (
      <Flex column className={classes.emptyWrapper} align="center">
        <img
          src={require('../../assets/images/empty_state.svg')}
          alt="empty-state"
        />
        <CustomTypography bold top="20px">
          Maaf, Tidak ada daftar
        </CustomTypography>
      </Flex>
    )
  }

  const daysRemaining = endDate => {
    const dateNow = moment().format('YYYY-MM-DD')
    const daysLeft = moment(endDate).diff(dateNow, 'days')

    let _color
    if (daysLeft <= 3) {
      _color = '#ef4d5e'
    } else if (daysLeft >= 4 && daysLeft <= 7) {
      _color = '#ffa000'
    } else if (daysLeft > 7) {
      _color = '#039be5'
    } else {
      _color = '#333333'
    }

    return (
      <Typography style={{fontSize: '13px', color: _color}}>
        ({daysLeft} hari lagi)
      </Typography>
    )
  }

  const MyCompanyComponent = () => (
    <>
      {/* <FilterWrapper /> */}
      <Grid container spacing={2}>
        {!loading ? (
          !loading && taskList.length > 0 ? (
            taskList.map(val => {
              const data = val.talent_candidate_group
              return (
                <Grid item sm={3} xs={6} key={val}>
                  <Card
                    elevation={1}
                    onClick={() => handleDetail(val)}
                    className={classes.card}
                  >
                    <CustomTypography blue bold bottom="5px">
                      {data.talent_version.name &&
                      data.talent_version.name.length > 45
                        ? `${data.talent_version.name.slice(0, 45)} ...`
                        : data.talent_version.name}
                    </CustomTypography>
                    <CustomTypography lightBlue bottom="15px" size="13px">
                      {data.company_job_profile?.title}
                    </CustomTypography>
                    <Divider />
                    <CustomTypography
                      lightBlue
                      size="13px"
                      top="15px"
                      bottom="5px"
                    >
                      {renderStatus[data?.talent_version?.status] ||
                        data?.talent_version?.status}
                    </CustomTypography>
                    <CustomTypography grey size="13px">
                      {moment(data.committee_date_start).format('D MMM YYYY')} -{' '}
                      {moment(data.committee_date_end).format('D MMM YYYY')}{' '}
                    </CustomTypography>
                    {daysRemaining(data.committee_date_end)}
                  </Card>
                </Grid>
              )
            })
          ) : (
            <EmptyState />
          )
        ) : (
          <CircularProgress className={classes.emptyWrapper} />
        )}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Talent Version is Out of Range"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  )

  useEffect(() => {
    if (data) {
      setTaskList(data.talent_committees)
    }
  }, [data])
  return (
    <div>
      <Section
        title="Versi Talenta"
        icon={AssignmentIndIcon}
        childrenStyle={{padding: 0}}
      >
        <Tabs value={tabsValue} onChange={handleChangeTabs}>
          <Tab label="Perusahaan saya" />
          {isHolding && <Tab label="Perusahaan lain" />}
        </Tabs>

        <div className={classes.wrapperTab}>
          {tabsValue === 0 ? MyCompanyComponent() : <OtherCompany />}
        </div>
      </Section>
    </div>
  )
}

export default CommitteeTask
