import gql from 'graphql-tag'

export const ADD_TO_NOMINATION_HOLDING = gql`
  mutation addHoldingNominationPeople(
    $object: [holding_nomination_people_insert_input!]!
  ) {
    insert_holding_nomination_people(objects: $object) {
      affected_rows
    }
  }
`
