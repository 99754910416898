import gql from 'graphql-tag'

export const GET_COMMITTEE_HOLDING_TASK = gql`
  query getCommiteHoldingTaskList($id: uuid) {
    holding_nomination_config(
      where: {
        holding_nomination_commites: {people_work_placement: {user: {_eq: $id}}}
        status: {_neq: "INACTIVE"}
      }
    ) {
      id
      name
      description
      status
      company_job_profile {
        id
        title
      }
      company_profile {
        id
        legal_name
        brand_name
        logo
        talent_versions {
          id
          status
          start_date
          end_date
        }
      }
      companyProfileByHeadCompany {
        id
        legal_name
        brand_name
        logo
      }
    }
  }
`
