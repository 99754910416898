import gql from 'graphql-tag'

export const TALENT_LIST_HOME = gql`
  query($user: uuid, $version: uuid) {
    people_work_placements(
      where: {user: {_eq: $user}, status: {_eq: "ACTIVE"}}
    ) {
      regno
      start_date
      end_date
      global_user {
        id
        name
        email
        avatar
      }
      placement_fields
      talent_development_plans {
        talent_development_competencies {
          id
          competency_dictionary {
            id
            name
          }
        }
        id
        name
        measure_of_success
        start_date
        end_date
        recommended_action
        status
      }
      current_position: company_job_profile {
        company
        id
        job_icon
        job_summary
        job_description
        experience_level
        company_organization {
          id
          name
        }
        company_employee_position {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
        }
      }
      talent_aspirations {
        id
        planned_date
        company_job_profile {
          id
          title
          job_icon
        }
      }
      talent_assignments(
        where: {
          talentVersionByTalentVersion: {
            status: {_in: ["INEVALUATION", "COMPLETED"]}
            visibility: {_eq: "PUBLISH"}
            id: {_eq: $version}
            classification_type: {_eq: "TALENT"}
          }
        }
        offset: 0
        limit: 1
        order_by: [{id: desc}]
      ) {
        id
        talent_score
        indicator_pot_competence
        indicator_pot_attendance
        indicator_pot_total_course
        indicator_pot_socmed_post
        indicator_perf_goal
        indicator_perf_ogf
        indicator_perf_multirater
        indicator_perf_nps
        talentVersionByTalentVersion {
          id
          name
          start_date
          end_date
          flexy_components
          status
        }
        talent_box {
          id
          name
          badge
          color_hex
          level
        }
      }

      past_badge: talent_assignments(
        where: {
          talentVersionByTalentVersion: {
            status: {_eq: "COMPLETED"}
            visibility: {_eq: "PUBLISH"}
            id: {_neq: $version}
            classification_type: {_eq: "TALENT"}
          }
        }
        offset: 1
      ) {
        id
        talent_score
        talent_box {
          id
          name
          badge
          color_hex
          level
        }
        talentVersionByTalentVersion {
          id
          name
          start_date
          end_date
        }
      }
    }
  }
`
export const TALENT_BADGE_SIDEBAR = gql`
  query($id: uuid) {
    talent_assignments(
      where: {
        user: {_eq: $id}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          visibility: {_eq: "PUBLISH"}
          classification_type: {_eq: "TALENT"}
        }
      }
      limit: 3
      order_by: [{id: desc}]
    ) {
      id
      talent_score
      date_modified
      talent_box {
        id
        badge
        color_hex
        name
        description
        level
      }
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
      }
      indicator_pot_competence
      indicator_pot_attendance
      indicator_pot_total_course
      indicator_pot_socmed_post
      indicator_perf_goal
      indicator_perf_ogf
      indicator_perf_multirater
      indicator_perf_nps
      people_work_placement {
        id
        placement_fields
      }
    }
  }
`
export const GET_ALL_USER_BADGE = gql`
  query($user: uuid, $offset: Int, $limit: Int) {
    talent_assignments(
      where: {
        user: {_eq: $user}
        talentVersionByTalentVersion: {
          status: {_eq: "COMPLETED"}
          visibility: {_eq: "PUBLISH"}
          classification_type: {_eq: "TALENT"}
        }
      }
      order_by: [{id: asc}]
      offset: $offset
      limit: $limit
    ) {
      id
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
      }
      talent_box {
        id
        name
        badge
        color_hex
      }
      talent_score
    }
  }
`
export const GET_DETAIL_USER_BADGE = gql`
  query($id: Int) {
    talent_assignments(where: {id: {_eq: $id}}) {
      id
      talent_score
      date_modified
      talent_box {
        id
        badge
        color_hex
        name
        description
        level
      }
      talentVersionByTalentVersion {
        id
        name
        start_date
        end_date
        flexy_components
        box_height
        box_width
        talent_boxes(order_by: [{index_y: desc}]) {
          id
          badge
          color_hex
          name
          description
          level
          index_x
          index_y
        }
      }
      indicator_pot_competence
      indicator_pot_attendance
      indicator_pot_total_course
      indicator_pot_socmed_post
      indicator_perf_goal
      indicator_perf_ogf
      indicator_perf_multirater
      indicator_perf_nps
    }
  }
`
