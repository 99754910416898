import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {GET_LIST_SKILLS} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'

import LoadingItem from '../../../../components/loading/LoadingItem'
import SkilsItem from './SkilsItem'

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 24,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 20,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
  center: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const LIMIT = 4

export default function Skils({userId, to}) {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_SKILLS, {
    fetchPolicy: 'network-only',
    context: {headers: {'X-Hasura-Role': 'user'}},
    variables: {
      id: userId || USER_ID,
      limit: LIMIT,
    },
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.root}>
      <Typography color="primary" className={classes.title}>
        Keahlian
      </Typography>
      <div className={classes.wrapper}>
        {loading && (
          <LoadingItem width={146} height={66} count={4} direction="row" />
        )}

        {!loading && totalData === 0 && (
          <div className={classes.center}>
            <EmptyState
              message1="Maaf, tidak ada daftar"
              message2="Belum ada Keahlian"
              width={280}
            />
          </div>
        )}

        {!loading &&
          data?.people_profile_skills.map(skill => {
            return (
              <SkilsItem
                key={skill.id}
                title={skill.people_skill.name}
                level={skill.people_skill_level.name}
              />
            )
          })}
      </div>

      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 6,
          }}
        >
          +{total} Lebih Banyak Keahlian
        </Typography>
      )}
    </div>
  )
}
