import {format as f, formatDistanceStrict} from 'date-fns'
import id from 'date-fns/locale/id'

export const formatDate = (date, format = 'MMM yyyy') =>
  f(new Date(date), format, {locale: id})

export const getJobDuration = options => {
  const {
    startDate,
    endDate,
    isPresent,
    format = 'MMM yyyy',
    withDistance = true,
  } = options
  const distance = formatDistanceStrict(
    new Date(startDate),
    isPresent ? new Date() : new Date(endDate),
    {locale: id}
  )

  const start = formatDate(startDate, format)
  const end = isPresent ? 'Sekarang' : formatDate(endDate, format)

  return `${start} - ${end} ${withDistance ? `(${distance})` : ''}`
}

export const getSubordinate = subordinates => {
  return subordinates
    ?.map(row => {
      return row?.people_work_placements?.map(res => {
        return {
          title: row.title,
          ...res,
        }
      })
    })
    .flat()
}

export const getSupervisor = supervisor => {
  return supervisor?.people_work_placements?.map(res => {
    return {
      title: supervisor?.title,
      ...res,
    }
  })
}

export function accessRightNEWSwitcher(res) {
  const data = []
  const _res = res.split('')
  if (_res[0] === '1') {
    data.push('Organization Administrator, ')
  }
  if (_res[1] === '1') {
    data.push('Organization Department Head, ')
  }
  if (_res[2] === '1') {
    data.push('Organization Finance Admin, ')
  }
  if (_res[3] === '1') {
    data.push('Organization HR_Admin, ')
  }
  if (_res[4] === '1') {
    data.push('Organization HR_Assistant, ')
  }
  if (_res[5] === '1') {
    data.push('Organization Payroll Master, ')
  }
  if (_res[6] === '1') {
    data.push('Organization Staff, ')
  }
  if (_res[7] === '1') {
    data.push('Organization Supervisor, ')
  }
  return data
}
