import gql from 'graphql-tag'

export const SEE_ALL_SUCCESSOR = gql`
  query getSuccessor(
    $id_nomination: Int
    $search: String
    $order: [holding_nomination_people_order_by!]
  ) {
    successor: holding_nomination_people(
      where: {
        nomination_id: {_eq: $id_nomination}
        _or: [
          {holding_source: {people_work_placement: {regno: {_ilike: $search}}}}
          {
            holding_source: {
              people_work_placement: {global_user: {name: {_ilike: $search}}}
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_job_profile: {title: {_ilike: $search}}
              }
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_profile: {legal_name: {_ilike: $search}}
              }
            }
          }
          {
            holding_source: {
              people_work_placement: {
                company_profile: {brand_name: {_ilike: $search}}
              }
            }
          }
        ]
      }
      order_by: $order
    ) {
      id
      holding_source {
        people_work_placement {
          id
          regno
          global_user {
            id
            name
            avatar
          }
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            legal_name
            brand_name
          }
          talent_assignments {
            talent_score
            id
            talent_version
            badge_classification
            status
            indicator_perf_nps
            indicator_perf_ogf
            indicator_perf_goal
            indicator_perf_multirater
            indicator_pot_attendance
            indicator_pot_competence
            indicator_pot_experience
            indicator_pot_socmed_post
            indicator_pot_total_course
            talent_box {
              id
              name
              level
              badge
              color_hex
              index_x
              index_y
              description
            }
            talentVersionByTalentVersion {
              id
              name
              start_date
              end_date
              box_height
              box_width
              indicator_perf_nps
              indicator_perf_ogf
              indicator_perf_goal
              indicator_perf_multirater
              indicator_pot_attendance
              indicator_pot_competence
              indicator_pot_socmed_post
              indicator_pot_total_course
              indicator_perf_nps_fields
              indicator_perf_ogf_fields
              indicator_perf_goal_fields
              indicator_perf_multirater_fields
              indicator_pot_attendance_fields
              indicator_pot_competence_fields
              indicator_pot_socmed_post_fields
              indicator_pot_total_course_fields
              talent_boxes {
                id
                name
                level
                badge
                color_hex
                index_x
                index_y
                description
              }
              min_pot
              min_perf
              max_pot
              max_perf
            }
          }
        }
      }
    }
  }
`
