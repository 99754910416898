import React, {useState} from 'react'
import {
  Dialog,
  DialogContent,
  Typography,
  Icon,
  makeStyles,
} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import moment from 'moment'
import Dompurify from 'dompurify'

import {
  GET_DETAIL_PWP_BY_ID,
  GET_DETAIL_ASPIRATION_BY_ID,
} from '../../../graphql/queries'

import LoadingComponent from '../../../components/loading/LoadingComponent'

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#eff2f4',
    display: 'flex',
    justifyContent: 'space-between',
    height: 40,
    padding: 24,
    alignItems: 'center',
  },
  tittleHeader: {
    fontSize: 22,
    color: '#373e41',
    fontWeight: '600',
  },
  titleJob: {
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#003d51',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 16,
  },
  root: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: '0px 10px',
  },
  root2: {
    marginTop: '2rem',
    padding: '0px 10px',
  },
  description: {
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#a9a8a8',
    fontSize: 16,
    margin: '11px 0px 16px 17px',
    lineHeight: '24px',
  },
  subtitle: {
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#039be5',
    fontWeight: 'bold',
    fontSize: 14,
  },
  date: {
    color: '#a9a8a8',
    fontSize: 16,
  },
}))

/**
 * @param {type} string 'CURRENT' | 'SUGGESTED'
 */
export default function DetailAspiration(props) {
  const classes = useStyles()
  const {open, id, type, onClose} = props

  const [date, setDate] = useState(undefined)

  const query =
    type === 'CURRENT' ? GET_DETAIL_PWP_BY_ID : GET_DETAIL_ASPIRATION_BY_ID

  const {data, loading, error} = useQuery(query, {
    skip: !id,
    variables: {id},
    onCompleted: data => {
      const {start_date, end_date, planned_date} = data?.position?.[0] || {}

      const date = type === 'CURRENT' ? start_date : planned_date

      setDate(() => {
        const start = date ? moment(date).format('DD/MM/YYYY') : null
        const end = end_date ? moment(end_date).format('DD/MM/YYYY') : null

        return [start, end].filter(date => date).join(' - ')
      })
    },
  })

  if (error) {
    console.error(error)
  }

  const {
    title,
    job_description,
    job_summary,
    company_job_profile_competencies,
    company_job_profile_r_educations,
  } = data?.position?.[0]?.company_job_profile || {}

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      scroll="body"
    >
      {loading ? (
        <LoadingComponent />
      ) : (
        <>
          <div id="alert-dialog-title" className={classes.header}>
            <Typography
              id="alert-dialog-title"
              variant="h3"
              className={classes.tittleHeader}
            >
              Detail Jabatan
            </Typography>
            <Icon style={{fontSize: 30, cursor: 'pointer'}} onClick={onClose}>
              close
            </Icon>
          </div>

          <DialogContent>
            <div className={classes.root}>
              <div>
                <Typography variant="subtitle1" className={classes.titleJob}>
                  {title || '-'}
                </Typography>
                <Typography className={classes.date}>
                  Tanggal rencana: {date}
                </Typography>
              </div>
            </div>
            <div className={classes.root2}>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Deskripsi Pekerjaan
              </Typography>
              <Typography
                variant="body2"
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: Dompurify.sanitize(job_description || '-'),
                }}
              />
              <Typography variant="subtitle1" className={classes.subtitle}>
                Kesimpulan Pekerjaan
              </Typography>
              <Typography
                variant="body2"
                className={classes.description}
                dangerouslySetInnerHTML={{
                  __html: Dompurify.sanitize(job_summary || '-'),
                }}
              />
              <Typography variant="subtitle1" className={classes.subtitle}>
                Kompetensi
              </Typography>
              {company_job_profile_competencies?.[0] ? (
                company_job_profile_competencies?.map((res, i) => {
                  return (
                    <Typography
                      variant="body2"
                      className={classes.description}
                      key={i}
                    >
                      {res.competency_dictionary?.name.en || '-'}
                    </Typography>
                  )
                })
              ) : (
                <Typography variant="body2" className={classes.description}>
                  -
                </Typography>
              )}
              <Typography variant="subtitle1" className={classes.subtitle}>
                Edukasi
              </Typography>
              {company_job_profile_r_educations?.[0] ? (
                company_job_profile_r_educations?.map((res, i) => {
                  return (
                    <Typography
                      key={i}
                      variant="body2"
                      className={classes.description}
                    >
                      {res.global_degree?.name} of {res.global_faculty?.name}
                    </Typography>
                  )
                })
              ) : (
                <Typography variant="body2" className={classes.description}>
                  -
                </Typography>
              )}
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
