import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {GET_LIST_EXPERIENCE} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import LoadingItem from '../../../../components/loading/LoadingItem'

import {getJobDuration} from '../../talent-profile-helper'
import ExperienceItem from './ExperienceItem'
import HeaderSection from '../shared-professional-information/HeaderSection'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 36,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
}))

const LIMIT = 2

const Experience = ({userId, to}) => {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_EXPERIENCE, {
    fetchPolicy: 'network-only',
    variables: {id: userId || USER_ID, limit: LIMIT},
    context: {headers: {'X-Hasura-Role': 'user'}},
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.wrapper}>
      <HeaderSection title="Pengalaman" />

      {loading && <LoadingItem />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Pengalaman"
          width={280}
        />
      )}

      {!loading &&
        data?.people_profile_experiences.map((experience, idx) => {
          const {is_present, additional_fields} = experience
          const {country_code, phone} =
            additional_fields?.referal_contact_phone || {}

          const date = getJobDuration({
            startDate: experience.from,
            endDate: experience.to,
            isPresent: is_present,
          })

          return (
            <ExperienceItem
              key={experience.id}
              avatar={experience.company_profile?.logo}
              title={experience.title}
              company={experience.company_name}
              date={date}
              location={experience.location?.experience_location}
              description={experience.description || '-'}
              achievements={experience.achievements}
              reasonForLeaveing={experience.reason_to_leave}
              refName={experience.referal_name}
              refPosition={experience.referal_position}
              refPhone={`${country_code + phone || '-'}`}
              company_line_of_business={experience.company_line_of_business}
              isLast={data?.people_profile_experiences.length === idx + 1}
            />
          )
        })}
      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 1,
          }}
        >
          +{total} Lebih Banyak Pengalaman
        </Typography>
      )}
    </div>
  )
}

export default Experience
