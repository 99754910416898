import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import {Link} from 'react-router-dom'

import {GET_LIST_EDUCATION} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {getJobDuration} from '../../talent-profile-helper'

import LoadingItem from '../../../../components/loading/LoadingItem'
import HeaderSection from '../shared-professional-information/HeaderSection'
import EducationItem from './EducationItem'

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 36,
  },
  more: {
    color: '#039BE5',
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    marginTop: 16,
    textDecoration: 'none',
    display: 'block',
  },
}))

const LIMIT = 2

export default function Education({userId, to}) {
  const classes = useStyles()

  const {data, loading, error} = useQuery(GET_LIST_EDUCATION, {
    context: {
      headers: {'X-Hasura-Role': userId ? 'public' : 'user'},
    },
    fetchPolicy: 'network-only',
    variables: {
      id: userId || USER_ID,
      limit: LIMIT,
    },
  })

  if (error) {
    console.error(error)
  }

  const totalData = data?.total.aggregate.count
  const total = totalData - LIMIT

  return (
    <div className={classes.wrapper}>
      <HeaderSection title="Pendidikan" />

      {loading && <LoadingItem />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Pendidikan"
          width={280}
        />
      )}

      {!loading &&
        data?.people_profile_educations.map((education, idx) => {
          const {city, attachments, province, gpa, ceritificate_id} =
            education.education_fields || {}

          const date = getJobDuration({
            startDate: education.start_date,
            endDate: education.end_date,
            isPresent: education.is_present,
            format: 'yyyy',
            withDistance: false,
          })

          const location = [city?.name, province?.name]
            .filter(d => d)
            .join(', ')

          return (
            <EducationItem
              key={education.id}
              avatar={education?.company_profile?.logo}
              school={
                education?.company_profile?.school_name ||
                education.other_school ||
                '-'
              }
              location={location}
              degree={education.degree?.name || '-'}
              date={date}
              description={education.description}
              certificate={ceritificate_id || '-'}
              gpa={gpa || '-'}
              attachments={attachments}
              isLast={data?.people_profile_educations.length === idx + 1}
            />
          )
        })}

      {total > 0 && (
        <Typography
          component={Link}
          className={classes.more}
          to={{
            ...to,
            pathname:
              to?.pathname || '/profile/see-all-professional-information',
            activeTab: 2,
          }}
        >
          +{total} Lebih Banyak Pendidikan
        </Typography>
      )}
    </div>
  )
}
