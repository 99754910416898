import React from 'react'
import {makeStyles, Tab as TabStyled} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  tab: {
    padding: '12px 20px',
  },
}))

export default function Tab(props) {
  const classes = useStyles()
  return <TabStyled className={classes.tab} {...props} />
}
