import React from 'react'
import {useQuery} from '@apollo/react-hooks'

import {USER_ID, COMPANY_ID} from '../../utils/globals'
import {USER_WORK} from '../../graphql/queries/index'

import DevelopmentPlan from './development-plan/DevelopmentPlan'
import Aspiration from './aspiration/Aspiration'
import ProfessionalInformation from './professional-information/ProfessionalInformation'

export default function TalentProfile() {
  const {data} = useQuery(USER_WORK, {
    variables: {
      user: USER_ID,
      company: COMPANY_ID,
    },
  })

  return (
    <>
      <ProfessionalInformation
        userWorkId={data?.people_work_placements[0]?.id}
      />

      <DevelopmentPlan userWorkId={data?.people_work_placements[0]?.id} />

      <Aspiration userWorkId={data?.people_work_placements[0]?.id} />
    </>
  )
}
