import React from 'react'
import {Chip, Divider, Typography} from '@material-ui/core'
import {AttachmentCard} from '@smartasn/wlb-utils-components'

import {WrapperProfessionalItem} from '../../TalentProfileStyles'

export default function Disciplinaryitem(props) {
  const [isMore, setIsMore] = React.useState(false)

  const {
    disciplineName,
    referenceNumber,
    date,
    givenTo,
    isLast,
    letterNumber,
    referenceDate,
    description,
    attachments,
  } = props

  return (
    <WrapperProfessionalItem>
      <div className="content">
        <Chip
          variant="outlined"
          className="chip"
          label={
            <Typography className="fw-14 fw-400">{disciplineName}</Typography>
          }
        />
        <div className="detail-section">
          <Typography className="mb-8 fs-14 fw-600">
            Nomor Referensi {referenceNumber}
          </Typography>
          <Typography className="mb-18 fs-12 fw-400 color-dimmed">
            {date}
          </Typography>
          <Typography className="mb-8 fs-14 fw-600">
            Diberikan kepada {givenTo}
          </Typography>
          <Typography className="mb-8 fs-12 fw-400 color-primary">
            {letterNumber}
          </Typography>
          <Typography className="mb-8 fs-12 fw-400 color-dimmed">
            {referenceDate}
          </Typography>
          <Typography className="fs-12 mb-16 fw-400">
            {description?.slice(0, (!isMore && 370) || description?.length) ||
              '-'}
          </Typography>
          {isMore && (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'start',
                  paddingTop: '10px',
                }}
              >
                {attachments?.map((file, idx) => (
                  <AttachmentCard
                    key={idx}
                    name={file.name}
                    url={file.url || file.link}
                  />
                ))}
              </div>
            </>
          )}

          <span className="see-more" onClick={() => setIsMore(!isMore)}>
            ...{isMore ? 'Lihat Lebih Sedikit' : 'Lihat Lebih Banyak'}
          </span>
          <Divider className={`divider ${isLast && 'hide'}`} />
        </div>
      </div>
      <div className="divider" />
    </WrapperProfessionalItem>
  )
}
