import React from 'react'
import {FormControlLabel, Checkbox, TextField} from '@material-ui/core'
const FormFilter = ({filterData, handleChange, state}) => {
  let render = null
  switch (filterData.name) {
    case 'badge':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.badge.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'aspiration':
      render =
        filterData &&
        filterData.data.map((res, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  onChange={handleChange(filterData.name)}
                  name={res.name}
                  color="primary"
                  value={res.id}
                  checked={state.aspiration.includes(res.id)}
                />
              }
              label={res.name}
            />
          )
        })
      break
    case 'point':
      render = (
        <div style={{display: 'flex', marginTop: '10px'}}>
          <TextField
            label="Min"
            variant="outlined"
            type="number"
            size="small"
            value={state.scoreFrom}
            onChange={handleChange('scoreFrom')}
          />
          <div style={{padding: '7px'}}>-</div>
          <TextField
            label="Max"
            variant="outlined"
            type="number"
            size="small"
            value={state.scoreTo}
            onChange={handleChange('scoreTo')}
          />
        </div>
      )
    default:
      break
  }
  return render
}
export default FormFilter
