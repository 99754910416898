import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {GET_LIST_DISCIPLINARIES} from '../../../../graphql/queries'
import {NAME, USER_ID} from '../../../../utils/globals'
import {formatDate} from '../../talent-profile-helper'

import LoadingItem from '../../../../components/loading/LoadingItem'
import Disciplinaryitem from '../../professional-information/disciplinary/Disciplinaryitem'

export default function Disciplinary({userId, userName}) {
  const {data, loading, error, fetchMore} = useQuery(GET_LIST_DISCIPLINARIES, {
    fetchPolicy: 'network-only',
    variables: {id: userId || USER_ID, limit: 6},
    context: {headers: {'X-Hasura-Role': 'public'}},
  })

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_profile_disciplinary.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_profile_disciplinary: [
            ...prev.people_profile_disciplinary,
            ...fetchMoreResult.people_profile_disciplinary,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Kedisiplinan"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.people_profile_disciplinary.length}
          next={fetchMoreData}
          hasMore={data?.people_profile_disciplinary.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.people_profile_disciplinary.map((disciplinary, idx) => {
            const start = formatDate(disciplinary.start_date, 'MMM dd, yyyy')
            const end = formatDate(disciplinary.end_date, 'MMM dd, yyyy')
            const refDate = formatDate(
              disciplinary.reference_date,
              'MMM dd, yyyy'
            )

            const date = [start, end].filter(d => d).join(' - ')

            return (
              <Disciplinaryitem
                key={disciplinary.id}
                disciplineName={disciplinary.discipline_name || '-'}
                referenceNumber={disciplinary.reference_number || '-'}
                date={date}
                givenTo={userName || NAME}
                letterNumber={disciplinary.discipline_letter_number || '-'}
                referenceDate={refDate}
                description={disciplinary.remark || '-'}
                attachments={disciplinary.attachments || []}
                isLast={data?.people_profile_disciplinary.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
