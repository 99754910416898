import React from 'react'
import {Typography, makeStyles} from '@material-ui/core'
import {Link} from 'react-router-dom'
import WorkIcon from '@material-ui/icons/Work'

import Section from '../../shared-component/wrapper/Section'

import TrainingLicense from './training-license/TrainingLicense'
import Experience from './experience/Experience'
import Education from './education/Education'
import Skils from './skils/Skils'
import GapCompetency from './gap-competency/GapCompetency'
import Disciplinary from './disciplinary/Disciplinary'
import Award from './award/Award'
import WorkingInformation from './working-information/WorkingInformation'

const useStyles = makeStyles(() => ({
  footer: {
    color: '#039BE5',
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 600,
    display: 'block',
    margin: 'auto',
    textDecoration: 'none',
    fontSize: 16,
  },
}))

export default function ProfessionalInformation(props) {
  const {userId, userWorkId, to} = props

  const classes = useStyles()

  return (
    <Section title="Informasi profesional" icon={WorkIcon}>
      <WorkingInformation userId={userId} />

      <Experience userId={userId} to={to} />

      <Education userId={userId} to={to} />

      <TrainingLicense userId={userId} to={to} />

      <Award userId={userId} to={to} />

      <Disciplinary userId={userId} to={to} />

      <Skils userId={userId} to={to} />

      <GapCompetency userWorkId={userWorkId} userId={userId} to={to} />

      <Typography
        className={classes.footer}
        component={Link}
        to={{
          ...to,
          pathname: to?.pathname || '/profile/see-all-professional-information',
          activeTab: 0,
        }}
      >
        Lihat Semua Informasi Profesional
      </Typography>
    </Section>
  )
}
