import React, {useState, useEffect} from 'react'
import {TypeMenuContainer} from '../../../components/job-portal/JobPortalStyles'
import {Typography, Paper, Divider} from '@material-ui/core'
import Carousel from 'nuka-carousel'
import {TALENT_BADGE_SIDEBAR} from '../../../graphql/queries'
import {useQuery} from '@apollo/react-hooks'
import {USER_ID} from '../../../utils/globals'
import {
  IconArrowDisabled,
  IconArrowBlue,
  GreyTypography,
  BoldTypography,
} from '../../../components/typography/TypographyStyles'
import {getCookie, switchFlexyComponents} from '../../../utils/helpers'
import ModalDetail from './ModalDetail'
import IconSvg from '../../../assets/images/icon_badges_not_found.svg'
import AllBadge from './ModalAll'
import '../../../assets/css/nukaCarousel.css'

const TalentSidebar = () => {
  const [boxes, setBoxes] = useState([])
  const [open, setOpen] = useState({
    open: false,
    data: null,
  })
  const [arrFlexy, setArrFlexy] = useState(null)
  const [openAll, setOpenAll] = useState(false)
  const condition = {id: USER_ID}
  const {data: dataList, loading} = useQuery(TALENT_BADGE_SIDEBAR, {
    variables: condition,
  })
  const data = getCookie('userData') && JSON.parse(getCookie('userData'))

  useEffect(() => {
    if (dataList && dataList.talent_assignments.length > 0) {
      setBoxes(dataList.talent_assignments)
    }
  }, [dataList])
  const handleOpen = (dataX, arr) => {
    setOpen({
      open: true,
      data: dataX,
    })
    setArrFlexy(arr)
  }
  const handleClose = () => {
    setOpen({
      open: false,
      data: null,
    })
    setArrFlexy(null)
  }
  const handleOpenAll = () => {
    setOpenAll(prev => !prev)
  }
  const Items = ({data}) => {
    return (
      <Paper style={{textAlign: 'center', paddingBottom: 16}} elevation={0}>
        <img src={data?.talent_box?.badge} alt="badge" style={{width: '40%'}} />
        <Typography variant="h6">{data?.talent_box?.name}</Typography>
        <BoldTypography variant="h6">
          {data?.talent_score?.toFixed(2)}
        </BoldTypography>
        <GreyTypography variant="body2">
          Versi : {data?.talentVersionByTalentVersion?.name}
          {/* {data?.talentVersionByTalentVersion?.start_date
            ? Moment(data.talentVersionByTalentVersion.start_date).format(
                'YYYY'
              )
            : ' - '}
          /
          {data?.talentVersionByTalentVersion?.end_date
            ? Moment(data.talentVersionByTalentVersion.end_date).format('YYYY')
            : ' - '} */}
        </GreyTypography>
      </Paper>
    )
  }
  const ItemsNull = () => {
    return (
      <Paper
        style={{
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        elevation={0}
      >
        <div>
          <img src={IconSvg} alt="badge" style={{width: '10vw'}} />
          <Typography variant="h6">Tidak ada lencana</Typography>
        </div>
      </Paper>
    )
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          border: '0.5px solid #eff2f4',
        }}
      />
      <Divider />
      {boxes.length > 0 && !loading && (
        <TypeMenuContainer>
          <Typography
            variant="body1"
            style={{
              marginBottom: 10,
              color: '#039be5',
              textAlign: 'right',
              cursor: 'pointer',
            }}
            onClick={handleOpenAll}
          >
            Semua Lencana
          </Typography>
        </TypeMenuContainer>
      )}

      {boxes.length === 0 && !loading ? (
        <ItemsNull />
      ) : (
        <Carousel
          style={{outline: 'none'}}
          swiping
          slideIndex={boxes.length - 1}
          renderCenterRightControls={({
            currentSlide,
            slideCount,
            nextSlide,
          }) => {
            return slideCount - currentSlide === 1 ? (
              <IconArrowDisabled
                style={{
                  position: 'absolute',
                  bottom: '12rem',
                  right: 0,
                  marginRight: '0.5rem',
                  display: boxes.length === 0 ? 'none' : 'block',
                }}
              >
                chevron_right
              </IconArrowDisabled>
            ) : (
              <IconArrowBlue
                onClick={nextSlide}
                style={{
                  position: 'absolute',
                  bottom: '12rem',
                  right: 0,
                  marginRight: '0.5rem',
                  display: boxes.length === 0 ? 'none' : 'block',
                }}
              >
                chevron_right
              </IconArrowBlue>
            )
          }}
          renderTopRightControls={null}
          renderCenterLeftControls={({previousSlide, currentSlide}) => {
            return currentSlide === 0 ? (
              <IconArrowDisabled
                style={{
                  position: 'absolute',
                  bottom: '12rem',
                  marginLeft: '0.5rem',
                  display: boxes.length === 0 ? 'none' : 'block',
                }}
              >
                chevron_left
              </IconArrowDisabled>
            ) : (
              <IconArrowBlue
                style={{
                  position: 'absolute',
                  bottom: '12rem',
                  marginLeft: '0.5rem',
                  display: boxes.length === 0 ? 'none' : 'block',
                }}
                onClick={previousSlide}
              >
                chevron_left
              </IconArrowBlue>
            )
          }}
          renderBottomCenterControls={null}
        >
          {boxes.map((res, i) => {
            const xArr = []
            const yArr = []

            if (res.talentVersionByTalentVersion.flexy_components) {
              res.talentVersionByTalentVersion.flexy_components.x.parameters.forEach(
                axis => switchFlexyComponents(axis, 'x', res, xArr, yArr)
              )
              res.talentVersionByTalentVersion.flexy_components.y.parameters.forEach(
                axis => switchFlexyComponents(axis, 'y', res, xArr, yArr)
              )
            }

            return (
              <div key={i}>
                <Items data={res} />
                <Divider />

                <div style={{padding: 24}}>
                  <Typography
                    variant="body1"
                    style={{
                      textAlign: 'left',
                      color: '#707070',
                      fontWeight: 700,
                      textTransform: 'capitalize',
                    }}
                    gutterBottom
                  >
                    Nilai{' '}
                    {res.talentVersionByTalentVersion?.flexy_components?.x.name}
                  </Typography>
                  {xArr.length > 0 &&
                    xArr.map((x, i) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          textAlign: 'left',
                          paddingLeft: '1rem',
                        }}
                        key={i}
                      >
                        <Typography variant="body1" style={{color: '#707070'}}>
                          {x.param} ({x.index})
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{marginLeft: '1rem'}}
                        >
                          {x.value}
                        </Typography>
                      </div>
                    ))}

                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 700,
                      textAlign: 'left',
                      color: '#707070',
                      marginTop: 24,
                      textTransform: 'capitalize',
                    }}
                    gutterBottom
                  >
                    Nilai{' '}
                    {res.talentVersionByTalentVersion?.flexy_components?.y.name}{' '}
                  </Typography>
                  {yArr.length > 0 &&
                    yArr.map((y, i) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          textAlign: 'left',
                          paddingLeft: '1rem',
                        }}
                        key={i}
                      >
                        <Typography variant="body1" style={{color: '#707070'}}>
                          {y.param} ({y.index})
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{marginLeft: '1rem'}}
                        >
                          {y.value}
                        </Typography>
                      </div>
                    ))}
                </div>
                <Typography
                  onClick={() => handleOpen(res, {xArr, yArr})}
                  variant="body1"
                  style={{color: '#039be5', textAlign: 'center'}}
                >
                  Lihat Detail
                </Typography>
              </div>
            )
          })}
        </Carousel>
      )}

      <ModalDetail
        onClose={handleClose}
        open={open.open}
        data={open.data}
        xArr={arrFlexy?.xArr}
        yArr={arrFlexy?.yArr}
      />
      <AllBadge
        onClose={handleOpenAll}
        open={openAll}
        name={data && data.name}
      />
    </>
  )
}

export default TalentSidebar
