import React, {useState} from 'react'
import Plot from 'react-plotly.js'
import {TextField, makeStyles, Typography, MenuItem} from '@material-ui/core'

import {useQuery} from '@apollo/react-hooks'

import {selectProps} from '../../../../../utils/helpers'
import {
  GET_DROPDOWN_CHART_HOLDING,
  GET_HOLDING_CHART,
} from '../../../../../graphql/queries'

const useStyles = makeStyles(() => ({
  filterContainer: {
    width: 713,
    padding: 20,
    paddingBottom: 10,
    display: 'flex',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    background: '#fff',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  container: {background: '#003d51', margin: '0 -24px'},
  diagramContainer: {
    textAlign: 'center',
  },
  selectWrapper: {width: 211},
}))

export default function ChartComponent({company_head}) {
  const classes = useStyles()

  const [valX, setValX] = useState(0)
  const [valY, setValY] = useState(0)
  const [valZ, setValZ] = useState(0)

  const {data, error} = useQuery(GET_DROPDOWN_CHART_HOLDING, {
    variables: {company_head},

    fetchPolicy: 'cache-and-network',
  })

  const {data: dataChart, error: errorChart} = useQuery(GET_HOLDING_CHART, {
    variables: {
      company_head,
      search_x: valX,
      search_y: valY,
      search_z: valZ,
    },

    fetchPolicy: 'cache-and-network',
  })

  if (data === undefined && error === undefined) {
    return 'Loading..'
  } else if (error !== undefined) {
    return <div>{error}</div>
  }

  if (dataChart === undefined && errorChart === undefined) {
    return 'Loading..'
  } else if (errorChart !== undefined) {
    return <div>{errorChart}</div>
  }

  const noDataScatterChart = [
    {
      x: [5],
      y: [5],
      z: [5],
      type: 'scatter3d',
      mode: 'markers+text',
      text: ['No Data'],
      textposition: 'middle center',
      hovertemplate:
        '<b>No Data, Please Choose Another axis</b>' + '<extra></extra>',
      marker: {
        // size: 20,
        size: 65,
        sizeref: 4000,
        sizemode: 'area',
        opacity: 0.8,
        color:
          '#' +
          Math.random()
            .toString(16)
            .substr(2, 6),
      },
    },
  ]

  const dataScatterChart = dataChart.v_holding_chart.map(res => {
    return {
      x: [res.axis_x.id],
      y: [res.axis_y.id],
      z: [res.axis_z.id],
      type: 'scatter3d',
      mode: 'markers+text',
      text: [res.count],
      textfont: {size: 30},
      textposition: 'middle center',
      // hovertext: ['x', 'y', 'z'],
      hovertemplate:
        `<br> <br>` +
        `<b>   X</b>   :   ${res.axis_x.name}   ` +
        `<br> <br>` +
        `<b>   Y</b>   :   ${res.axis_y.name}   ` +
        `<br> <br>` +
        `<b>   Z</b>   :   ${res.axis_z.name}   ` +
        `<br> <br>` +
        '<extra></extra>',
      marker: {
        // size: 20,
        sizemin: 45,
        size: [res.count * 3],
        opacity: 0.8,
        color:
          '#' +
          Math.random()
            .toString(16)
            .substr(2, 6),
      },
    }
  })

  const layout = {
    showlegend: false,
    width: 800,
    height: 445,
    font: {
      family: 'Muli',
      color: '#fff',
    },
    hoverlabel: {
      bgcolor: '#fff',
      font: {
        family: 'Muli',
        color: '#000',
        size: 16,
      },
    },
    paper_bgcolor: '#003d51',
    margin: {
      l: 0,
      r: 0,
      b: 0,
      t: 0,
    },
    scene: {
      xaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'red'},
      yaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'yellow'},
      zaxis: {gridcolor: '#fff', gridwidth: 1.5, spikecolor: 'green'},
    },
  }

  return (
    <div className={classes.container}>
      <div className={classes.filterContainer}>
        <div className={classes.selectWrapper}>
          <Typography variant="body2">X Axis</Typography>
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={selectProps(valX)}
            value={valX}
            onChange={e => setValX(e.target.value)}
          >
            <MenuItem value={0} disabled>
              Industry
            </MenuItem>
            {data.list_axis_x.map((res, i) => {
              return (
                <MenuItem value={res.id} key={i}>
                  {res.name}
                </MenuItem>
              )
            })}
          </TextField>
        </div>
        <div className={classes.selectWrapper}>
          <Typography variant="body2">Y Axis</Typography>

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={selectProps(valY)}
            value={valY}
            onChange={e => setValY(e.target.value)}
          >
            <MenuItem value={0} disabled>
              Function
            </MenuItem>
            {/* {data.list_axis_y.map((res, i) => {
              return (
                <MenuItem value={res.id} key={i}>
                  {res.name}
                </MenuItem>
              )
            })} */}
          </TextField>
        </div>
        <div className={classes.selectWrapper}>
          <Typography variant="body2">Z Axis</Typography>

          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            select
            SelectProps={selectProps(valZ)}
            value={valZ}
            onChange={e => setValZ(e.target.value)}
          >
            <MenuItem value={0} disabled>
              Category
            </MenuItem>
            {data.list_axis_z.map((res, i) => {
              return (
                <MenuItem value={res.id} key={i}>
                  {res.name}
                </MenuItem>
              )
            })}
          </TextField>
        </div>
      </div>
      <div className={classes.diagramContainer}>
        <Plot
          data={
            dataChart.v_holding_chart.length > 0
              ? dataScatterChart
              : noDataScatterChart
          }
          config={{
            responsive: true,
          }}
          layout={layout}
        />
      </div>
    </div>
  )
}
