import React from 'react'
import {useQuery} from '@apollo/react-hooks'
import {EmptyState} from '@smartasn/wlb-utils-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import {GET_LIST_EXPERIENCE} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {getJobDuration} from '../../talent-profile-helper'

import LoadingItem from '../../../../components/loading/LoadingItem'
import ExperienceItem from '../../professional-information/experience/ExperienceItem'

export default function Experience({userId}) {
  const {data, loading, error, fetchMore} = useQuery(GET_LIST_EXPERIENCE, {
    fetchPolicy: 'network-only',
    variables: {id: userId || USER_ID, limit: 6},
    context: {headers: {'X-Hasura-Role': 'user'}},
  })

  if (error) {
    console.error(error)
  }

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        offset: data.people_profile_experiences.length,
      },
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev
        return Object.assign({}, prev, {
          people_profile_experiences: [
            ...prev.people_profile_experiences,
            ...fetchMoreResult.people_profile_experiences,
          ].filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        })
      },
    })
  }

  const totalData = data?.total.aggregate.count

  return (
    <div>
      {loading && <LoadingItem count={6} />}

      {!loading && totalData === 0 && (
        <EmptyState
          message1="Maaf, tidak ada daftar"
          message2="Belum ada Pengalaman"
          width={280}
        />
      )}

      {!loading && (
        <InfiniteScroll
          dataLength={data?.people_profile_experiences.length}
          next={fetchMoreData}
          hasMore={data?.people_profile_experiences.length < totalData}
          loader={<LoadingItem count={6} />}
          scrollThreshold={0.3}
        >
          {data?.people_profile_experiences.map((experience, idx) => {
            const {is_present, additional_fields} = experience
            const {country_code, phone} =
              additional_fields?.referal_contact_phone || {}

            const date = getJobDuration({
              startDate: experience.from,
              endDate: experience.to,
              isPresent: is_present,
            })

            return (
              <ExperienceItem
                key={experience.id}
                avatar={experience.company_profile?.logo}
                title={experience.title}
                company={experience.company_name}
                date={date}
                location={experience.location?.experience_location}
                description={experience.description || '-'}
                achievements={experience.achievements}
                reasonForLeaveing={experience.reason_to_leave}
                refName={experience.referal_name}
                refPosition={experience.referal_position}
                refPhone={`${country_code + phone || '-'}`}
                company_line_of_business={experience.company_line_of_business}
                isLast={data?.people_profile_experiences.length === idx + 1}
              />
            )
          })}
        </InfiniteScroll>
      )}
    </div>
  )
}
