import React from 'react'
import {Avatar, Chip, Divider, Typography, makeStyles} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  chip: {
    height: 'fit-content',
    border: '1px solid #E5E5E5',
    borderRadius: 40,
    padding: '15px 30px',
    marginRight: 32,
    minWidth: 183,
  },
  title: {
    fontWeight: 600,
    fontSize: 14,
    color: '#333333',
    marginBottom: 2,
  },
  level: {
    textTransform: 'capitalize',
    color: '#014A62',
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 18,
  },
  share: {
    color: '#A9A8A8',
    marginBottom: 12,
    fontSize: 12,
  },
  avatarGroup: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12,
  },
  avatar: {
    width: 38,
    height: 38,
    fontSize: 14,
  },
  body: {
    width: '100%',
    marginTop: 30,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      marginTop: 0,
    },
  },
  content: {
    marginBottom: 30,
  },
  divider: {
    marginBottom: 30,
  },
}))

export default function SkillsItem(props) {
  const {title, level, people_profile_skills, totalPeople, isLast} = props
  const classes = useStyles()

  const offset = totalPeople - people_profile_skills?.length

  return (
    <div className={classes.root}>
      <Chip
        variant="outlined"
        classes={{root: classes.chip}}
        label={<Typography className={classes.title}>{title}</Typography>}
      />
      <div className={classes.body}>
        <div className={classes.content}>
          <Typography className={classes.level}>{level}</Typography>
          <Typography className={classes.share}>
            Bagikan keterampilan yang sama dengan :
          </Typography>
          <div className={classes.avatarGroup}>
            {people_profile_skills.map(({global_user}, idx) => {
              return (
                <Avatar
                  key={idx}
                  src={global_user.avatar}
                  className={classes.avatar}
                />
              )
            })}
            {offset > 0 && (
              <Avatar className={classes.avatar}>+{offset}</Avatar>
            )}
          </div>
        </div>
        {!isLast && <Divider className={classes.divider} />}
      </div>
    </div>
  )
}
